import React from "react";
import "../../Components/Responsive.css";
import "../../Components/Res.css";
import "../../Components/UserDashboard.css";
import "../../Components/SweetAlert.css";
import "../../Components/Modal.css";

function UnitAmount() {
  return (
    <div>
      <span>20,000</span>
    </div>
  );
}

export default UnitAmount;
