import React from "react";
import { Routes, Route } from "react-router-dom";
import "../InvestorComponent/Pagination.css";
import "../../Components/UserDashboard.css";
import "../../Components/Responsive.css";
import "../../Components/Clique24Logo/Clique24Logo.css";
import clsx from "clsx";
import InvestorsDashboard from "../Dashboard/InvestorsDashboard";
import Payout from "../Payout/Payout";
import Transactions from "../Transactions/Transactions";
import MyReferrals from "../MyReferrals/MyReferrals";
import Accounts from "../Accounts/Accounts";
import { makeStyles } from "@material-ui/core/styles";
import SideBar from "../../Components/SideBar";
import { Menu } from "@material-ui/core";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import UserSettings from "../../Components/UserSettings";

const useStyles = makeStyles({
        list: {
                width: 250,
        },
        fullList: {
                width: "auto",
        },
});

function MainRoutes() {

  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [anchor, SetAnchor] = React.useState("left");

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className="SideShift">
        <SideBar />
      </div>
    </div>
  );
  return (
        <>
        <div>
          <div className="Main-Side-Bar Parent">
            <aside className="SideBarResponsive">
              <SideBar />
            </aside>
  
            <main className="MainDash ">
              <div className="NavBarContainer1">
                <div>
                  <React.Fragment key={anchor}>
                    <button
                      onClick={toggleDrawer(anchor, true)}
                      className="MenuSideBar"
                    >
                      <Menu className="Menu-Icon1" />
                    </button>
                    <SwipeableDrawer
                      anchor={anchor}
                      open={state[anchor]}
                      onClose={toggleDrawer(anchor, false)}
                      onOpen={toggleDrawer(anchor, true)}
                    >
                      {list(anchor)}
                    </SwipeableDrawer>
                  </React.Fragment>
                </div>
              </div>
              <div className="wrapper_inner FooterTarget ">
               
                 <div className="NavBarSetting">
                <UserSettings />
              </div>
  
              <Routes>       
                <Route index element={<InvestorsDashboard />}></Route>
                <Route path="/dashboard" element={<InvestorsDashboard />}></Route>
                <Route path="/payouts" element={<Payout />}></Route>
                <Route path="/transactions" element={<Transactions />}></Route>
                <Route path="/account" element={<Accounts />}></Route>
                <Route path="/my-referrals" element={<MyReferrals />}></Route>
              </Routes>
  
                <footer>
                  {/* <Footer /> */}
                </footer>
              </div>
            </main>
          </div>
        </div>
      </>
      
  );
}

export default MainRoutes;
