import { LanguageOutlined, Lock, Notifications } from "@material-ui/icons";
import React from "react";
import "./UserSettings.css";
import { useAuth } from "../auth";
import { getUser } from "../services/AuthService";

function UserSettings() {
  const auth = useAuth();
  const user = getUser();

  const date = new Date();
  const hour = date.getHours();

  return (
    <div>
    <div className="container NavWrapper p-0">
      <div className="DashBoardContainer">
        <div>
          {hour < 12 ? (
            <div className="Hello__Sur">Goodmorning!</div>
          ) : hour < 18 ? (
            <div className="Hello__Sur">Goodafternoon!</div>
          ) : (
            <div className="Hello__Sur">Goodevening!</div>
          )}
        </div>
        <span className="Hello__Sur1">Investor, {user?.first_name ? user?.first_name : user?.phone }</span>
      </div>
      <div className="Settings__Cont">
        <div>
          <Notifications className="NavBarIcons" />{" "}
        </div>
        <div className="Clique__Sti" onClick={() => {
              auth.logout();
            }} >
          <Lock className="NavBarIcons" /> LogOut
        </div>
      </div>
    </div>
  </div>
  );
}

export default UserSettings;
