import React, {useEffect, useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import NavBarList from "../../Components/NavBarList";
import { useAuth } from "../../auth";
import NavBar from "../../Components/NavBar";
import Footer from "../../Components/Footer";

function Signup() {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const refCodeD = params.get('code');
  console.log("refCode", refCodeD)
  const auth = useAuth();
  let navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const [errPhone, setErrPhone] = useState("");
  const [password, setPassword] = useState("")
  const [errPassword, setErrPassword] = useState("");
  const [loading, setLoading] = useState("")

  const [confirmPassword, setConfirmPassword] = useState("")
  const [errConfirmPassword, setErrConfirmPassword] = useState("");
  const [isAccept, setIsAccept] = useState("")
  const [errIsAccept, setErrIsAccept] = useState("");
  const [refCode, setRefCode] = useState(refCodeD || "")
  const [knowAboutUs, setKnowAboutUs] = useState("")
  const [firstName, setFirstName] = useState("")
  const [errFirstName, setErrFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [errLastName, setErrLastName] = useState("")

  // useEffect(() => {
  //   if(refCode){
  //     setRefCode(refCode)
  //   }
  // }, [refCodeD])
  const handleSignup = async () => {
    if (!phone) {
      setErrPhone("Phone number is required");
      return;
    }
    if (!password) {
      setErrPassword("Password is required");
      return;
    }
    if (!firstName) {
      setErrFirstName("First Name is required");
      return;
    }
    if (!lastName) {
      setErrLastName("Last Name is required");
      return;
    }
    if (password !== confirmPassword) {
      setErrConfirmPassword("Password does not match");
      return;
    }
    if (!isAccept) {
      setErrPassword("Please, Accept our terms and conditions.");
      return;
    }
    setLoading(true)
    let user = await {
      phone: phone,
      first_name: firstName,
      last_name: lastName,
      password: password,
      referral_code: refCode,
      know_about_us: knowAboutUs,
      isAccept: isAccept,
    }
    navigate("/confirm-phone", {state:{user:user}})
    setLoading(false)
  }



  return (
    <div className="LoginBody">
      <header>
        {" "}
        <div>
          <NavBar />
        </div>
      </header>
      <div className="DashBoardContainer">
        <div className="column22 ">
          <div className="logoSignup__Cont">
          
              <img src="/assets/3.png" alt="" className="ImgLogo" />
           
            <div className="logo__SignUp__Note">
              <div className="SignUp__Header">Sign Up.</div>

              <div className="SignUp__Header__Note">Sign Up and Invest.</div>
            </div>
          </div>

          <div className="Input__C">
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="floatingInput"
                placeholder="Phone Number"
                value={phone}
                onChange={(event) => {
                  if(event.target.value.length){
                    setErrPhone("")
                  }else{
                    setErrPhone("Phone number is required")
                  }
                  setPhone(event.target.value)
                }}
              />
              <label className="frol" for="floatingInput">
                Phone Number
              </label>
              {(errPhone) && <div className='input_error'>{errPhone}</div>}
            </div>

            {/* <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="floatingInput"
                placeholder="Phone Number"
              />
              <label className="frol" for="floatingInput">
                Username
              </label>
              <div className="valid-feedback   alert alert-danger">
                Username is in use!
              </div>
              <div className="valid-feedback alert alert-success">
                Username is available!
              </div>
            </div> */}
            <div className="row g-2">
              <div className="col-md">
                <div className="form-floating  mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingPassword"
                    placeholder="First Name"
                    value={firstName}
                    onChange={(event) => {
                      if(event.target.value.length){
                        setErrFirstName("")
                      }else{
                        setErrFirstName("First Name is required")
                      }
                      setFirstName(event.target.value)
                    }}
                  />
                  <label className="frol" for="floatingPassword">
                    First Name
                  </label>
                  {(errFirstName) && <div className='input_error'>{errFirstName}</div>}
                </div>
              </div>
              <div className="col-md">
                <div className="form-floating  mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingPassword"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(event) => {
                      if(event.target.value.length){
                        setErrLastName("")
                      }else{
                        setErrLastName("Last Name is required")
                      }
                      setLastName(event.target.value)
                    }}
                  />
                  <label className="frol" for="floatingPassword">
                    Last Name
                  </label>
                  {(errLastName) && <div className='input_error'>{errLastName}</div>}
                </div>
              </div>
            </div>
            <div className="row g-2">
              <div className="col-md">
                <div className="form-floating  mb-3">
                  <input
                    type="password"
                    className="form-control"
                    id="floatingPassword"
                    placeholder="Password"
                    value={password}
                    onChange={(event) => {
                      if(event.target.value.length){
                        setErrPassword("")
                      }else{
                        setErrPassword("Password is required")
                      }
                      setPassword(event.target.value)
                    }}
                  />
                  <label className="frol" for="floatingPassword">
                    Password
                  </label>
                  {(errPassword) && <div className='input_error'>{errPassword}</div>}
                </div>
              </div>
              <div className="col-md">
                <div className="form-floating  mb-3">
                  <input
                    type="password"
                    className="form-control"
                    id="floatingPassword"
                    placeholder="Password"
                    value={confirmPassword}
                    onChange={(event) => {
                      if(event.target.value.length){
                        setErrConfirmPassword("")
                      }else{
                        setErrConfirmPassword("Password is required")
                      }
                      setConfirmPassword(event.target.value)
                    }}
                  />
                  <label className="frol" for="floatingPassword">
                    Confirm Password
                  </label>
                  {(errConfirmPassword) && <div className='input_error'>{errConfirmPassword}</div>}
                </div>
              </div>
            </div>
          </div>
          <div className="form-floating mb-3">
            <input
              type="number"
              className="form-control"
              id="floatingInput"
              placeholder="Phone Number"
              disabled={refCodeD?.length > 0}
              value={refCode}
              onChange={(event) => {
                setRefCode(event.target.value)
              }}
            />
            <label className="frol" for="floatingInput">
              Enter Referral Code
            </label>
            <div className="valid-feedback alert alert-danger">
              Invalid Code
            </div>
          </div>

          <div className="form-floating mb-3">
            <select
              className="form-select"
              id="floatingSelect"
              aria-label="Floating label select example"
              value={knowAboutUs}
              onChange={(event) => {
                setKnowAboutUs(event.target.value)
              }}
            >
              <option className="frol" selected>
                How did you know about us?
              </option>

              <option value="Google">Google</option>
              <option value="Radio">Radio</option>
              <option value="Instagram Ad">Instagram Ad</option>
              <option value="Facebook Ad">Facebook Ad</option>
              <option value="Twitter Ad">Twitter Ad</option>
              <option value="Our social Media">Our social Media page</option>
              <option value="From a friend">From a friend</option>
            </select>
            <label className="frol" for="floatingSelect">
              How did you know about us?
            </label>
          </div>

          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck1"
              value={isAccept}
              onChange={(event) => {
                setIsAccept(event.target.checked)
              }}
            />
            <label className="form-check-label Accept" for="exampleCheck1">
              Accept our terms and conditions.
            </label>
            <div className="valid-feedback alert-danger">
              You must accept our terms and conditions to signup!
            </div>
            {(errIsAccept) && <div className='input_error'>{errIsAccept}</div>}
          </div>
          <br />

          <div className="Terms__SignUp">
            <div className="Clique__Qes">
              Already signed up?{" "}
              <Link to="/Login" className="Clique__Link">
                <span>Log in Here</span>
              </Link>
            </div>

            <div>
              <button disabled={!isAccept} className="Log__Btn" onClick={handleSignup}>Signup</button>
            </div>
          </div>

          <br />
          <div className="Clique__Qes">
            Go back to{" "}
            <Link to="/" className="Clique__Link">
              <span>Home</span>
            </Link>
          </div>
        </div>

    {/* <Footer/> */}
      </div>
    </div>
  );
}

export default Signup;
