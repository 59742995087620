import React, { useEffect, useState } from "react";
import "../../Components/Responsive.css";
import "../../Components/Res.css";
import "../../Components/UserDashboard.css";
import "../../Components/SweetAlert.css";
import "../../Components/Modal.css";

import "../InvestorComponent/Pagination.css";
import { MoreVert } from "@material-ui/icons";
import Paginations from "../InvestorComponent/Paginations";
import WithdrawFunds from "../InvestorComponent/WithdrawFunds";
import YourInvestmentDetails from "./YourInvestmentDetails";
import { useDispatch, useSelector } from "react-redux";
import { fetchInvests } from "../../features/invests/investsSlice";
import { Bars } from "react-loader-spinner";
import moment from "moment/moment";
import CurrencyFormat from "react-currency-format";

function InvestmentList() {
  const dispatch = useDispatch()
  const {invests, paginator, loading} = useSelector((state) => state.invests)
  
  const [yourInvestmentViewShow, setYourInvestmentViewShow] = useState(false);
  const handleYourInvestmentView = () => setYourInvestmentViewShow(false);

  const [withdrawfundsShow, setWithdrawfundsShow] = useState(false);
  const handleWithdrawfunds = () => setWithdrawfundsShow(false);
  
  const [search2, setSearch2] = useState('');
  const [page, setPage] = useState(paginator?.currentPage || 1);
  const [pageLimit, setPageLimit] = useState(10);
  const [pageCount, setPageCount] = useState(paginator?.pageCount || 0);
  const [selected, setSelected] = useState({})

  const showDate = (passDate) => {
    return moment(passDate).format('DD-MM-YYYY')
  }

  useEffect(() => {
    handleFetchMyInvests()
    getCurrentROI();
  }, [])

  const handleFetchMyInvests = (search = "", limit = 10, page = 1) => {
    dispatch(fetchInvests({name: search, page_size: limit, page:page }))
  }

  const handleMyUpdate = (search = search2, limit = pageLimit, page = 1) => {
    dispatch(fetchInvests({name: search, page_size: limit, page:page }));
  }

  const getCurrentROI = (checkDate, ROI, amount) => {
    const currentTime = new Date();
    const checkTime = new Date(checkDate);
    const interest =  ROI - amount;
    const fixedMonthlyROI =  interest / 12;
    const getDate = (date) => moment(date, 'DD/MM/YYYY').startOf('month')
    const diff =  Math.abs(getDate(checkTime).diff(getDate(currentTime), 'months'));

    return fixedMonthlyROI * diff;
  }

  return (
    <>
      <Paginations 
        handleFetchTransSearch={handleMyUpdate} 
        page={page} 
        setPage={setPage} 
        pageCount={paginator?.pageCount || 0} 
        pageLimit={pageLimit}
        setPageLimit={setPageLimit}
      />
      {loading ? (
          <div className="d-flex justify-content-center">
            <Bars
              heigth="30"
              width="30"
              color="gray"
              ariaLabel="loading-indicator"
            />
          </div>
      ) : null}
      {
        invests?.map((item, index) => (
          <div key={index} className="Investment-Transaction-Info50">
            <div className="Investors-TransactionRef">
              <div className="Wallet-Ref-Heading">Inv Ref ID</div>
              <div className="Wallet-Ref-Result">{item?.code}</div>
            </div>

            <div className="Investment-Amount">
              <div className="Wallet-Ref-Heading">&#8358; Amount</div>
              <CurrencyFormat
                value={item?.amount}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₦ "}
                renderText={(value) => (
                  <div className="Wallet-Ref-Result">{value}</div>
                )}
              />
            </div>
            <div className="Inv-Equity">
              <div className="Inv-Heading">Units</div>
              <div className="Inv-Result">{item?.unit}</div>
            </div>
            {
              item?.status === 'waiting' ? (
                <div className="Inv-ROI">
                  <div className="Wallet-Ref-Heading">&#8358; Total ROI/Month</div>
                  <CurrencyFormat
                    value={getCurrentROI(item?.created_at, item?.roi, item?.amount )}
                    displayType={"text"}
                    decimalScale={2}
                    thousandSeparator={true}
                    prefix={"₦ "}
                    renderText={(value) => (
                      <div className="Wallet-Ref-Result">{value}</div>
                    )}
                  />
                </div>  
              ) : null
            }
            <div className="Inv-ROI">
              <div className="Wallet-Ref-Heading">&#8358; ROI</div>
              <CurrencyFormat
                value={item?.roi}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₦ "}
                renderText={(value) => (
                  <div className="Wallet-Ref-Result">{value}</div>
                )}
              />
            </div>

            <div className="Inv-Date">
              <div className="Wallet-Ref-Heading">Investment Date</div>
              <div className="Wallet-Ref-Result">{showDate(item?.created_at)}</div>
            </div>

            <div className="Inv-Maturity-Date">
              <div className="Wallet-Ref-Heading">Maturity Date</div>
              <div className="Wallet-Ref-Result">{showDate(item?.roi_date)}</div>
            </div>

            <div className="Inv-Maturity-Status">
              <div className="Wallet-Ref-Heading">Status</div>
              <div className="Wallet-Ref-Result">{item?.status}</div>
            </div>

            <div className="Members-Action">
              <div
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <MoreVert />
              </div>
              <ul
                className="dropdown-menu dropdown-menu-end"
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  <a
                    className="dropdown-item View__Details"
                    onClick={() => {setSelected(item); setYourInvestmentViewShow(true)}}
                  >
                    View Details
                  </a>
                </li>
                {
                  item?.status === "matured" ? (
                    <li>
                      <a
                        className="dropdown-item View__Details"
                        onClick={() => {setSelected(item); setWithdrawfundsShow(true)}}
                      >
                        Withdraw Funds
                      </a>
                    </li>
                  ) : null
                }

                {/* <li>
                  <a className="dropdown-item View__Details">Paid Status</a>
                </li> */}
              </ul>
            </div>
          </div>
        ))
      }

      <Paginations />

      <WithdrawFunds
        selected={selected}
        show={withdrawfundsShow}
        onClose={handleWithdrawfunds}
        onOpen={() => setWithdrawfundsShow(true)}
      />

      <YourInvestmentDetails
        selected={selected}
        show={yourInvestmentViewShow}
        onClose={handleYourInvestmentView}
        onOpen={() => setYourInvestmentViewShow(true)}
      />
    </>
  );
}

export default InvestmentList;
