import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listReferrals } from '../../services/ReferralService'

const initialState = {
    loading: false,
    referrals: [],
    paginator: {},
    isSuccessful: false
}

export const fetchReferrals = createAsyncThunk('fetchReferrals', (initData) => {
    return listReferrals(initData)
    .then((response) => response.data.data)
})


const referralsSlice = createSlice({
    name: 'referrals',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchReferrals.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchReferrals.fulfilled, (state, action ) => {
        state.loading = false
        state.referrals = action.payload.data
        state.paginator = action.payload.paginator
        state.isSuccessful = true
       })
       builder.addCase(fetchReferrals.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default referralsSlice.reducer 