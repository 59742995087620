import React, { useEffect, useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { Modal } from "react-bootstrap";
import { Close } from "@material-ui/icons";
import "../../Components/Responsive.css";
import "../../Components/Res.css";
import "../../Components/UserDashboard.css";
import "../../Components/SweetAlert.css";
import "../../Components/Modal.css";

import { FcCurrencyExchange, FcExternal, FcLeftDown2 } from "react-icons/fc";
import ROI from "./ROI";
import InvestedAmount from "./InvestedAmount";
import { FcLineChart } from "react-icons/fc";
import Swal from "sweetalert2";
import EnterPin from "../InvestorComponent/EnterPin";
import RemainingEquity from "../InvestorComponent/RemainingEquity";
import InvestedUnits from "./InvestedUnits";
import InvestmentROI from "./InvestmentROI";
import { useDispatch, useSelector } from "react-redux";
import { fetchInvestUnitsSum } from "../../features/invests/sums/investUnitsSumSlice";
import { fetchInvestSum } from "../../features/invests/sums/investSumSlice";
import { fetchInvestROISum } from "../../features/invests/sums/investROISumSlice";

function InvestmentOverview() {
  const dispatch = useDispatch();
  const [executiveInvestorsShow, setExecutiveInvestorsShow] = useState(false);
  const handleExecutiveInvestors = () => setExecutiveInvestorsShow(false);
  
  const investUnitsSum = useSelector((state) => state.investUnitsSum)
  const investSum = useSelector((state) => state.investSum)
  const investROISum = useSelector((state) => state.investROISum)

  useEffect(() => {
    dispatch(fetchInvestUnitsSum())
    dispatch(fetchInvestSum())
    dispatch(fetchInvestROISum())
  }, [])

  const handleShareInvestors = () => {
    setExecutiveInvestorsShow(false);

    Swal.fire({
      customClass: {
        confirmButton: "confirm-button-class",
        title: "title-class",
        icon: "icon-class",
        text: "text-class",
      },
      icon: "success",
      title: "Investor's Equity is Set",
      showConfirmButton: false,
      timer: 1500,
    });
  };

  return (
    <div>
      <div className="container overflow-hidden p-0 Investors-Dashboard-Overview">
        <div className="row g-4">
          <div className="col-xl-4">
            <div className="Investors-Invested-Amount Investment-Mobile">
              <div className="Investors-Invested-Heading">
                <div className="Investors-Amount-Heading">
                  Invested Amount &#8358;
                </div>

                <div className="IconCont1">
                  <FcCurrencyExchange className="AccIcon" />
                </div>
              </div>
              <div className="Investors-Amount">
                 <InvestedAmount 
                  loading={investSum?.loading} 
                  amount={investSum?.total}
                />
              </div>
            </div>
          </div>
          <div className="col-xl-4 ">
            <div className="Investors-Invested-Amount Investment-Mobile">
              <div className="Investors-Invested-Heading">
                <div className="Investors-Amount-Heading">Units purchased</div>

                <div className="IconCont1">
                  <FcLeftDown2 className="AccIcon" />
                </div>
              </div>
              <div className="Investors-Amount">
                <InvestedUnits 
                  loading={investUnitsSum?.loading}
                  units={investUnitsSum?.total}
                />
              </div>
            </div>
          </div>
          <div className="col-xl-4">
            <div className="Investors-Invested-Amount">
              <div className="Investors-Invested-Heading">
                <div className="Investors-Amount-Heading">
                  ROI &#8358;&nbsp;(
                  <span>
                    <InvestmentROI />
                  </span>
                  &nbsp;<span>returns in 12 months)</span>
                </div>

                <div className="IconCont1">
                  <FcExternal className="AccIcon" />
                </div>
              </div>
              <div className="Investors-Amount">
                <ROI loading={investROISum?.loading} roi={investROISum?.total} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <AddInvestor
          show={addinvestorShow}
          onClose={handleAddinvestor}
          onOpen={() => setAddinvestorShow(true)}
        /> */}

      <Modal
        size="lg"
        show={executiveInvestorsShow}
        onHide={() => setExecutiveInvestorsShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        keyboard={false}
        animation={true}
      >
        <Modal.Body>
          <div className="Clique24__ModalHeadings">
            <div onClick={handleExecutiveInvestors}>
              <Close className="Clique24__Close" />
            </div>
          </div>
          <div className="Settings__Modal__Container">
            <div className="Inv-Heading">
              <div className="Log__Cont">
                <img src="/assets/3.png" alt="" className="Log__Img__Modal" />
              </div>
              <div className="Clique24__Headings__Note">
                <div className="Clique24__Settings__Header">
                  Executive Investor Share
                </div>
                <div className="DisplayUsernames">
                  Enter the equity percentage accordignly
                </div>
              </div>
            </div>
            <br /> <br /> <br />
            <div>
              <div className="Invest-Dashboard">
                <div className="Invest-DashboardContainer">
                  <div className="INVRemaining">
                    {/* Net Profit&nbsp;&#8358; */}
                    Total Equity Remaining
                  </div>
                  <div className="IconCont1">
                    <FcLineChart className="AccIcon" />
                  </div>
                </div>
                <div className="InvAmtHeadingWrapper">
                  <div className="InvAmtHeading">
                    <RemainingEquity />
                  </div>
                  {/* <Settings /> */}
                </div>
              </div>
            </div>
            <br /> <br />
            <div className="InCont">
              <div className="InContWrapper">
                <div className="mb-3">
                  <FloatingLabel
                    controlId="floatingUsername"
                    label="First Name"
                    className="Input__User34"
                  >
                    <Form.Control type="text" placeholder="Last Name" />
                  </FloatingLabel>
                </div>
              </div>
              <div className="InContWrapper">
                <div className="mb-3">
                  <FloatingLabel
                    controlId="floatingUsername"
                    label="LastName"
                    className="Input__User34"
                  >
                    <Form.Control type="text" placeholder="Last Name" />
                  </FloatingLabel>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <FloatingLabel
                controlId="floatingUsername"
                label="Equity %"
                className="Input__User34"
              >
                <Form.Control type="number" placeholder="First Name" />
              </FloatingLabel>
            </div>
            <div className="InCont">
              <div className="InContWrapper">
                <div className="mb-3">
                  <FloatingLabel
                    controlId="floatingUsername"
                    label="Phone number"
                    className="Input__User34"
                  >
                    <Form.Control type="number" placeholder="Last Name" />
                  </FloatingLabel>
                </div>
              </div>
              <div className="InContWrapper">
                <div className="mb-3">
                  <FloatingLabel
                    controlId="floatingUsername"
                    label="Password"
                    className="Input__User34"
                  >
                    <Form.Control type="password" placeholder="Last Name" />
                  </FloatingLabel>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <EnterPin />
              <div
                className="valid-feedback AlertNot alert alert-danger "
                role="alert"
              >
                Incorrect PIN!
              </div>
            </div>
            <br />
            <div className="Payment__Heading__Cont">
              <div className="Profile__Cont__Wrapper mb-3">
                <button
                  className="Profile__Cont__Saves"
                  onClick={handleShareInvestors}
                >
                  Set Share
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default InvestmentOverview;
