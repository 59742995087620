import React, { useState } from "react";
import { Menu } from "@material-ui/icons";
import "../../Components/Responsive.css";
import "../../Components/Clique24Logo/Clique24Logo.css";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import UserNavBar from "../../Components/UserNavBar";
import Footer from "../../Components/Footer";
import Dashboard from "./Dashboard";
import SideBar from "../../Components/SideBar";
import UserSettings from "../../Components/UserSettings";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

function InvestorsDashboard() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [anchor, SetAnchor] = React.useState("left");

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className="SideShift">
        <SideBar />
      </div>
    </div>
  );
  return (
    <Dashboard />
  );
}

export default InvestorsDashboard;
