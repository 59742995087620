import React from "react";
import "../../Components/Responsive.css";
import "../../Components/Res.css";
import "../../Components/UserDashboard.css";
import "../../Components/SweetAlert.css";
import "../../Components/Modal.css";

function PrivacyTerms() {
  return (
    <div className="container-fluid p-0 overflow-hidden">
      <div>
        <div className="container-fluid  p-0">
          <div className="row g-3">
            <div className="col-lg-12">
              <div className="Invest-Dashboard202">
                <div className="EquityNumber">Privacy Terms</div>
                <div className="TermsAndConditions">Term Sheet for Investors

This Term Sheet ("Term Sheet") outlines the principal terms and conditions under which [Your Company Name], a [Jurisdiction of Incorporation] corporation ("Company"), proposes to raise equity financing from the investors listed below ("Investors"). This Term Sheet is not legally binding except for the provisions under the heading "Confidentiality" and "Exclusivity." The terms outlined herein are subject to negotiation and execution of definitive agreements by all parties.
<br/><br/>

<b>1. Investment Amount:</b>


The total investment amount will be N250,000,00.
Investors shall collectively invest $[Investment Amount Per Investor] per Investor.<br/><br/>
2. Valuation:

Pre-money valuation of the Company is $[Pre-money Valuation].
Post-money valuation will be determined upon completion of the investment round.<br/><br/>
3. Securities Offered:
Investors will receive [Type of Securities] in exchange for their investment, with standard anti-dilution provisions.<br/><br/>
4. Use of Proceeds:

The proceeds from this investment will be primarily used for [Specific Purposes such as product development, marketing, hiring, etc.].
<br/><br/>5. Investor Privileges:

Board Representation:
Investors collectively holding 10% or more of the Company’s outstanding equity shall have the right to appoint 1 board member(s).
<br/><br/>Information Rights:
Investors shall have the right to receive regular updates and financial statements of the Company on a quarterly basis.<br/><br/>
Liquidation Preference:
In the event of liquidation, Investors shall have a 1x liquidation preference over the common shareholders.<br/><br/>
Anti-dilution Protection:
Investors shall be granted standard anti-dilution protection to maintain their ownership percentage in the event of future equity financing at a lower valuation.
Right of First Refusal and Co-sale:<br/><br/>
Investors shall be granted a right of first refusal and co-sale rights on future equity issuances.<br/><br/>
Voting Rights:
Investors shall have voting rights proportionate to their ownership in the Company.
<br/><br/>
6. Conditions Precedent:

The investment shall be subject to the completion of satisfactory due diligence by the Investors.
Execution of definitive agreements, including but not limited to subscription agreements, shareholders agreements, and any necessary legal documentation.
<br/><br/>7. Confidentiality:

<br/><br/>All parties agree to keep the terms of this Term Sheet confidential and not to disclose them to any third party, except as required by law.
8. Exclusivity:

<br/><br/>The Company agrees to grant the Investors a period of exclusivity for [X] days to complete due diligence and negotiate definitive agreements.
9. Governing Law:

This Term Sheet shall be governed by and construed in accordance with the laws of [Jurisdiction], without regard to its conflict of law provisions.
10. Termination:

This Term Sheet may be terminated by mutual agreement of the Company and the Investors at any time.
11. Execution:

This Term Sheet may be executed in counterparts, each of which shall be deemed an original, but all of which together shall constitute one and the same instrument.
12. Binding Effect:

This Term Sheet shall be binding upon and inure to the benefit of the parties hereto and their respective successors and assigns.
Signed on this ____ day of __________, 20.

[Your Company Name]

By: ____________________________
Name: [Your Name]
Title: [Your Title]

Investors

[Investor Name]

[Investor Name]

[Investor Name]

[Add more lines as necessary for additional investors]

This Term Sheet is for discussion purposes only and does not constitute an offer or commitment by any party until definitive agreements have been executed.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyTerms;
