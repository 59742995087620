import React from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { Modal } from "react-bootstrap";
import { Close } from "@material-ui/icons";
import moment from "moment";
import CurrencyFormat from "react-currency-format";

function ViewRephrals({ show, onClose, selected }) {
  const showDate = (passDate) => {
    return moment(passDate).format('DD-MM-YYYY')
  }

  return (
    <div>
      <div>
        <Modal
          size="lg"
          show={show}
          onHide={onClose}
          backdrop="static"
          aria-labelledby="example-modal-sizes-title-lg"
          keyboard={false}
          animation={true}
        >
          <Modal.Body>
            <div className="Clique24__ModalHeadings">
              <Close className="Clique24__Close" onClick={onClose} />
            </div>

            <div className="Clique24__ModalHeadings"></div>
            <div className="Settings__Modal__Container">
              <div className="Clique24__Headings">
                <div className="Log__Cont">
                  <img src="/assets/3.png" alt="" className="Log__Img__Modal" />
                </div>
                <div className="Clique24__Headings__Note">
                  <div className="Clique24__Settings__Header">
                    Your Referral
                  </div>
                  <div className="Clique24__Settings__Note">
                    View your My Referral's details
                  </div>
                </div>
              </div>
              <br />
              <br />

              <div className="container">
                <div className="Inv-Display-Container">
                  <div className="Inv-Display-Container-Payment">
                    {" "}
                    INVESTOR DETAILS
                  </div>
                  <div className="Inv-Display-Container-Details">
                    Investor Phone Number :{" "}
                    <span className="Inv-Display-Container-Result">
                      {selected?.phone}
                    </span>
                  </div>
                  <div className="Inv-Display-Container-Details">
                    Investor Name :{" "}
                    <span className="Inv-Display-Container-Result">
                      {selected?.phone}
                    </span>
                  </div>
                  <br />
                  <div className="Inv-Display-Container-Details">
                    Investment Date :{" "}
                    <span className="Inv-Display-Container-Result">
                      {showDate(selected?.created_at)}
                    </span>
                  </div>
                </div>
              </div>

              <br />

              {
                selected?.referral_status !== "awaiting" ? (
                  <div className="container">
                    <div className="Inv-Display-Container">
                      <div className="Inv-Display-Container-Payment">
                        {" "}
                        BONUS DETAILS
                      </div>

                      <div className="Inv-Display-Container-Details">
                        Bonus Amount :{" "}
                        <CurrencyFormat
                          value={selected?.referral_amount || 0}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"₦ "}
                          renderText={(value) => (
                            <div className="Inv-Display-Container-Result">{value}</div>
                          )}
                        />
                        {/* <span className="Inv-Display-Container-Result">
                          &#8358; 0:00
                        </span> */}
                      </div>

                      <br />

                      <div className="Inv-Display-Container-Details">
                        Maturity Date :{" "}
                        <span className="Inv-Display-Container-Result">
                          {showDate(selected?.updated_at)}
                        </span>
                      </div>
                      <div className="Inv-Display-Container-Details">
                        Status :{" "}
                        <span className="Inv-Display-Container-Result">
                          {selected?.referral_status}
                        </span>
                      </div>
                    </div>
                  </div>
                ) : null
              }
              {/* <div className="container">
                  <div className="Inv-Display-Container">
                    <div className="Inv-Display-Container-Payment">
                      {" "}
                      ACCOUNT DETAILS
                    </div>
  
                    <div className="Inv-Display-Container-Details">
                      Account Name :
                      <span className="Inv-Display-Container-Result">
                        Name of account display
                      </span>
                    </div>
                    <div className="Inv-Display-Container-Details">
                      Account Number
                      <span className="Inv-Display-Container-Result">
                        Account Number display
                      </span>
                    </div>
                    <div className="Inv-Display-Container-Details">
                      Bank Name
                      <span className="Inv-Display-Container-Result">
                        Name of bank
                      </span>
                    </div>
                  </div>
                </div>
                <br /> */}
              {/* <div className="container">
                  <div className="Inv-Display-Container">
                    <div className="Inv-Display-Container-Payment">
                      {" "}
                      ROI PAYMENT DETAILS
                    </div>
  
                    <div className="Inv-Display-Container-Details">
                      Payment Date :
                      <span className="Inv-Display-Container-Result">
                         20-20-2024
                      </span>
                    </div>
                    <div className="Inv-Display-Container-Details">
                      Paid Amount :
                      <span className="Inv-Display-Container-Result">
                        &#8358; 0:00
                      </span>
                    </div>
                    <div className="Inv-Display-Container-Details">
                      Payment Status :{" "}
                      <span className="Inv-Display-Container-Result">
                        Paid
                      </span>
                    </div>
                  </div>
                </div> */}
              <br />

              <br />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default ViewRephrals;
