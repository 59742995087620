import axios from '../axiosinstance';

      
export function listPayouts(initData){
        return axios.get('/payouts', { params: initData })
}

export function addPayout(data){
        return axios.post('/payouts', data)
}

export function getPayout(initData){
        return axios.get('/payouts/'+initData?.id)
}

export function updatePayout(id, initData){
        return axios.patch('/payouts/'+id, initData)
}

export function payoutCount(search){
        return axios.get('/payouts/stat/count', { params: search })
}

export function payoutSum(search){
        return axios.get('/payouts/stat/sum', { params: search })
}


