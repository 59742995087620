import React from 'react'
import SignUpAndLogin from './SignUpAndLogin'

function NavBar() {
  return (
    <div>   <div>
    <div className="container p-0">
      <div className="Nav-Bar">
        <div className="CleeQLogo">
          <img
            src="/assets/CleeQLogo.png"
            alt=""
            className="Nav-Bar-Logo"
           
          />
        </div>
        <div className="CleeQ-Nav-List">
          {/* <div>
            <a href="#Home" className="ClickLink">
              Home
            </a>
          </div>

          <div>
            <a href="#TypeDetails" className="ClickLink">
              Investment
            </a>
          </div>

          <div>
            <a href="#TermsConditions" className="ClickLink">
              Terms and Conditions
            </a>
          </div>

          <div>
            <a href="#Aboutus" className="ClickLink">
              About Us
            </a>
          </div>
          <div>
            <a href="#ReferalPolicy" className="ClickLink">
              Referral Policy
            </a>
          </div>
          <div>
            <a href="#ContactUs" className="ClickLink">
              Contact Us
            </a>
          </div> */}
        </div>
        <SignUpAndLogin />
      </div>
    </div>
  </div></div>
  )
}

export default NavBar