import React from "react";
import "../../Components/Responsive.css";
import "../../Components/Res.css";
import "../../Components/UserDashboard.css";
import "../../Components/SweetAlert.css";
import "../../Components/Modal.css";
import CurrencyFormat from "react-currency-format";

function InvestedAmount({loading, amount}) {
  return <>
    {
      loading ? (
        <div>Loading</div>
      ) : (
        <CurrencyFormat
          value={amount || 0}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"₦ "}
          renderText={(value) => (
            <div>{value}</div>
          )}
        />
      )
    }
    
  </>;
}

export default InvestedAmount;
