import React, { useState } from "react";
import {
  FiArrowUpRight,
  FiBarChart,
  FiCornerLeftDown,
  FiEdit,
  FiGrid,
  FiKey,
  FiPower,
  FiThumbsUp,
  FiToggleLeft,
  FiUserCheck,
  FiUsers,
} from "react-icons/fi";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../auth";

function SideBar() {
  const auth = useAuth();
  // Get the current location
  const location = useLocation();

  // Function to determine if a link is active
  const isActiveLink = (path) => {
    return location.pathname === path;
  };

  return (
    <div>
      {/* <div className="SideDrawer">
        <div className="SideLogo">
          <img src="/assets/247.png" alt="" className="CleeQ24__Logo" />
        </div>
        <div>
          <div className="SideCover">
            <Link to="/InvestorsDashboard" className="SideLink">
              <div className="SideBarContG">
                <FiGrid className="IconReact" />
                <div>Dashboard</div>
              </div>
            </Link>
          </div>


          <button className={isActive ? 'active' : ''} >
        My Button
      </button>




          <div className="SideCover">
            <Link to="/Transactions" className="SideLink">
              <div className="SideBarContG">
                <FiEdit className="IconReact" />
                <div>Transactions</div>
              </div>
            </Link>
          </div>

          <div className="SideCover">
            <Link to="/Accounts" className="SideLink">
              <div className="SideBarContG">
                <FiUserCheck className="IconReact" />
                <div>Accounts</div>
              </div>
            </Link>
          </div>

        
        </div>
      </div> */}
      <div>
        <div className="SideDrawer">
          <div className="SideLogo">
            <img src="/assets/247.png" alt="" className="CleeQ24__Logo" />
          </div>
          <div>
            <div className="SideCover">
              <Link
                to="/dashboard"
                className={
                  isActiveLink("/dashboard") ? "active" : "inactive"
                }
              >
                <div className="SideBarContG">
                  <FiGrid className="IconReact" />
                  <div>Dashboard</div>
                </div>
              </Link>
            </div>
            <div className="SideCover">
              <Link
                to="/my-referrals"
                className={isActiveLink("/my-referrals") ? "active" : "inactive"}
              >
                <div className="SideBarContG">
                  <FiCornerLeftDown className="IconReact" />
                  <div>My Referral Bonus</div>
                </div>
              </Link>
            </div>
           
            <div className="SideCover">
              <Link
                to="/payouts"
                className={isActiveLink("/payouts") ? "active" : "inactive"}
              >
                <div className="SideBarContG">
                  <FiArrowUpRight className="IconReact" />
                  <div>Payouts</div>
                </div>
              </Link>
            </div>
        
            <div className="SideCover">
              <Link
                to="/account"
                className={isActiveLink("/account") ? "active" : "inactive"}
              >
                <div className="SideBarContG">
                  <FiKey className="IconReact" />
                  <div>Accounts</div>
                </div>
              </Link>
            </div>
         
            <br />
            <div className="SideCover NavBarIcons" >
              <div className="SideBarContG" onClick={() => {
              auth.logout();
            }}>
                <FiToggleLeft className="IconReact " />
                <div>Logout</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideBar;
