import React from "react";
import "./Home.css";
import { FiMail, FiPhone } from "react-icons/fi";
import { BsWhatsapp } from "react-icons/bs";

function ContactUs() {
  return (
    <div>
      
      <br />
      <div className="container p-0">
      <div className="Investors-Heading22">Contact Us</div>

      <br/>

   
      <br/>
   
        <div className="row g-4">
          <div className="col-xl-4">
            <div className="Inv-Cover11">
              <div className="Investors-Heading-Ico">
                <div>
                  <FiMail className="ContactIco" />
                </div>
                <div>
                  <div className="Investors-Heading20">Send an Email:</div>
                  <div className="InvestmentNote">
                    <b>Investment@CleeQ24.com</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4">
            <div className="Inv-Cover11">
              <div className="Investors-Heading-Ico">
                <div>
                  <BsWhatsapp className="ContactIco" />
                </div>
                <div>
                  <div className="Investors-Heading20">WhatsApp:</div>
                  <div className="InvestmentNote">
                    <b>07057856362</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4">
            <div className="Inv-Cover11">
              <div className="Investors-Heading-Ico">
                <div>
                  <FiPhone className="ContactIco" />
                </div>
                <div>
                  <div className="Investors-Heading20">Call:</div>
                  <div className="InvestmentNote">
                    <b>07057856362</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
