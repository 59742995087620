import React from "react";
import "../../Components/Responsive.css";
import "../../Components/Res.css";
import "../../Components/UserDashboard.css";
import "../../Components/SweetAlert.css";
import "../../Components/Modal.css";

function RemainingEquity() {
  return (
    <div>
      <span>15%</span>
    </div>
  );
}

export default RemainingEquity;
