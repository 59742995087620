import React, { useState } from "react";
import "../InvestorComponent/Pagination.css";
import { Menu } from "@material-ui/icons";
import "../../Components/UserDashboard.css";
import "../../Components/Responsive.css";
import "../../Components/Clique24Logo/Clique24Logo.css";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import UserNavBar from "../../Components/UserNavBar";
import Footer from "../../Components/Footer";
import SideBar from "../../Components/SideBar";
import InvestorsCount from "./InvestorsCount";
import InvestorsTable from "./InvestorsTable";
import UserSettings from "../../Components/UserSettings";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

function Investors() {
  const [withdrawfundsShow, setWithdrawfundsShow] = useState(false);
  const handleWithdrawfunds = () => setWithdrawfundsShow(false);

  const [active, setActive] = useState("Dashboard");

  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [anchor, SetAnchor] = React.useState("left");

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className="SideShift">
        <SideBar />
      </div>
    </div>
  );
  return (
    <>
      <div>
        <div className="Main-Side-Bar Parent">
          <aside className="SideBarResponsive">
            <SideBar />
          </aside>

          <main className="MainDash ">
            <div className="NavBarContainer1">
              <div>
                <React.Fragment key={anchor}>
                  <button
                    onClick={toggleDrawer(anchor, true)}
                    className="MenuSideBar"
                  >
                    <Menu className="Menu-Icon1" />
                  </button>
                  <SwipeableDrawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    onOpen={toggleDrawer(anchor, true)}
                  >
                    {list(anchor)}
                  </SwipeableDrawer>
                </React.Fragment>
              </div>
            </div>
            <div className="wrapper_inner FooterTarget ">
            <div className="NavBarSetting">
              <UserSettings />
            </div>
              <InvestorsCount />
              <InvestorsTable />
              <footer>
                {/* <Footer /> */}
              </footer>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}

export default Investors;
