import axios from '../axiosinstance';

      
export function listReferrals(initData){
        return axios.get('/my-referrals', { params: initData })
}

export function addReferral(data){
        return axios.post('/my-referrals', data)
}

export function getReferral(initData){
        return axios.get('/my-referrals/'+initData?.id)
}

export function updateReferral(id, initData){
        return axios.patch('/my-referrals/'+id, initData)
}

export function referralCount(search){
        return axios.get('/my-referrals/stat/count', { params: search })
}

export function referralSum(search){
        return axios.get('/my-referrals/stat/sum', { params: search })
}

