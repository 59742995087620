import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { investSumUnits } from '../../../services/InvestService'

const initialState = {
    loading: false,
    total: 0,
}

export const fetchInvestUnitsSum = createAsyncThunk('fetchInvestUnitsSum', (initData) => {
    return investSumUnits(initData)
    .then((response) =>  response.data.data)
})


const investUnitsSumSlice = createSlice({
    name: 'investUnitsSum',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchInvestUnitsSum.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchInvestUnitsSum.fulfilled, (state, action ) => {
        state.loading = false
        state.total = action.payload[0]?.amount
       })
    }
})

export default investUnitsSumSlice.reducer 