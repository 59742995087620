import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { investCount } from '../../../services/InvestService'

const initialState = {
    loading: false,
    count: 0,
}

export const fetchInvestsPaidCount = createAsyncThunk('fetchInvestsPaidCount', (initData) => {
    return investCount(initData)
    .then((response) =>  response.data.data)
})


const investsPaidCountSlice = createSlice({
    name: 'investsPaidCount',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchInvestsPaidCount.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchInvestsPaidCount.fulfilled, (state, action ) => {
        state.loading = false
        state.count = action.payload
       })
    }
})

export default investsPaidCountSlice.reducer 