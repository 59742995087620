import { Routes, Route } from 'react-router-dom'
import { RequiredAuth } from "./RequiredAuth";
import { AuthProvider } from "./auth";
import { RedirectAuth } from "./RedirectAuth";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from './Pages/Login/Login';
import VerifyOTP from './Pages/SignUp/VerifyOTP';
import InvestorsDashboard from './Pages/Dashboard/InvestorsDashboard';
import Users from './Pages/Users/Users';
import Payout from './Pages/Payout/Payout';
import Investors from './Pages/Investments/Investors';

import { Dashboard } from '@material-ui/icons';
import Accounts from './Pages/Accounts/Accounts';
import Transactions from './Pages/Transactions/Transactions';
import Home from './Pages/Home/Home';
import Signup from './Pages/SignUp/Signup';
import Referral from './Pages/Referals/Referral';
import MyReferrals from './Pages/MyReferrals/MyReferrals';
import MainRoutes from './Pages/MainRoutes/MainRoutes';
import ConfirmPhoneNumber from './Pages/SignUp/ConfirmPhoneNumber';
import SignUpSuccessful from './Pages/SignUp/SignUpSuccessful';


function App() {
  return ( 
    <div> 
        <AuthProvider>
          <Routes>
            <Route index element={<Home />}></Route>
            <Route path="/login" element={<RedirectAuth><Login /></RedirectAuth>}></Route>
            <Route path="/signup" element={<RedirectAuth><Signup /></RedirectAuth>}></Route>
            <Route path="/confirm-phone" element={<RedirectAuth><ConfirmPhoneNumber /></RedirectAuth>}></Route>
            <Route path="/verify-otp" element={<RedirectAuth><VerifyOTP /></RedirectAuth>}></Route>
            <Route path="/successful" element={<RedirectAuth><SignUpSuccessful /></RedirectAuth>}></Route>
            <Route path="/*" element={<RequiredAuth><MainRoutes /></RequiredAuth>}></Route>
            {/* <Route path="/Login" exact component={Login} /> 
            <Route path="/Signup" exact component={Signup} /> 
            <Route path="/VerifyOTP" component={VerifyOTP} />
            <Route path="/InvestorsDashboard" component={InvestorsDashboard} />  
            <Route path="/Users" component={Users} />  
            <Route path="/Payout" component={Payout} />  
            <Route path="/Dashboard" component={Dashboard} />  
            <Route path="/Investors" component={Investors} />  
            <Route path="/Transactions" component={Transactions} />  
            <Route path="/Accounts" component={Accounts} />  
            <Route path="/Referral" component={Referral} /> 
            <Route path="/MyReferrals" component={MyReferrals} />  */}
            
          </Routes> 
          <ToastContainer />
        </AuthProvider>
    </div>
  );
}

export default App;



