import React from "react";
import "../../Components/Responsive.css";
import "../../Components/Res.css";
import "../../Components/UserDashboard.css";
import "../../Components/SweetAlert.css";
import "../../Components/Modal.css";

function TermsAndConditions() {
  return (
    <div className="container-fluid p-0 overflow-hidden">
      <div>
        <div className="container-fluid  p-0">
          <div className="row g-3">
            <div className="col-lg-12">
              <div className="Invest-Dashboard202">
                <div className="EquityNumber">Our Terms and conditions</div>
                <div className="TermsAndConditions">
                  Our Terms and conditions Note
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermsAndConditions;
