import React, { useState, useEffect } from "react";
import "../../Components/Responsive.css";
import "../../Components/Res.css";
import "../../Components/UserDashboard.css";
import "../../Components/SweetAlert.css";
import "../../Components/Modal.css";
import {
  FcCurrencyExchange,
  FcIcons8Cup,
  FcMoneyTransfer,
  FcPositiveDynamic,
} from "react-icons/fc";
import { Modal } from "react-bootstrap";
import { Close } from "@material-ui/icons";
import Swal from "sweetalert2";
import InvestedAmount from "./InvestedAmount";
import Timer from "../../Components/Timer";
import NumberOfInvestors from "../InvestorComponent/NumberOfInvestors";
import UnitAmount from "../InvestorComponent/UnitAmount";
import { FcBullish } from "react-icons/fc";
import { Link, useLocation } from "react-router-dom";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import CurrencyFormat from "react-currency-format";
import { addInvest } from "../../services/InvestService";
import { toast } from "react-toastify";
import { fetchInvests } from "../../features/invests/investsSlice";
import { useDispatch, useSelector } from "react-redux";
import { Bars } from "react-loader-spinner";
import { fetchInvestsCount } from "../../features/invests/counts/investsCountSlice";
import { fetchInvestSum } from "../../features/invests/sums/investSumSlice";
import { fetchInvestROISum } from "../../features/invests/sums/investROISumSlice";
import { fetchReferralPayoutSum } from "../../features/referrals/sums/referralPayoutSumSlice";
import { getUser } from "../../services/AuthService";
import { fetchInvestorsCount } from "../../features/investors/counts/investorsCountSlice";

function InvestmentCount() {
  const dispatch = useDispatch();
  const user = getUser();
  const investsCount = useSelector((state) => state.investsCount);
  const investSum = useSelector((state) => state.investSum);
  const investROISum = useSelector((state) => state.investROISum);
  const referralPayoutSum = useSelector((state) => state.referralPayoutSum);

  const [unit, setUnit] = useState(0);
  const [errUnit, setErrUnit] = useState(0);
  const [loading, setLoading] = useState(false);

  const [investinusShow, setInvestinusShow] = useState(false);
  const handleInvestinus = () => setInvestinusShow(false);

  useEffect(() => {
    if (user?._id) {
      setTimeout(() => {
        dispatch(fetchInvestsCount());
        dispatch(fetchInvestSum());
        dispatch(fetchInvestROISum());
        dispatch(fetchReferralPayoutSum({ status: "paid" }));
        dispatch(fetchInvestorsCount());
      }, 5000);
    }
  }, []);

  // const calPerc = async(value) => {
  //   const fixPerc = 25
  //   const fixAmount = await fixPerc * 100;
  //   const fixTotal = await fixAmount / value
  //   return fixTotal
  // }

  const handleInvest = () => {
    // onClose();
    setInvestinusShow(false);

    Swal.fire({
      customClass: {
        confirmButton: "confirm-button-class",
        title: "title-class",
        icon: "icon-class",
        text: "text-class",
      },

      title: "Thank you for making the right choice",
      text: "Your investment will be processed shortly",
      icon: "warning",
      confirmButtonText: "OK!",
      //   showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          customClass: {
            confirmButton: "confirm-button-class",
            title: "title-class",
            icon: "icon-class",
            text: "text-class",
          },

          title: "Your Investment is Successful!",
          text: "Thank you for Investing with us",
          icon: "success",
          // confirmButtonText: "Feels Good!",
          timer: 2000,
        });
      }
    });
  };

  const handleAdd = () => {
    if (!unit) {
      setErrUnit("Unit is required");
      return;
    }
    let date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    setLoading(true);

    const data = {
      unit: unit,
      amount: parseInt(unit) * 20000,
      roi: (25 * parseInt(unit) * 20000) / 100 + parseInt(unit) * 20000,
      roi_date: date.toISOString(),
    };

    console.log(data);
    addInvest(data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Invested"
          );
          setUnit(0);
          setInvestinusShow(false);
          dispatch(fetchInvests({}));
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },

            icon: "success",
            title: "Successful",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <div>
      <div className="container overflow-hidden p-0">
        <div className="Investors-Dashboard-Body">

          <div className="container p-0">
            <div className="Investment-Advice4">
              <div className="Investment-Advice">
                <div className="Investment-Advice-Cont">
                  <FcBullish className="Investment-Advice-Icon" />
                </div>

                <div>Earn ₦ 500 by referring someone to invest with us.</div>
              </div>

              <div>
                <Link to="/my-referrals" className="LinkClasss">
                  Click here see your Referral Link and code
                </Link>
              </div>
            </div>
          </div>

          <br />

          <div className="Investors-Dashboard-Heading-Container">
            <div className="Investors-Dashboard-Heading20">Dashboard</div>
            <button
              className="Investors-Funding-Button "
              onClick={() => setInvestinusShow(true)}
            >
              <div>Invest</div>
              <div className="IconCont1">
                <FcPositiveDynamic className="AccIcon" />
              </div>
            </button>
          </div>
          <br />
          <div className="container overflow-hidden p-0 Investors-Dashboard-Overview">
            <div className="row g-4">
              <div className="col-xl-5 ">
                <div className="Investors-Invested-Amount33 Investment-Mobile">
                  <div>
                    <div className="Investors-Invested-Heading">
                      <div className="Investors-Amount-Heading">
                        No of Investors
                      </div>

                      <div className="Verified-Opp">Veified Oportunity</div>
                    </div>
                    <div className="Investors-Amount">
                      <NumberOfInvestors />
                    </div>
                  </div>
                  <div>
                    <div>
                      <div className="Invest-DashboardContainer">
                        <div className="INVRemaining">Unit Amount</div>
                        <div className="INVRemaining">
                          25% returns in 12 months
                        </div>
                      </div>
                      <div className="InvAmtHeadingWrapper">
                        <div className="Investors-Amount">
                          <span>&#8358;&nbsp;</span>
                          <UnitAmount />
                          <span>&nbsp;/&nbsp;Unit</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-7">
                <div className="container p-0">
                  <div className="row g-2">
                    <div className="col-xl-4">
                      <div className="Investors-Invested-Amount22 Investment-Mobile">
                        <div className="Investors-Invested-Heading">
                          <div className="Investors-Amount-Heading30">
                            No of your Investments
                          </div>
                          <div className="IconCont1">
                            <FcIcons8Cup className="AccIcon" />
                          </div>
                        </div>
                        <div className="Investors-Amount">
                          {investsCount?.loading
                            ? "Loading..."
                            : investsCount?.count}
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4">
                      <div className="Investors-Invested-Amount22 Investment-Mobile">
                        <div className="Investors-Invested-Heading">
                          <div className="Investors-Amount-Heading30">
                            Total Invested Amount
                          </div>
                          <div className="IconCont1">
                            <FcCurrencyExchange className="AccIcon" />
                          </div>
                        </div>
                        <div className="Investors-Amount">
                          <InvestedAmount
                            loading={investSum?.loading}
                            amount={investSum?.total}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4">
                      <div className="Investors-Invested-Amount22 Investment-Mobile">
                        <div className="Investors-Invested-Heading">
                          <div className="Investors-Amount-Heading30">
                            Total Bonus
                          </div>
                          <div className="IconCont1">
                            <FcMoneyTransfer className="AccIcon" />
                          </div>
                        </div>
                        {referralPayoutSum?.loading ? (
                          <div>Loading</div>
                        ) : (
                          <CurrencyFormat
                            value={referralPayoutSum?.total || 0}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"₦ "}
                            renderText={(value) => (
                              <div className="Investors-Amount">{value}</div>
                            )}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        size="lg"
        show={investinusShow}
        onHide={() => setInvestinusShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        keyboard={false}
        animation={true}
      >
        <Modal.Body>
          <div className="Clique24__ModalHeadings">
            <div onClick={handleInvestinus}>
              <Close className="Clique24__Close" />
            </div>
          </div>
          <div className="Settings__Modal__Container">
            <div className="Inv-Heading">
              <div className="Log__Cont">
                <img src="/assets/3.png" alt="" className="Log__Img__Modal" />
              </div>
              <div className="Clique24__Headings__Note">
                <div className="Clique24__Settings__Header">Invest with us</div>
                <div className="DisplayUsernames">
                  Follow these three simple steps to successfully invest with
                  us.
                </div>
              </div>
            </div>
            <br />
            <br />
            <div className="Steps">Step 1</div>
            <br />
            <label for="fname" className="UnitEntry">
              Select your desired Units
            </label>
            <div className="DisplayUsernames">
              Use the calculator below to check the amount and the return on
              investment (ROI)
            </div>
            <br />
            <form>
              <div className="mb-3">
                <div className="input-group">
                  <FloatingLabel
                    controlId="floatingUsername"
                    label="Enter the number of Units you want to purchase"
                    className="mb-3 Input__User34"
                  >
                    <Form.Control
                      type="number"
                      placeholder="Unit"
                      value={unit}
                      onChange={(event) => {
                        setUnit(event.target.value);
                      }}
                    />
                  </FloatingLabel>
                </div>
              </div>
            </form>
            <div className="UnitEntry">
              Investment Unit, Amount, and ROI calculator
            </div>
            <div>
              <div className="Invest-Dashboard25 p-0">
                <div className="container p-0">
                  <div className="row g-2">
                    <div className="col-lg-4">
                      <div className="Invest-Equity-Box">
                        <div className="Invest-Equity-Heading">
                          Number of Units
                        </div>
                        <div className="Invest-Equity">&nbsp;{unit | 0}</div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="Invest-Equity-Box">
                        <div className="Invest-Equity-Heading">
                          Amount to Invest
                        </div>
                        <CurrencyFormat
                          value={parseInt(unit) * 20000 || 0}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"₦ "}
                          renderText={(value) => (
                            <div className="Invest-Equity">{value}</div>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="Invest-Equity-Box">
                        <div className="Invest-Equity-Heading">
                          &#8358;&nbsp;R01 in 12 months
                        </div>
                        <CurrencyFormat
                          value={
                            (25 * parseInt(unit) * 20000) / 100 +
                              parseInt(unit) * 20000 || 0
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"₦ "}
                          renderText={(value) => (
                            <div className="Invest-Equity">{value}</div>
                          )}
                        />
                        {/* <div className="Invest-Equity">&#8358;&nbsp;{( 25 * parseInt(unit) * 20000 / 100) + parseInt(unit) * 20000 }</div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />
            <div className="Steps">Step 2</div>
            <div className="BankDetailsWrapper">
              Make deposit to our account details below
            </div>
            <br />
            <div>
              <div className="BankDetailsWrapper">
                CLEEQ24 OFFICIAL BANK ACCOUNT
              </div>
              <div className="BankDetailsContainer">
                <div className="CleeQ24-BankDetails">
                  <div className="CleeQ24-AcccNumber">Account Name</div>-
                  <div className="CleeQ24-Account-Num">CleeQ24 LTD</div>
                </div>
                <div className="CleeQ24-BankDetails">
                  <div className="CleeQ24-AcccNumber">Bank Name</div>-
                  <div className="CleeQ24-Account-Num">GTB</div>
                </div>
                <div className="CleeQ24-BankDetails">
                  <div className="CleeQ24-AcccNumber">Account Number</div>-
                  <div className="CleeQ24-Account-Num">0908765647</div>
                </div>
              </div>
            </div>
            <br /> <br />
            <div className="Steps">Step 3</div>
            <div className="BankDetailsWrapper">
              Send your Investment payment receipt to our official whatsApp
              number and click on the Invest button below
            </div>
            <div className="CleeQ24-LogoInv">
              <div>LOGO</div>
              <div className="CleeQ24-Account-Num">07057856362</div>
            </div>
            <br />
            <div className="Payment__Heading__Cont">
              <div className="Profile__Cont__Wrapper mb-3">
                {loading ? (
                  <Bars
                    heigth="20"
                    width="20"
                    color="gray"
                    ariaLabel="loading-indicator"
                  />
                ) : (
                  <button
                    disabled={!unit}
                    className="Profile__Cont__Saves"
                    onClick={handleAdd}
                  >
                    Invest
                  </button>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default InvestmentCount;
