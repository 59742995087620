import React, { useEffect, useState } from "react";
import "../../Components/Responsive.css";
import "../../Components/Res.css";
import "../../Components/UserDashboard.css";
import "../../Components/SweetAlert.css";
import "../../Components/Modal.css";

import { FiAlertOctagon, FiCheckCircle } from "react-icons/fi";
import TermsAndConditions from "../InvestorComponent/TermsAndConditions";
import PrivacyTerms from "../InvestorComponent/PrivacyTerms";

function Legal() {
  const [active, setActive] = useState("TermsAndConditions");

  const [withdrawfundsShow, setWithdrawfundsShow] = useState(false);
  const handleWithdrawfunds = () => setWithdrawfundsShow(false);

  return (
    <div className="p-3 InvDashContainerStat001">
      <div className="container-fluid p-0 overflow-hidden">
        <div className="col-12">
          <div className=" InvestorsContHeadingWrapper">
            <div className=" InvestorsContHeading ">
              <div className=" InvestorsContHeading ">Legal</div>
              <div className=" BInstructions">
                You are instructed to read through our terms and conditions.
              </div>
            </div>
          </div>
          <div className="InvBtnWrapper40">
            <div
              className={
                active === "TermsAndConditions" ? "IA" : "ICONINACTIVE"
              }
              onClick={() => setActive("TermsAndConditions")}
            >
              <FiCheckCircle className="InvestICO" />
              <div>Terms and Conditions</div>
            </div>
            {/* <div
              className={active === "PrivacyTerms" ? "IA" : "ICONINACTIVE"}
              onClick={() => setActive("PrivacyTerms")}
            >
              <FiAlertOctagon className="InvestICO" />
              <div>Privacy </div>
            </div> */}
          </div>
        </div>
        <div></div>
      </div>

      {active === "TermsAndConditions" && <TermsAndConditions />}
      {active === "PrivacyTerms" && <PrivacyTerms />}
    </div>
  );
}

export default Legal;
