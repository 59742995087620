import React from "react";
import "../../Components/Responsive.css";
import "../../Components/Res.css";
import "../../Components/UserDashboard.css";
import "../../Components/SweetAlert.css";
import "../../Components/Modal.css";

import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
function EnterPin() {
  return (
    <div>
      <div className="input-group">
        <FloatingLabel
          controlId="floatingUsername"
          label="Enter PIN"
          className="Input__User34"
        >
          <Form.Control type="Password" placeholder="PIN" />
        </FloatingLabel>
      </div>
    </div>
  );
}

export default EnterPin;
