
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Close } from "@material-ui/icons";
import Swal from "sweetalert2";
import "../../Components/Responsive.css";
import "../../Components/Res.css";
import "../../Components/UserDashboard.css";
import "../../Components/SweetAlert.css";
import "../../Components/Modal.css";
import EnterPin from "../InvestorComponent/EnterPin";
import { getUser } from "../../services/AuthService";
import { a } from "react-spring";
import CurrencyFormat from "react-currency-format";
import { addPayout } from "../../services/PayoutService";
import { toast } from "react-toastify";
import { fetchReferralSum } from "../../features/referrals/sums/referralSumSlice";
import { fetchReferralPayoutSum } from "../../features/referrals/sums/referralPayoutSumSlice";
import { fetchReferralPayoutCount } from "../../features/referrals/counts/referralPayoutCountSlice";
import { fetchReferrals } from "../../features/referrals/referralsSlice";
import { useDispatch } from "react-redux";
import { fetchReferralCount } from "../../features/referrals/counts/referralCountSlice";

function WithdrawBonus({ show, onClose, onOpen, amount }) {
  const user = getUser();
  const dispatch = useDispatch()

  const [sendmoneydetailsShow, setSendmoneydetailsShow] = useState(false);
  const handleSendmoneydetails = () => setSendmoneydetailsShow(false);

  const [notificationShow, setNotificationShow] = useState(false);
  const handleNotificationDetails = () => setNotificationShow(false);

  const [notificationPinShow, setNotificationPinShow] = useState(false);
  const handleNotificationPinDetails = () => setNotificationPinShow(false);

  const [loading, setLoading] = useState(false);
  const handleSent = () => {
    onClose();
    setSendmoneydetailsShow(true);
  };

  const handleBack = () => {
    onOpen();
    setSendmoneydetailsShow(false);
  };

  const handleSentmoney = () => {
    onClose();
    setSendmoneydetailsShow(false);

    Swal.fire({
      customClass: {
        confirmButton: "confirm-button-class",
        title: "title-class",
        icon: "icon-class",
        text: "text-class",
      },

      title: "Are you sure about this transaction?",
      text: "This transaction will take few hours to reflect in your bank account!",
      icon: "warning",
      confirmButtonText: "Yes!",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        handleAdd()
      }
    });
  };

  const handleAdd = () => {
    setLoading(true);

    const data = {
      amount: amount,
      account_name: user?.account_name,
      account_number: user?.account_number,
      bank_name: user?.bank_name,
      type: "referral",
    };

    console.log(data);
    addPayout(data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Invested"
          );
          onClose()
          setSendmoneydetailsShow(false);
          dispatch(fetchReferrals({}))
          dispatch(fetchReferralCount())
          dispatch(fetchReferralSum({status: 'ready'}))
          dispatch(fetchReferralPayoutSum({status: 'paid'}))
          dispatch(fetchReferralPayoutCount({status: 'paid'}))
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
              text: "text-class",
            },

            title: "Successful!",
            text: "Thank you for Investing with us",
            icon: "success",
            confirmButtonText: "Feels Good!",
            timer: 2000,
          });
        
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }

  return (
    <div>
      <div>
        {/*Clique  SEND MONEY   Settings Modal starts here*/}
        <Modal
          size="lg"
          show={show}
          onHide={onClose}
          aria-labelledby="example-modal-sizes-title-lg"
          keyboard={false}
          animation={true}
        >
          <Modal.Body>
            <div className="Clique24__ModalHeadings">
              <div onClick={onClose}>
                <Close className="Clique24__Close" />
              </div>
            </div>
            <div className="Settings__Modal__Container">
              <div className="Clique24__Headings">
                <div className="Log__Cont">
                  <img src="/assets/3.png" alt="" className="Log__Img__Modal" />
                </div>
                <div className="Clique24__Headings__Note">
                  <div className="Clique24__Settings__Header">
                    Bonus Withdrawal
                  </div>
                  <div className="DisplayUsernames">
                    Withdraw funds to your registered account
                  </div>
                </div>
              </div>
              <br />

              <div className="Payment__Heading__Cont">
                <br />
                <div className="DisplayNames">
                  Beneficiary's Name : <span>{user?.account_name}</span>
                </div>
                <div className="DisplayNames">
                  Bank Name : <span>{user?.bank_name}</span>
                </div>
                <div className="DisplayNames">
                  Account Number: <span>{user?.account_number}</span>
                </div>
                <br /><br />
                <div className="DisplayNames26">
                  Due Amount:{" "}
                  <CurrencyFormat
                    value={amount || 0}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"₦ "}
                    renderText={(value) => (
                      <span>{value}</span>
                    )}
                  />
                  {/* <span>
                    &#8358; Display total Bonus here
                  </span> */}
                </div>
                <br /><br /><br />
                <div className="Profile__Cont__Wrapper mb-3">
                {
                    user?.bank_name && user?.account_number ? (
                      <div className="Profile__Cont__Saves" onClick={handleSent}>
                        Withdraw Bonus
                      </div >
                    ) : (
                      <div className="Profile__Cont__Saves3" onClick={() => setNotificationShow(true)}>
                        Withdraw Funds
                      </div>
                    )
                  }
                  
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/*Clique SEND MONEY  Settings Modal Ends here*/}
      </div>

      {/*Clique  SEND MONEY DETAILS DISPLAY Modal starts here*/}
      <Modal
        size="lg"
        show={sendmoneydetailsShow}
        onHide={() => setSendmoneydetailsShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        keyboard={false}
        animation={true}
      >
        <Modal.Body>
          <div className="Clique24__ModalHeadings">
            <div onClick={handleSendmoneydetails}>
              <Close className="Clique24__Close" />
            </div>
          </div>
          <div className="Settings__Modal__Container">
            <div className="Inv-Heading">
              <div className="Log__Cont">
                <img src="/assets/3.png" alt="" className="Log__Img__Modal" />
              </div>
              <div className="Clique24__Headings__Note">
                <div className="Clique24__Settings__Header">
                  Bonus Withdrawal Confirmation
                </div>
                <div className="DisplayUsernames">
                  Confirm your Bonus withdrawal details
                </div>
              </div>
            </div>
            <br />
            <br />
            <div className="INV-AccountDetails">
              <div className="BenHeadings">Investor's Bank Information </div>
              <div className="BenHeadingsTittle">
                <div className="DisplayNames">Account Name</div>
                <div className="DisplayUsernames">{user?.account_name}</div>
              </div>
              <div className="BenHeadingsTittle">
                <div className="DisplayNames">Account Number</div>
                <div className="DisplayUsernames">{user?.account_number}</div>
              </div>
              <div className="BenHeadingsTittle">
                <div className="DisplayNames">Bank Name</div>
                <div className="DisplayUsernames">{user?.bank_name}</div>
              </div>
            </div>
            <div className="INV-AccountDetails">
            <CurrencyFormat
              value={amount || 0}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"₦ "}
              renderText={(value) => (
                <div className="BenHeadings">{value}</div>
              )}
            />
              {/* <div>
                <div className="DisplayNames">Amount</div>
                <div className="DisplayUsernames">&#8358; Amount display</div>
              </div> */}
            </div>

            <div className="mb-3">
              <EnterPin />
              <div
                className="valid-feedback AlertNot alert alert-danger "
                role="alert"
              >
                Incorrect PIN!
              </div>
            </div>
            <br />
            <div className="Payment__Heading__Cont">
              <div className="Profile__Cont__Wrapper mb-3">
                <div className="Profile__Cont__Saves" onClick={handleBack}>
                  Back
                </div>
                  {
                    user?.pin ? (
                      <button
                        className="Profile__Cont__Saves"
                        onClick={handleSentmoney}
                      >
                        Withdraw Funds
                      </button>
                    ) : (
                      <button className="Profile__Cont__Saves3" onClick={() => setNotificationPinShow(true)}>
                        Withdraw Funds
                      </button>
                    )
                  }
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/*Clique SEND MONEY DETAILS DISPLAY   Modal Ends here*/}

       {/* Notification when no Bank account */}
       <Modal
        size="lg"
        show={notificationShow}
        onHide={() => setNotificationShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        keyboard={false}
        animation={true}
      >
        <Modal.Body>
          <div className="Clique24__ModalHeadings">
            <div onClick={handleNotificationDetails}>
              <Close className="Clique24__Close" />
            </div>
          </div>
          <div className="Settings__Modal__Container">
            <div className="Inv-Heading">
              <div className="Log__Cont">
                <img src="/assets/3.png" alt="" className="Log__Img__Modal" />
              </div>
              <div className="Clique24__Headings__Note">
                <div className="Clique24__Settings__Header">
                  Withdrawal Notification
                </div>
                <div className="DisplayUsernames">
                  Confirm your Bank details are updated
                </div>
              </div>
            </div>
            <br />
            <br />
            <div className="INV-AccountDetails">
              <div className="DisplayUsernames">
                Please go to Accounts - Manage Bank - Add Bank, and add your account details.
              </div>
              <br />
              <div className="DisplayUsernames">
                Your account details is required for this withdral.
              </div>
              <br />
              <div className="DisplayUsernames">
                Thank you.
              </div>
            </div>
            
            <br />
            <div className="Payment__Heading__Cont">
              <div className="Profile__Cont__Wrapper mb-3">
                <div className="Profile__Cont__Saves" onClick={() => setNotificationShow(false)}>
                  Back
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Notification when no PIN */}
      <Modal
        size="lg"
        show={notificationPinShow}
        onHide={() => setNotificationPinShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        keyboard={false}
        animation={true}
      >
        <Modal.Body>
          <div className="Clique24__ModalHeadings">
            <div onClick={handleNotificationPinDetails}>
              <Close className="Clique24__Close" />
            </div>
          </div>
          <div className="Settings__Modal__Container">
            <div className="Inv-Heading">
              <div className="Log__Cont">
                <img src="/assets/3.png" alt="" className="Log__Img__Modal" />
              </div>
              <div className="Clique24__Headings__Note">
                <div className="Clique24__Settings__Header">
                  Withdrawal Notification
                </div>
                <div className="DisplayUsernames">
                  Confirm your PIN is updated
                </div>
              </div>
            </div>
            <br />
            <br />
            <div className="INV-AccountDetails">
              <div className="DisplayUsernames">
                Please go to Accounts - Change Withdrawal PIN, and input your pin.
              </div>
              <br />
              <div className="DisplayUsernames">
                Your Pin is required for this withdral.
              </div>
              <br />
              <div className="DisplayUsernames">
                Thank you.
              </div>
            </div>
            
            <br />
            <div className="Payment__Heading__Cont">
              <div className="Profile__Cont__Wrapper mb-3">
                <div className="Profile__Cont__Saves" onClick={() => setNotificationPinShow(false)}>
                  Back
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default WithdrawBonus;
