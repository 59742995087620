import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { investCount } from '../../../services/InvestService'

const initialState = {
    loading: false,
    count: 0,
}

export const fetchInvestsCount = createAsyncThunk('fetchInvestsCount', (initData) => {
    return investCount(initData)
    .then((response) =>  response.data.data)
})


const investsCountSlice = createSlice({
    name: 'investsCount',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchInvestsCount.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchInvestsCount.fulfilled, (state, action ) => {
        state.loading = false
        state.count = action.payload
       })
    }
})

export default investsCountSlice.reducer 