import { createContext, useContext, useState } from 'react';
import { clearAuth, getToken, getUser } from './services/AuthService'
const AuthContext = createContext(null)

export const AuthProvider = ({children}) => {
        const [user, setUser] = useState(getUser())
        const [token, setToken] = useState(getToken())

        const login = (user, token) => {
                setToken(token)
                setUser(user)
        }

        const signup = (user) => {
                //dispatch(signupAction(user.phone, user.password))
                setUser(user)
        }

        const logout = () => {
                clearAuth();
                setUser(null)
        }

        return (
                <AuthContext.Provider value={{user, token, signup, login, logout}} >
                        {children}
                </AuthContext.Provider>
        )
} 

export const useAuth = () => {
        return useContext(AuthContext)
}