import { SkipNext, SkipPrevious } from "@material-ui/icons";
import React from "react";
import "./Pagination.css";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
}));

function Paginations({handleFetchTransSearch, search, page, setPage, startDate, endDate, pageCount, pageLimit, setPageLimit }) {
        const classes = useStyles();
        const buttonPreviuos = () => {
          if(page > 1){
            setPage(page - 1)
            handleFetchTransSearch(search, pageLimit, page - 1, startDate, endDate )
          }
        }
        
        const buttonNext = () => {
          if(page !== pageCount){
            setPage(page + 1)
            handleFetchTransSearch(search, pageLimit, page + 1, startDate, endDate )
          }
        }
        return (
          <div>
            {/* <div className={classes.root}>
              <Pagination count={10} showFirstButton showLastButton />
            </div> */}
            <div className="Search__paginate">
              {page === 1 ? (
                <div className="Search__paginate-Pre">
                  <SkipPrevious />
                  Previous
                </div>
              ) : (
                <div onClick={buttonPreviuos} className="Search__paginate-Pre2">
                  <SkipPrevious />
                  Previous
                </div>
              )}
              <div>
                Result&nbsp;<span>{page}</span>&nbsp;out of&nbsp;<span>{pageCount}</span>
                &nbsp;page(s)
              </div>
              {page === pageCount ? (
                <div className="Search__paginate-Pre">
                  Next <SkipNext />
                </div>
              ) : (
                <div onClick={buttonNext} className="Search__paginate-Pre2">
                  Next <SkipNext />
                </div>
              )}
            </div>
          </div>
  )
}

export default Paginations