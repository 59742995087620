import React, { useState } from "react";
import "../../Components/Responsive.css";
import "../../Components/Res.css";
import "../../Components/UserDashboard.css";
import "../../Components/SweetAlert.css";
import "../../Components/Modal.css";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import EnterPin from "../InvestorComponent/EnterPin";
import Swal from "sweetalert2";
import { getUser, saveUserInLocalStorage } from "../../services/AuthService";
import { updateInvestorPwd } from "../../services/InvestorService";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";

function ChangePassword() {
  let user = getUser();
  const [password, setPassword] = useState("");
  const [errPassword, setErrPassword] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const [errConfirmPwd, setErrConfirmPwd] = useState("");
  const [loading, setLoading] = useState(false);

  const handleUpdate = () => {
    if (password !== confirmPwd) {
      setErrConfirmPwd("Password does not match");
      return;
    }
    setLoading(true);
    const data = {
      password: password
    }
    updateInvestorPwd(user?._id, data)
      .then(async(response) => {
        setLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          await saveUserInLocalStorage(response.data.data)
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Update"
          );
          //window.location.reload();
          Swal.fire({
            customClass: {
              confirmButton: "confirm-button-class",
              title: "title-class",
              icon: "icon-class",
            },
            title: "Password Successfully updated!",
            icon: "success",
            timer: 2000,
          });
        
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }

  return (
    <div className="p-3 InvDashContainerStat001">
      <div className="container-fluid p-0 overflow-hidden">
        <div className="col-12">
          <div className=" InvestorsContHeading ">
            <div className=" InvestorsContHeading ">Change Password</div>
            <div className=" BInstructions">
              You are adviced to change your password regularly.
            </div>
          </div>
        </div>
        <div></div>
      </div>
      <br />
      <br />
      <div className="container-fluid p-0 overflow-hidden">
        <div>
          <div className="container-fluid  p-0">
            <div className="Invest-Dashboard202">
              <div className="EquityNumberSubCont">
                <div className="EquityNumber">Password</div>
              </div>
              <div>
                <div className="mb-3 mt-3">
                  <div className="input-group">
                    <FloatingLabel
                      controlId="floatingUsername"
                      label="New Password"
                      className="mb-3 Input__User34"
                      value={password}
                      onChange={(event) => {
                        if(event.target.value.length){
                          setErrPassword("")
                        }else{
                          setErrPassword("Password is required")
                        }
                        setPassword(event.target.value)
                      }}
                    >
                      <Form.Control type="text" placeholder="PIN" />
                    </FloatingLabel>
                    {(errPassword) && <div className='input_error'>{errPassword}</div>}
                  </div>
                </div>

             

                {/* <div className="mb-3">
                  <div className="input-group">
                    <FloatingLabel
                      controlId="floatingUsername"
                      label="New Password"
                      className="mb-3 Input__User34"
                    >
                      <Form.Control type="text" placeholder="PIN" />
                    </FloatingLabel>
                  </div>
                </div> */}
                <div className="mb-3">
                  <div className="input-group">
                    <FloatingLabel
                      controlId="floatingUsername"
                      label="Confirm New Password"
                      className="mb-3 Input__User34"
                      value={confirmPwd}
                      onChange={(event) => {
                        if(event.target.value.length){
                          setErrConfirmPwd("")
                        }else{
                          setErrConfirmPwd("Confirm Password is required")
                        }
                        setConfirmPwd(event.target.value)
                      }}
                    >
                      <Form.Control type="text" placeholder="PIN" />
                    </FloatingLabel>
                    {(errConfirmPwd) && <div className='input_error'>{errConfirmPwd}</div>}
                  </div>
                </div>
                <EnterPin />

                <br />
                {
                  loading ? (
                    <Bars
                      heigth="20"
                      width="20"
                      color="gray"
                      ariaLabel="loading-indicator"
                    />
                  ) : (
                    <button disabled={!password && !confirmPwd} className="WithdrawFunds" onClick={handleUpdate}>
                      Save
                    </button>
                  ) 
                }
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
