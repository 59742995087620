import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { payoutSum } from '../../../services/PayoutService'

const initialState = {
    loading: false,
    total: 0,
}

export const fetchPayoutPaidSum = createAsyncThunk('fetchPayoutPaidSum', (initData) => {
    return payoutSum(initData)
    .then((response) =>  response.data.data)
})


const payoutPaidSumSlice = createSlice({
    name: 'payoutPaidSum',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchPayoutPaidSum.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchPayoutPaidSum.fulfilled, (state, action ) => {
        state.loading = false
        state.total = action.payload[0]?.amount
       })
    }
})

export default payoutPaidSumSlice.reducer 