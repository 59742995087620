import React from "react";
import "./Footer.css";

function FooterHome() {
  return (
    <div>
      <div className="UserFooter">
        <img src="/assets/TLogo.png" alt="" className="Footer__Img" />
        <div className="Footer__Note">
          &copy;{new Date().getFullYear()} Clique24 LTD. All Rights Reserved
        </div>
      </div>
    </div>
  );
}

export default FooterHome;
