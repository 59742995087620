import React from "react";
import "../../Components/Responsive.css";
import "../../Components/Res.css";
import "../../Components/UserDashboard.css";
import "../../Components/SweetAlert.css";
import "../../Components/Modal.css";

import TransactionsInfo from "./TransactionsInfo";

function InvestorTransaction() {
  return (
    <>
      <div className="container overflow-hidden p-0">
        <div className="Investors-Dashboard-Body">
          <div className="Investors-Dashboard-Heading-Container">
            <div className="Investors-Dashboard-Heading">
              Withdrawal Details
            </div>
          </div>
          <div className="container overflow-hidden Investors-Transactions">
            <div className="">
              As soon as you make any transaction, your transaction details will
              appear here.
            </div>
          </div>

          <div className="container overflow-hidden Investors-Transactions300">
            <TransactionsInfo />
          </div>

          <center className="Spreader"></center>
        </div>
      </div>
    </>
  );
}

export default InvestorTransaction;
