import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listInvests } from '../../services/InvestService'

const initialState = {
    loading: false,
    invests: [],
    paginator: {},
    isSuccessful: false
}

export const fetchInvests = createAsyncThunk('fetchInvests', (initData) => {
    return listInvests(initData)
    .then((response) => response.data.data)
})


const investsSlice = createSlice({
    name: 'invests',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchInvests.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchInvests.fulfilled, (state, action ) => {
        state.loading = false
        state.invests = action.payload.data
        state.paginator = action.payload.paginator
        state.isSuccessful = true
       })
       builder.addCase(fetchInvests.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default investsSlice.reducer 