import React from "react";
import "../../Components/Responsive.css";
import "../../Components/Res.css";
import "../../Components/UserDashboard.css";
import "../../Components/SweetAlert.css";
import "../../Components/Modal.css";
import InvestmentTransactions from "./InvestmentTransactions";
import InvestmentCount from "./InvestmentCount";

function Dashboard() {
  return (
    <>
      <InvestmentCount />
      <InvestmentTransactions />
    </>
  );
}

export default Dashboard;
