import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { referralCount } from '../../../services/ReferralService'

const initialState = {
    loading: false,
    count: 0,
}

export const fetchReferralPayoutCount = createAsyncThunk('fetchReferralPayoutCount', (initData) => {
    return referralCount(initData)
    .then((response) =>  response.data.data)
})


const referralPayoutCountSlice = createSlice({
    name: 'referralPayoutCount',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchReferralPayoutCount.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchReferralPayoutCount.fulfilled, (state, action ) => {
        state.loading = false
        state.count = action.payload
       })
    }
})

export default referralPayoutCountSlice.reducer 