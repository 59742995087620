import React, { useEffect } from "react";
import "../../Components/Responsive.css";
import "../../Components/Res.css";
import "../../Components/UserDashboard.css";
import "../../Components/SweetAlert.css";
import "../../Components/Modal.css";
import MyReferralInfo from "./MyReferralInfo";
import { useDispatch, useSelector } from "react-redux";
import { fetchReferrals } from "../../features/referrals/referralsSlice";

function MyReferralTable() {
  const dispatch = useDispatch()
  const {referrals} = useSelector((state) => state.referrals)
  console.log("referrals", referrals)

  useEffect(() => {
    dispatch(fetchReferrals({}))
  }, [])
  return (
    <>
      <div className="container overflow-hidden p-0">
        <div className="Investors-Dashboard-Body">
          <div className="Investors-Dashboard-Heading-Container">
            <div className="Investors-Dashboard-Heading">My Referral Details</div>
          </div>
          {
            referrals?.length < 1  ? (
              <div className="container overflow-hidden Investors-Transactions">
                <div className="">You have not made any Referral yet.</div>
              </div>
            ) : null
          }
          {
            referrals?.length > 0  ? (
              <div className="container overflow-hidden Investors-Transactions300">
                <MyReferralInfo />
              </div>
            ) : null
          }
          <center className="Spreader"></center>
        </div>
      </div>
    </>
  );
}

export default MyReferralTable;
