import React from "react";
import "../../Components/Responsive.css";
import "../../Components/Res.css";
import "../../Components/UserDashboard.css";
import "../../Components/SweetAlert.css";
import "../../Components/Modal.css";
import { useSelector } from "react-redux";
import CurrencyFormat from "react-currency-format";

function NumberOfInvestors() {
  const investorsCount = useSelector((state) => state.investorsCount)

  return (
    <div>
      {
        investorsCount?.loading ? (
          <span>Loading...</span>
        )  : (
          <CurrencyFormat
            value={(1000 + investorsCount?.count) || 0}
            displayType={"text"}
            thousandSeparator={true}
            renderText={(value) => (
              <span>{value}</span>
            )}
          />
        )
      }
    </div>
  )
}

export default NumberOfInvestors