import "../../Components/Responsive.css";
import "../../Components/Res.css";
import "../../Components/UserDashboard.css";
import "../../Components/SweetAlert.css";
import "../../Components/Modal.css";
import {
  FcLineChart,
  FcMultipleInputs,
  FcNegativeDynamic,
} from "react-icons/fc";
import React, { useEffect, useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { Modal } from "react-bootstrap";
import { Close } from "@material-ui/icons";
import EnterPin from "../InvestorComponent/EnterPin";
import RemainingEquity from "../InvestorComponent/RemainingEquity";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { fetchPayoutCount } from "../../features/payouts/counts/payoutCountSlice";
import { fetchPayoutSum } from "../../features/payouts/sums/payoutSumSlice";
import CurrencyFormat from "react-currency-format";
import { fetchPayoutPaidSum } from "../../features/payouts/sums/payoutPaidSumSlice";
import { fetchPayoutPaidCount } from "../../features/payouts/counts/payoutPaidCountSlice";
import { fetchPayouts } from "../../features/payouts/payoutsSlice";

function PayoutCount() {
  const dispatch = useDispatch()
  const payoutCount = useSelector((state) => state.payoutCount)
  const payoutPaidCount = useSelector((state) => state.payoutPaidCount)
  const payoutSum = useSelector((state) => state.payoutSum)
  const payoutPaidSum = useSelector((state) => state.payoutPaidSum)

  useEffect(() => {
    dispatch(fetchPayoutCount({status: "pending"}))
    dispatch(fetchPayoutPaidCount({status: "paid"}))
    dispatch(fetchPayoutSum({status: "pending"}))
    dispatch(fetchPayoutPaidSum({status: "paid"}))
    
  }, [])

  const [investinusShow, setInvestinusShow] = useState(false);
  const handleInvestinus = () => setInvestinusShow(false);

  const handleInvest = () => {
    setInvestinusShow(false);

    Swal.fire({
      customClass: {
        confirmButton: "confirm-button-class",
        title: "title-class",
        icon: "icon-class",
        text: "text-class",
      },

      title: "Thanks for making the right choice",
      text: "Your investment will be processed shortly",
      icon: "warning",
      confirmButtonText: "Yes!",
      //   showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          customClass: {
            confirmButton: "confirm-button-class",
            title: "title-class",
            icon: "icon-class",
            text: "text-class",
          },

          title: "Your Investment is Successful!",
          text: "Thank you for Investing with us",
          icon: "success",
          confirmButtonText: "Feels Good!",
          //       timer: 2000,
        });
      }
    });
  };

  const statusChange = (type) => {
    if(type === 'pending'){
      dispatch(fetchPayouts({status: 'pending'}))
    }
    if(type === 'paid'){
      dispatch(fetchPayouts({status: 'paid'}))
    }
  }
  return (
    <div>
      <div className="container overflow-hidden p-0">
        <div className="Investors-Dashboard-Body">
          {/* <div className="CleeQBody2">eee</div> */}
          <div className="Investors-Dashboard-Heading-Container">
            <div className="Investors-Dashboard-Heading">Payouts</div>
          </div>
          <br />
          <div className="container overflow-hidden p-0 Investors-Dashboard-Overview">
            <div className="row g-4">
              <div onClick={() => statusChange('pending')}  className="col-xl">
                <div className="Investors-Invested-Amount Investment-Mobile">
                  <div className="Investors-Invested-Heading">
                    <div className="Investors-Amount-Heading">Pending</div>

                    <div className="IconCont1">
                      <FcMultipleInputs className="AccIcon" />
                    </div>
                  </div>
                  <div className="Investors-Amount20">
                    <span>{payoutCount?.loading ? "Loading..." : payoutCount?.count }</span>
                    {
                      payoutSum?.loading ? (
                        <span>Loading...</span>
                      ) : (
                        <CurrencyFormat
                          value={payoutSum?.total || 0}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"₦ "}
                          renderText={(value) => (
                            <span>{value}</span>
                          )}
                        />
                      )
                    }
                  </div>
                </div>
              </div>
              <div onClick={() => statusChange('paid')}  className="col-xl">
                <div className="Investors-Invested-Amount Investment-Mobile">
                  <div className="Investors-Invested-Heading">
                    <div className="Investors-Amount-Heading">Paid</div>

                    <div className="IconCont1">
                      <FcMultipleInputs className="AccIcon" />
                    </div>
                  </div>
                  <div className="Investors-Amount20">
                    <span>{payoutPaidCount?.loading ? "Loading..." : payoutPaidCount?.count }</span>
                    {
                      payoutPaidSum?.loading ? (
                        <span>Loading...</span>
                      ) : (
                        <CurrencyFormat
                          value={payoutPaidSum?.total || 0}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"₦ "}
                          renderText={(value) => (
                            <span>{value}</span>
                          )}
                        />
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        size="lg"
        show={investinusShow}
        onHide={() => setInvestinusShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        keyboard={false}
        animation={true}
      >
        <Modal.Body>
          <div className="Clique24__ModalHeadings">
            <div onClick={handleInvestinus}>
              <Close className="Clique24__Close" />
            </div>
          </div>
          <div className="Settings__Modal__Container">
            <div className="Inv-Heading">
              <div className="Log__Cont">
                <img src="/assets/3.png" alt="" className="Log__Img__Modal" />
              </div>
              <div className="Clique24__Headings__Note">
                <div className="Clique24__Settings__Header">Invest with us</div>
                <div className="DisplayUsernames">
                  Enter the equity percentage accordignly
                </div>
              </div>
            </div>
            <br /> <br /> <br />
            <div>
              <div className="Invest-Dashboard">
                <div className="Invest-DashboardContainer">
                  <div className="INVRemaining">
                    {/* Net Profit&nbsp;&#8358; */}
                    Total Equity Remaining
                  </div>
                  <div className="IconCont1">
                    <FcLineChart className="AccIcon" />
                  </div>
                </div>
                <div className="InvAmtHeadingWrapper">
                  <div className="InvAmtHeading">
                    <RemainingEquity />
                  </div>
                  {/* <Settings /> */}
                </div>
              </div>
            </div>
            <br /> <br />
            <div className="InCont">
              <div className="InContWrapper">
                <div className="mb-3">
                  <FloatingLabel
                    controlId="floatingUsername"
                    label="First Name"
                    className="Input__User34"
                  >
                    <Form.Control type="text" placeholder="Last Name" />
                  </FloatingLabel>
                </div>
              </div>
              <div className="InContWrapper">
                <div className="mb-3">
                  <FloatingLabel
                    controlId="floatingUsername"
                    label="LastName"
                    className="Input__User34"
                  >
                    <Form.Control type="text" placeholder="Last Name" />
                  </FloatingLabel>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <FloatingLabel
                controlId="floatingUsername"
                label="Equity %"
                className="Input__User34"
              >
                <Form.Control type="number" placeholder="First Name" />
              </FloatingLabel>
            </div>
            <div className="InCont">
              <div className="InContWrapper">
                <div className="mb-3">
                  <FloatingLabel
                    controlId="floatingUsername"
                    label="Phone number"
                    className="Input__User34"
                  >
                    <Form.Control type="number" placeholder="Last Name" />
                  </FloatingLabel>
                </div>
              </div>
              <div className="InContWrapper">
                <div className="mb-3">
                  <FloatingLabel
                    controlId="floatingUsername"
                    label="Password"
                    className="Input__User34"
                  >
                    <Form.Control type="password" placeholder="Last Name" />
                  </FloatingLabel>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <EnterPin />
              <div
                className="valid-feedback AlertNot alert alert-danger "
                role="alert"
              >
                Incorrect PIN!
              </div>
            </div>
            <br />
            <div className="Payment__Heading__Cont">
              <div className="Profile__Cont__Wrapper mb-3">
                <button className="Profile__Cont__Saves" onClick={handleInvest}>
                  Invest
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default PayoutCount;
