import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { referralSum } from '../../../services/ReferralService'

const initialState = {
    loading: false,
    total: 0,
}

export const fetchReferralPayoutSum = createAsyncThunk('fetchReferralPayoutSum', (initData) => {
    return referralSum(initData)
    .then((response) =>  response.data.data)
})


const referralPayoutSumSlice = createSlice({
    name: 'referralPayoutSum',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchReferralPayoutSum.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchReferralPayoutSum.fulfilled, (state, action ) => {
        state.loading = false
        state.total = action.payload[0]?.amount
       })
    }
})

export default referralPayoutSumSlice.reducer 