import React, { useState } from "react";
import "../../Components/Responsive.css";
import "../../Components/Res.css";
import "../../Components/UserDashboard.css";
import "../../Components/SweetAlert.css";
import "../../Components/Modal.css";
import "../InvestorComponent/Pagination.css";
import { CiBadgeDollar } from "react-icons/ci";
import AllTransactions from "./AllTransactions";


function TransactionsInfo() {
  const [active, setActive] = useState("AllTransactions");

  return (
    <div>
      <div className="container-fluid overflow-hidden p-0">
        <div className="TransactionCont">
          <div
            className={active === "AllTransactions" ? "IA" : "ICONINACTIVE"}
            onClick={() => setActive("AllTransactions")}
          >
            <CiBadgeDollar className="InvestICO" />
            <div className="User-Headings">All Withdrawals</div>
          </div>
        </div>

        <div>
          <div className="Invwrapper_inner">
            {active === "AllTransactions" && <AllTransactions />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TransactionsInfo;
