import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { investorsCount } from '../../../services/InvestorService'

const initialState = {
    loading: false,
    count: 0,
}

export const fetchInvestorsCount = createAsyncThunk('fetchInvestorsCount', (initData) => {
    return investorsCount(initData)
    .then((response) =>  response.data.data)
})


const investorsCountSlice = createSlice({
    name: 'investorsCount',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchInvestorsCount.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchInvestorsCount.fulfilled, (state, action ) => {
        state.loading = false
        state.count = action.payload
       })
    }
})

export default investorsCountSlice.reducer 