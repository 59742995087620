import React, { useState } from "react";
import "../../Components/Responsive.css";
import "../../Components/Res.css";
import "../../Components/UserDashboard.css";
import "../../Components/SweetAlert.css";
import "../../Components/Modal.css";
import AccountsDisplay from "./AccountsDisplay";
import { FiChevronRight } from "react-icons/fi";
import ChangePassword from "./ChangePassword";
import Legal from "./Legal";
import ManageInvestorBank from "./ManageInvestorBank";
import WithdrawalPIN from "./WithdrawalPIN";

function AccountDetails() {
  const [active, setActive] = useState("AccountsDisplay");

  return (
    <div className="TrasactionHeadings ">
    <div className="container overflow-hidden p-0">
     
        <div className="row g-3">
          <div className="col-12">
            <div className=" InvetSubDashHeadingsCover">
              <div className=" InvetSubDashHeadings ">
                Investor's perosnal details
              </div>
            </div>
          </div>
          <div className="col-xl-4">
            <div className="container-fluid p-0">
              <div className="row g-3">
                <div className="col">
                  <div className="InvestorsCont">
                    <div className="InvestorsContWrapperT30">
                      <div className="AccountHeading">Accounts</div>
                    </div>
                    <div className="Investor-NamesCont">
                      {/* <div className="Investor-Names">
                        Investor Name display
                      </div> */}
                      {/* <div className="Investor-UserNames">
                        Investor ID dispaly
                      </div>
                      <div className="Investor-UserNames">
                        Date : <span>Display date here</span>
                      </div>
                      <br />
                      <div className="Investor-UserNames">
                        Invesror Type <span>Display Type here</span>
                      </div> */}
                    </div>
                    <div className="Investor-Accounts">
                      <div
                        className={active === "AccountsDisplay" ? "AAA" : "III"}
                        onClick={() => setActive("AccountsDisplay")}
                      >
                        <div className="Inv-Heading">Personal Information</div>
                        <FiChevronRight className="Inv-HeadingIcon" />
                      </div>
                    </div>
                    <br /> <br />
                    <div className="InvestorsContWrapperT30">
                      <div className="AccountHeading">Privacy</div>
                    </div>
                    <div className="Investor-Accounts">
                      <div
                        className={
                          active === "ManageInvestorBank" ? "AAA" : "III"
                        }
                        onClick={() => setActive("ManageInvestorBank")}
                      >
                        <div className="Inv-Heading">Manage Bank</div>
                        <FiChevronRight className="Inv-HeadingIcon" />
                      </div>
                      {/* <FiChevronRight/> */}
                    </div>
                    <div className="Investor-Accounts">
                      <div
                        className={active === "Legal" ? "AAA" : "III"}
                        onClick={() => setActive("Legal")}
                      >
                        <div className="Inv-Heading">Legal</div>
                        <FiChevronRight className="Inv-HeadingIcon" />
                      </div>
                    </div>
                    <br /> <br />
                    <div className="InvestorsContWrapperT30">
                      <div className="AccountHeading">Security</div>
                    </div>
                    <div className="Investor-Accounts">
                      <div
                        className={active === "ChangePassword" ? "AAA" : "III"}
                        onClick={() => setActive("ChangePassword")}
                      >
                        <div className="Inv-Heading">Change Password</div>
                        <FiChevronRight className="Inv-HeadingIcon" />
                      </div>
                    </div>
                    <div className="Investor-Accounts">
                      <div
                        className={active === "WithdrawalPIN" ? "AAA" : "III"}
                        onClick={() => setActive("WithdrawalPIN")}
                      >
                        <div className="Inv-Heading">Change Withdrawal PIN</div>
                        <FiChevronRight className="Inv-HeadingIcon" />
                      </div>
                    </div>
                    {/* <div className="Investor-Accounts">
                      <div
                        className={active === "" ? "AAA" : "III"}
                        onClick={() => setActive("")}
                      >
                        <div className="Inv-Heading">Logout</div>
                        <FiChevronRight className="Inv-HeadingIcon" />
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-8">
            <div className=" DashContainerStat2">
              <div className="container-fluid p-0">
                <div className="row">
                  <div className="col">
                    {active === "AccountsDisplay" && <AccountsDisplay />}
                    {active === "ManageInvestorBank" && <ManageInvestorBank />}
                    {active === "ChangePassword" && <ChangePassword />}
                    {active === "Legal" && <Legal />}
                    {active === "WithdrawalPIN" && <WithdrawalPIN />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountDetails;
