import React from "react";
import { Link, useNavigate } from "react-router-dom";


function SignUpSuccessful() {
  let navigate = useNavigate();

  const getStared = () => {
    navigate("/dashboard")
  }
  return (
    <div className="LoginBody">
        <header>

        </header>
      <div className="DashBoardContainer">
        <div className="column22 ">
          <div className="logoSignup__Cont">
            <div className="logo__Cont">
              <img src="/assets/3.png" alt="" className="ImgLogo" />
            </div>
            <div className="logo__SignUp__Note">
              <div className="SignUp__Header">Sign Up for free..</div>

              <div className="SignUp__Header__Note">
                Sign Up with clique24 for free and explore..
              </div>
            </div>
          </div>
          <br />
          <br />
          <br />
          <div className="SignUp__success__Note">
            Congratulations! <br />
            <span className="SignUp__success__Note__Tittle">
              {" "}
              Your Sign up was Successful.
            </span>
          </div>
          <br />{" "}
          <div>
            <Link to="/Login" className="Clique__Link">
              <button className="Log__Btn" onClick={getStared}>Login</button>
            </Link>
          </div>
        </div>

        <footer>
          {/* <FooterHome /> */}
        </footer>
      </div>
    </div>
  );
}

export default SignUpSuccessful;
