import React, { useEffect, useState } from "react";
import "../../Components/Responsive.css";
import "../../Components/Res.css";
import "../../Components/UserDashboard.css";
import "../../Components/SweetAlert.css";
import "../../Components/Modal.css";
import "../InvestorComponent/Pagination.css";
import { MoreVert } from "@material-ui/icons";
import Paginations from "../InvestorComponent/Paginations";
import TransactionView from "../InvestorComponent/TransactionView";
import ViewRephrals from "./ViewRephrals";
import moment from "moment";
import { fetchReferrals } from "../../features/referrals/referralsSlice";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../services/AuthService";
import CurrencyFormat from "react-currency-format";

function MyAllReferral() {
  const dispatch = useDispatch()
  const user = getUser();
  const {referrals, paginator, loading} = useSelector((state) => state.referrals)

  const [transactionViewShow, setTransactionViewShow] = useState(false);
  const handleTransactionView = () => setTransactionViewShow(false);
  const [search2, setSearch2] = useState('');
  const [page, setPage] = useState(paginator?.currentPage || 1);
  const [pageLimit, setPageLimit] = useState(10);
  const [pageCount, setPageCount] = useState(paginator?.pageCount || 0);
  const [selected, setSelected] = useState({})

  const showDate = (passDate) => {
    return moment(passDate).format('DD-MM-YYYY')
  }

  useEffect(() => {
    handleFetchMyReferrals()
  }, [])

  const handleFetchMyReferrals = (search = "", limit = 10, page = 1) => {
    dispatch(fetchReferrals({name: search, page_size: limit, page:page }))
  }

  const handleMyUpdate = (search = search2, limit = pageLimit, page = 1) => {
    dispatch(fetchReferrals({name: search, page_size: limit, page:page }));
  }

  return (
    <>
      <Paginations 
        handleFetchTransSearch={handleMyUpdate} 
        page={page} 
        setPage={setPage} 
        pageCount={paginator?.pageCount || 0} 
        pageLimit={pageLimit}
        setPageLimit={setPageLimit}
      />
      {
        referrals?.map((item, index) => (
          <div className="Investment-Transaction-Info50">
            <div className="Investors-TransactionRef">
              <div className="Wallet-Ref-Heading">Ref Number</div>
              <div className="Wallet-Ref-Result">{item?.cus_code}</div>
            </div>

            <div className="Inv-Referrer">
              <div className="Wallet-Ref-Heading">Referrer</div>
              <div className="Wallet-Ref-Result"> {item?.phone}</div>
            </div>
            <div className="Inv-Maturity-Date">
              <div className="Wallet-Ref-Heading">Investor</div>
              <div className="Wallet-Ref-Result"> {user?.phone}</div>
            </div>
            <div className="Inv-Bonus">
              <div className="Wallet-Ref-Heading">Bonus</div>
              <CurrencyFormat
                value={item?.referral_amount || 0}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₦ "}
                renderText={(value) => (
                  <div className="Wallet-Ref-Result">{value}</div>
                )}
              />
            </div>

            {/* <div className="Inv-Duration">
              <div className="Wallet-Ref-Heading">&#8358; Amount Paid</div>
              <div className="Wallet-Ref-Result">&#8358; 200</div>
            </div> */}

            <div className="Investment-Date">
              <div className="Wallet-Ref-Heading">Invstment Date</div>
              <div className="Wallet-Ref-Result">{showDate(item?.created_at)}</div>
            </div>

            <div className="Inv-Maturity-Status">
              <div className="Wallet-Ref-Heading">Status</div>
              <div className="Wallet-Ref-Result">{item?.referral_status || 'awaiting'}</div>
            </div>

            <div className="Members-Action">
              <div
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <MoreVert />
              </div>
              <ul
                className="dropdown-menu dropdown-menu-end"
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  <a
                    className="dropdown-item View__Details"
                    onClick={() => {setSelected(item); setTransactionViewShow(true)}}
                  >
                    View Details
                  </a>
                </li>
              </ul>
            </div>
          </div>
        ))
      }

      <Paginations 
        handleFetchTransSearch={handleMyUpdate} 
        page={page} 
        setPage={setPage} 
        pageCount={paginator?.pageCount || 0} 
        pageLimit={pageLimit}
        setPageLimit={setPageLimit}
      />


      <ViewRephrals
        selected={selected}
        show={transactionViewShow}
        onClose={handleTransactionView}
        onOpen={() => setTransactionViewShow(true)}
      />
    </>
  );
}

export default MyAllReferral;
