import React from "react";
import { Modal } from "react-bootstrap";
import { Close } from "@material-ui/icons";
import CurrencyFormat from "react-currency-format";
import moment from "moment";

function YourInvestmentDetails({ show, onClose, selected }) {
  const showDate = (passDate) => {
    return moment(passDate).format('DD-MM-YYYY')
  }

  const getCurrentROI = (selectedData, ROI, amount) => {
    const currentTime = new Date();
    const checkTime = new Date(selectedData?.created_at);
    const interest =  selectedData?.roi - selectedData?.amount;
    const fixedMonthlyROI =  interest / 12;
    const getDate = (date) => moment(date, 'DD/MM/YYYY').startOf('month')
    const diff =  Math.abs(getDate(checkTime).diff(getDate(currentTime), 'months'));

    return fixedMonthlyROI * diff;
  }

  return (
    <div>
      {" "}
      <div>
        <Modal
          size="lg"
          show={show}
          onHide={onClose}
          backdrop="static"
          aria-labelledby="example-modal-sizes-title-lg"
          keyboard={false}
          animation={true}
        >
          <Modal.Body>
            <div className="Clique24__ModalHeadings">
              <Close className="Clique24__Close" onClick={onClose} />
            </div>

            <div className="Clique24__ModalHeadings"></div>
            <div className="Settings__Modal__Container">
              <div className="Clique24__Headings">
                <div className="Log__Cont">
                  <img src="/assets/3.png" alt="" className="Log__Img__Modal" />
                </div>
                <div className="Clique24__Headings__Note">
                  <div className="Clique24__Settings__Header">
                    Your Investment
                  </div>
                  <div className="Clique24__Settings__Note">
                    View your investment details
                  </div>
                </div>
              </div>
              <br />
              <br />

              <div className="container">
                <div className="Inv-Display-Container">
                  <div className="Inv-Display-Container-Payment">
                    INVESTMENT TYPE
                  </div>
                  <div className="Inv-Display-Container-Details">
                    Investment Refrence Number :{" "}&nbsp;
                    <span className="Inv-Display-Container-Result">
                      {selected?.code}
                    </span>
                  </div>
                  <div className="Inv-Display-Container-Details">
                    Investment Type :{" "}&nbsp;
                    <span className="Inv-Display-Container-Result">Units</span>
                  </div>

                  <div className="Inv-Display-Container-Details">
                    Status :{" "}&nbsp;
                    <span className="Inv-Display-Container-Result">
                      {selected?.status}
                    </span>
                  </div>
                </div>
              </div>

              <br />

              <div className="container">
                <div className="Inv-Display-Container">
                  <div className="Inv-Display-Container-Payment">
                    {" "}
                    INVESTMENT DETAILS
                  </div>
                  <div className="Inv-Display-Container-Details">
                    Investment Amount :{" "}&nbsp;
                    <CurrencyFormat
                      value={selected?.amount || 0}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"₦ "}
                      renderText={(value) => (
                        <span className="Inv-Display-Container-Result">{value}</span>
                      )}
                    />
                  </div>
                  <div className="Inv-Display-Container-Details">
                    Total ROI/Month :{" "}&nbsp;
                    <CurrencyFormat
                      value={getCurrentROI(selected)}
                      displayType={"text"}
                      decimalScale={2}
                      thousandSeparator={true}
                      prefix={"₦ "}
                      renderText={(value) => (
                        <span className="Inv-Display-Container-Result">{value}</span>
                      )}
                    />
                  </div>
                  <div className="Inv-Display-Container-Details">
                    ROI :{" "}&nbsp;
                    <CurrencyFormat
                      value={selected?.roi || 0}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"₦ "}
                      renderText={(value) => (
                        <span className="Inv-Display-Container-Result">{value}</span>
                      )}
                    />
                  </div>
                  <div className="Inv-Display-Container-Details">
                    Units :{" "}&nbsp;
                    <span className="Inv-Display-Container-Result">{selected?.unit}</span>
                  </div>
                  <br />
                  <div className="Inv-Display-Container-Details">
                    Investment Date :{" "}&nbsp;
                    <span className="Inv-Display-Container-Result">
                      {showDate(selected?.created_at)}
                    </span>
                  </div>
                  <div className="Inv-Display-Container-Details">
                    Maturity Date :{" "}&nbsp;
                    <span className="Inv-Display-Container-Result">
                      {showDate(selected?.roi_date)}
                    </span>
                  </div>
                </div>
              </div>
              <br />
              {
                selected?.status === "paid" ? (
                  <div className="container">
                    <div className="Inv-Display-Container">
                      <div className="Inv-Display-Container-Payment">
                        {" "}
                        ACCOUNT DETAILS
                      </div>

                      <div className="Inv-Display-Container-Details">
                        Account Name :&nbsp;
                        <span className="Inv-Display-Container-Result">
                          {selected?.investor?.account_name}
                        </span>
                      </div>
                      <div className="Inv-Display-Container-Details">
                        Account Number : &nbsp;
                        <span className="Inv-Display-Container-Result">
                          {selected?.investor?.account_number}
                        </span>
                      </div>
                      <div className="Inv-Display-Container-Details">
                        Bank Name : &nbsp;
                        <span className="Inv-Display-Container-Result">
                          {selected?.investor?.bank_name}
                        </span>
                      </div>
                    </div>
                  </div>
                ) : null
              }
              <br />
              {
                selected?.status === "paid" ? (
                  <div className="container">
                    <div className="Inv-Display-Container">
                      <div className="Inv-Display-Container-Payment">
                        {" "}
                        ROI PAYMENT DETAILS
                      </div>

                      <div className="Inv-Display-Container-Details">
                        Payment Date :&nbsp;
                        <span className="Inv-Display-Container-Result">
                          20-20-2024
                        </span>
                      </div>
                      <div className="Inv-Display-Container-Details">
                        Paid Amount :&nbsp;
                        <span className="Inv-Display-Container-Result">
                          &#8358; 0:00
                        </span>
                      </div>
                      <div className="Inv-Display-Container-Details">
                        Payment Status :{" "}&nbsp;
                        <span className="Inv-Display-Container-Result">
                          Paid
                        </span>
                      </div>
                    </div>
                  </div>  
                ) : null
              }
              <br />
          

              <br />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default YourInvestmentDetails;
