import React from "react";

function Footer() {
  return (
    <div className="Footer-Const">
      <img src="/assets/247.png" alt="" className="Logo-Footer" />
      <div className="Footer__Note">
        &copy;{new Date().getFullYear()} CleeQ24 LTD. All Rights Reserved
      </div>
    </div>
  );
}

export default Footer;
