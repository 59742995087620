import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { payoutCount } from '../../../services/PayoutService'

const initialState = {
    loading: false,
    count: 0,
}

export const fetchPayoutPaidCount = createAsyncThunk('fetchPayoutPaidCount', (initData) => {
    return payoutCount(initData)
    .then((response) =>  response.data.data)
})


const payoutPaidCountSlice = createSlice({
    name: 'payoutPaidCount',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchPayoutPaidCount.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchPayoutPaidCount.fulfilled, (state, action ) => {
        state.loading = false
        state.count = action.payload
       })
    }
})

export default payoutPaidCountSlice.reducer 