import React, { useState } from "react";
import "../../Components/Responsive.css";
import "../../Components/Res.css";
import "../../Components/UserDashboard.css";
import "../../Components/SweetAlert.css";
import "../../Components/Modal.css";
import { Close } from "@material-ui/icons";

import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import EnterPin from "../InvestorComponent/EnterPin";
import { updateInvestor } from "../../services/InvestorService";
import { getUser, saveUserInLocalStorage } from "../../services/AuthService";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
// import "../../SweetAlert.css";

function AccountsDisplay({ show, onClose, onOpen }) {
  let user = getUser();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [loading, setLoading] = useState(false);

  const [editnameShow, setEditnameShow] = useState(false);
  const handleEditname = () => setEditnameShow(false);

  const [editlastShow, setEditlastShow] = useState(false);
  const handleEditlast = () => setEditlastShow(false);

  const [editemailShow, setEditemailShow] = useState(false);
  const handleEditemail = () => setEditemailShow(false);

  const [editphoneShow, setEditphoneShow] = useState(false);
  const handleEditphonenumber = () => setEditphoneShow(false);



  
  const handleEditphone = (data) => {
    // onClose();
    setEditphoneShow(false);

    Swal.fire({
      customClass: {
        confirmButton: "confirm-button-class",
        title: "title-class",
        icon: "icon-class",
        text: "text-class",
      },

      title: "Is this your Phone number?",
      text: `${data?.phone}`,
      icon: "warning",
      confirmButtonText: "OK!",
      // showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        handleUpdate(data)
        // Swal.fire({
        //   customClass: {
        //     confirmButton: "confirm-button-class",
        //     title: "title-class",
        //     icon: "icon-class",
        //     text: "text-class",
        //   },

        //   title: "Successful!",
        //   text: "Thank you for Investing with us",
        //   icon: "success",
        //   confirmButtonText: "Feels Good!",
        //   timer: 2000,
        // });
      }
    });
  };

  const handleUpdate = (data) => {
    setLoading(true);
    
    updateInvestor(user?._id, data)
      .then(async(response) => {
        setLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          await saveUserInLocalStorage(response.data.data)
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Update"
          );
          window.location.reload();
          setEditnameShow(false);
          setEditlastShow(false);
          setEditemailShow(false);
          setEditphoneShow(false);
          Swal.fire({
            customClass: {
              confirmButton: "confirm-button-class",
              title: "title-class",
              icon: "icon-class",
            },
            title: "Successful!",
            icon: "success",
            timer: 2000,
          });
        
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }

  return (
    <div className="p-3 InvDashContainerStat001">
      <div className="container-fluid p-0 overflow-hidden">
        <div className="col-12">
          <div className=" InvestorsContHeading ">
            <div className=" InvestorsContHeading ">Personal details</div>
            <div className=" BInstructions">
              Here are your personal and contact information
            </div>
          </div>
        </div>

        <div></div>
      </div>
      <br />
      <br />
      <div className="container-fluid p-0 overflow-hidden">
        <div>
          <div className="container-fluid  p-0">
            <div className="col-lg-12">
              <div className="Invest-Dashboard20">
                <div className="EquityNumberSubCont">
                  <div className="EquityNumber"> First Name</div>

                  <button
                    className="WithdrawFunds"
                    onClick={() => setEditnameShow(true)}
                  >
                    Edit
                  </button>
                </div>
                <div className="PersonalHeadings">{user?.first_name ? user?.first_name : "Update First Name"}</div>
              </div>
            </div>
            <br />
            <div className="col-lg-12">
              <div className="Invest-Dashboard20">
                <div className="EquityNumberSubCont">
                  <div className="EquityNumber"> Last Name</div>

                  <button
                    className="WithdrawFunds"
                    onClick={() => setEditlastShow(true)}
                  >
                    Edit
                  </button>
                </div>
                <div className="PersonalHeadings">{user?.last_name ? user?.last_name : "Update Last Name"}</div>
              </div>
            </div>
            {/* <br />
            <div className="col-lg-12">
              <div className="Invest-Dashboard20">
                <div className="EquityNumberSubCont">
                  <div className="EquityNumber"> Email</div>

                  <button
                    className="WithdrawFunds"
                    onClick={() => setEditemailShow(true)}
                  >
                    Edit
                  </button>
                </div>
                <div className="PersonalHeadings">{user?.email ? user?.email : "Update Email"}</div>
              </div>
            </div> */}
            <br />
            <div className="col-lg-12">
              <div className="Invest-Dashboard20">
                <div className="EquityNumberSubCont">
                  <div className="EquityNumber">Phone Number</div>

                  <button
                    className="WithdrawFunds"
                    onClick={() => setEditphoneShow(true)}
                  >
                    Edit
                  </button>
                </div>
                {
                  loading ? (
                    <div className="PersonalHeadings">Loading</div>
                  ) : (
                    <div className="PersonalHeadings">{user?.phone ? user?.phone : "Update Phone Number"}</div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*EDIT NAME STARTS HERE*/}
      <Modal
        size="lg"
        show={editnameShow}
        onHide={() => setEditnameShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        keyboard={false}
        animation={true}
      >
        <Modal.Body>
          <div className="Clique24__ModalHeadings">
            <div onClick={handleEditname}>
              <Close className="Clique24__Close" />
            </div>
          </div>
          <div className="Settings__Modal__Container">
            <div className="Inv-Heading">
              <div className="Log__Cont">
                <img src="/assets/3.png" alt="" className="Log__Img__Modal" />
              </div>
              <div className="Clique24__Headings__Note">
                <div className="Clique24__Settings__Header">
                  Personal Details
                </div>
                <div className="DisplayUsernames">
                  You can update your name here.
                </div>
              </div>
            </div>
            <br />
            <br />
            <div className="EditDetails">
              <div className="input-group">
                <FloatingLabel
                  controlId="floatingUsername"
                  label="Enter First Name"
                  className="Input__User34"
                  value={firstName}
                  onChange={(event) => {
                    setFirstName(event.target.value)
                  }}
                >
                  <Form.Control type="text" placeholder="Enter First Name" />
                </FloatingLabel>
              </div>
            </div>
            <br />
            {/* <div className="EditDetails">
              <EnterPin />
            </div> */}
{/* 
            <div className="mb-3">
              <EnterPin />
              <div
                className="valid-feedback AlertNot alert alert-danger "
                role="alert"
              >
                Incorrect PIN!
              </div>
            </div> */}
            <br />
            <div className="Payment__Heading__Cont">
              <div className="Profile__Cont__Wrapper mb-3">
              {
                  loading ? (
                    <Bars
                      heigth="20"
                      width="20"
                      color="gray"
                      ariaLabel="loading-indicator"
                    />
                  ) : (
                    <button disabled={!firstName} className="Profile__Cont__Saves" onClick={() => handleUpdate({first_name: firstName})}>
                      Save
                    </button>
                  ) 
                }
                
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/*EDIT NAME ENDS HERE*/}
      {/*EDIT LAST NAME STARTS HERE*/}
      <Modal
        size="lg"
        show={editlastShow}
        onHide={() => setEditlastShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        keyboard={false}
        animation={true}
      >
        <Modal.Body>
          <div className="Clique24__ModalHeadings">
            <div onClick={handleEditlast}>
              <Close className="Clique24__Close" />
            </div>
          </div>
          <div className="Settings__Modal__Container">
            <div className="Inv-Heading">
              <div className="Log__Cont">
                <img src="/assets/3.png" alt="" className="Log__Img__Modal" />
              </div>
              <div className="Clique24__Headings__Note">
                <div className="Clique24__Settings__Header">
                  Personal Details
                </div>
                <div className="DisplayUsernames">
                  You can Update your last name here
                </div>
              </div>
            </div>
            <br />
            <br />
            <div className="EditDetails">
              <div className="input-group">
                <FloatingLabel
                  controlId="floatingUsername"
                  label="Enter Last Name"
                  className="Input__User34"
                  value={lastName}
                  onChange={(event) => {
                    setLastName(event.target.value)
                  }}
                >
                  <Form.Control type="text" placeholder="PIN" />
                </FloatingLabel>
              </div>
            </div>
            <br />
            {/* <div className="EditDetails">
              <EnterPin />
            </div> */}

            <div className="mb-3">
              {/* <EnterPin /> */}
              <div
                className="valid-feedback AlertNot alert alert-danger "
                role="alert"
              >
                Incorrect PIN!
              </div>
            </div>
            <br />
            <div className="Payment__Heading__Cont">
              <div className="Profile__Cont__Wrapper mb-3">
              {
                loading ? (
                  <Bars
                    heigth="20"
                    width="20"
                    color="gray"
                    ariaLabel="loading-indicator"
                  />
                ) : (
                  <button disabled={!lastName} className="Profile__Cont__Saves" onClick={() => handleUpdate({last_name: lastName})}>
                    Save
                  </button>
                ) 
              }
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/*EDIT LAST NAME ENDS HERE*/}

      {/*EDIT EMAIL NAME STARTS HERE*/}
      <Modal
        size="lg"
        show={editemailShow}
        onHide={() => setEditemailShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        keyboard={false}
        animation={true}
      >
        <Modal.Body>
          <div className="Clique24__ModalHeadings">
            <div onClick={handleEditemail}>
              <Close className="Clique24__Close" />
            </div>
          </div>
          <div className="Settings__Modal__Container">
            <div className="Inv-Heading">
              <div className="Log__Cont">
                <img src="/assets/3.png" alt="" className="Log__Img__Modal" />
              </div>
              <div className="Clique24__Headings__Note">
                <div className="Clique24__Settings__Header">
                  Email Contact Details
                </div>
                <div className="DisplayUsernames">
                  Update your email.
                </div>
              </div>
            </div>
            <br />
            <br />
            <div className="EditDetails">
              <div className="input-group">
                <FloatingLabel
                  controlId="floatingUsername"
                  label="Enter Email"
                  className="Input__User34"
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value)
                  }}
                >
                  <Form.Control type="email" placeholder="PIN" />
                </FloatingLabel>
              </div>
            </div>
            <br />
            {/* <div className="EditDetails">
              <EnterPin />
            </div> */}

            <div className="mb-3">
              {/* <EnterPin /> */}
              <div
                className="valid-feedback AlertNot alert alert-danger "
                role="alert"
              >
                Incorrect PIN!
              </div>
            </div>
            <br />
            <div className="Payment__Heading__Cont">
              <div className="Profile__Cont__Wrapper mb-3">
              {
                loading ? (
                  <Bars
                    heigth="20"
                    width="20"
                    color="gray"
                    ariaLabel="loading-indicator"
                  />
                ) : (
                  <button disabled={!email} className="Profile__Cont__Saves" onClick={() => handleUpdate({email: email})}>
                    Save
                  </button>
                ) 
              }
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/*EDIT EMAIL ENDS HERE*/}

      {/*EDIT PHONE NUMBER STARTS HERE*/}
      <Modal
        size="lg"
        show={editphoneShow}
        onHide={() => setEditphoneShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        keyboard={false}
        animation={true}
      >
        <Modal.Body>
          <div className="Clique24__ModalHeadings">
            <div onClick={handleEditphonenumber}>
              <Close className="Clique24__Close" />
            </div>
          </div>
          <div className="Settings__Modal__Container">
            <div className="Inv-Heading">
              <div className="Log__Cont">
                <img src="/assets/3.png" alt="" className="Log__Img__Modal" />
              </div>
              <div className="Clique24__Headings__Note">
                <div className="Clique24__Settings__Header">
                Phone Contact Details
                </div>
                <div className="DisplayUsernames">
                  Confirm your phone number .
                </div>
              </div>
            </div>
            <br />
            <br />
            <div className="EditDetails">
              <div className="input-group">
                <FloatingLabel
                  controlId="floatingUsername"
                  label="Enter Your Phone number"
                  className="Input__User34"
                  value={phone}
                  onChange={(event) => {
                    setPhone(event.target.value)
                  }}
                >
                  <Form.Control type="number" placeholder="PIN" />
                </FloatingLabel>
              </div>
            </div>
            <br />
            {/* <div className="EditDetails">
              <EnterPin />
            </div> */}

            <div className="mb-3">
              {/* <EnterPin /> */}
              <div
                className="valid-feedback AlertNot alert alert-danger "
                role="alert"
              >
                Incorrect PIN!
              </div>
            </div>
            <br />
            <div className="Payment__Heading__Cont">
              <div className="Profile__Cont__Wrapper mb-3">
                <button
                  disabled={!phone}
                  className="Profile__Cont__Saves"
                  onClick={() => handleEditphone({phone: phone})}
                >
                  Verify phone number
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/*EDIT PHONE NUMBER ENDS HERE*/}
    </div>
  );
}

export default AccountsDisplay;
