import axios from '../axiosinstance';


export function getInvestor(initData){
        return axios.get('/investors/'+initData?.id)
}

export function updateInvestor(id, initData){
        return axios.patch('/investors/'+id, initData)
}

export function updateInvestorPwd(id, initData){
        return axios.patch('/investors/'+id+"/password", initData)
}

export function investorsCount(search){
        return axios.get('/investors/stat/count', { params: search })
}


