import React from "react";
import { Link } from "react-router-dom";


function SignUpAndLogin() {
  return (
    <div className="CleeQAuth">
    <div>
      <div className="SignUpAndLoginContianer">
        <Link to="/Login" className="Clique__Link">
          <button className="SignIn-LogIn-Btn">Login</button>
        </Link>
        <Link to="/SignUp" className="Clique__Link">
          <button className="SignIn-LogIn-Btn">Invest Now</button>{" "}
        </Link>
      </div>
    </div>
  </div>
  );
}

export default SignUpAndLogin;
