import React, { useState } from "react";
import { Close } from "@material-ui/icons";
import { Modal } from "react-bootstrap";
import "../../Components/New.css";
import "../../Components/NewResponsive.css";
import "../../Components/Res.css";
import "../../Components/Modal.css";
import "../../Components/UserDashboard.css";

import Footer from "../../Components/Footer";
import NavBar from "../../Components/NavBar";
import { Link } from "react-router-dom";
import { FiArrowDownCircle } from "react-icons/fi";
import {
  FcAdvertising,
  FcApproval,
  FcComboChart,
  FcDocument,
  FcPaid,
  FcWorkflow,
} from "react-icons/fc";
import ContactUs from "./ContactUs";
import JoinInvestors from "../../Components/JoinInvestors";

function Home() {
  const [investmentShow, setInvestmentShow] = useState(false);
  const handleInvestment = () => setInvestmentShow(false);

  const [investmentTypeShow, setInvestmentTypeShow] = useState(false);
  const handleInvestmentType = () => setInvestmentTypeShow(false);

  const [investmentPolicyShow, setInvestmentPolicyShow] = useState(false);
  const handleInvestmentPolicy = () => setInvestmentPolicyShow(false);

  const [investmentTermsShow, setInvestmentTermsShow] = useState(false);
  const handleInvestmentTerms = () => setInvestmentTermsShow(false);

  return (
    <div>
      <div className="paddy">
        <header className="container p-0 HeaderContainer">
          <NavBar />
        </header>
        <article>
          <div className="Home-Investment-Container">
            <div className="container p-0">
              <div className="row g-4">
                <div className="col-lg">
                  <div className="Investors-Home ">
                    <div>
                      <div className="Investors-Home-Heading">
                        Discover Opportunity,
                        <br />
                        Invest with Certainty <br />
                        and Maximize Returns
                      </div>

                      <div className="Investors-Verified">
                        <FcApproval className="Investors-Intro-Ico" />
                        <div className="Investors-Intro">
                          Invest in a verified opportunity.
                        </div>
                      </div>
                    </div>
                    <Link to="/SignUp" className="Clique__Link">
                      <button className="Invest-Btn-Btn">
                        Earn 25% ROI and more
                      </button>
                    </Link>
                  </div>
                  {/* <JoinInvestors/>  */}
                </div>
                <div className="col-lg">
                  <div className="">
                    <div className="container p-0">
                      <div className="row g-4">
                        <div className="col-lg-6">
                          <div className="CleeQ-Options">
                            <div className="CleeQ-Options-Icon-Cover">
                              <FcComboChart className="CleeQ-Options-Icon" />
                            </div>
                            <div className="CleeQ-Options-More-Cover">
                              <div className="CleeQ-Options-Heading">
                                About This Investment
                              </div>
                              <button
                                className="CleeQ-Options-Read-More"
                                onClick={() => setInvestmentShow(true)}
                              >
                                Read more
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="CleeQ-Options1">
                            <div className="CleeQ-Options-Icon-Cover1">
                              <FcPaid className="CleeQ-Options-Icon1" />
                            </div>
                            <div className="CleeQ-Options-More-Cover1">
                              <div className="CleeQ-Options-Heading1">
                                Investment Type
                              </div>
                              <button
                                className="CleeQ-Options-Read-More1"
                                onClick={() => setInvestmentTypeShow(true)}
                              >
                                Read more
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="CleeQ-Options1">
                            <div className="CleeQ-Options-Icon-Cover1">
                              <FcAdvertising className="CleeQ-Options-Icon1" />
                            </div>
                            <div className="CleeQ-Options-More-Cover1">
                              <div className="CleeQ-Options-Heading1">
                                Referral Policy
                              </div>
                              <button
                                className="CleeQ-Options-Read-More1"
                                onClick={() => setInvestmentPolicyShow(true)}
                              >
                                Read more
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="CleeQ-Options1">
                            <div className="CleeQ-Options-Icon-Cover1">
                              <FcDocument className="CleeQ-Options-Icon1" />
                            </div>
                            <div className="CleeQ-Options-More-Cover1">
                              <div className="CleeQ-Options-Heading1">
                                Terms and Conditions
                              </div>
                              <button
                                className="CleeQ-Options-Read-More1"
                                onClick={() => setInvestmentTermsShow(true)}
                              >
                                Read more
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br /> <br /> <br />
          <br /> <br /> <br />
          <div className="container p-0 Investors-Home-Body202">
            <div className="Investors-Heading60">Join Over +997 Investors</div>
          </div>
          <div>
            <br />
            <br />
            <br /> <br />
            <br />
            <br /> <br />
            <br />
            <br />
            <ContactUs />
          </div>
        </article>

        <footer className="maddy">
          <center>
            <Footer />
          </center>
        </footer>
      </div>
      {/*ABOUNT THIS INVESTMENT*/}
      <Modal
        size="lg"
        show={investmentShow}
        onHide={() => setInvestmentShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        keyboard={false}
        animation={true}
      >
        <Modal.Body>
          <div className="Clique24__ModalHeadings">
            <div onClick={handleInvestment}>
              <Close className="Clique24__Close" />
            </div>
          </div>
          <div className="Settings__Modal__Container">
            <div className="Inv-Heading">
              {/* <div className="Log__Cont">
                  <img src="/assets/lg.png" alt="" className="Log__Img__Modal" />
                </div> */}
              <div className="Clique24__Headings__Note">
                <div className="Clique24__Settings__Header">
                  About this investment
                </div>
                <br />
                <div className="InvestmentNote">
                  This meticulously vetted investment opportunity within the
                  dynamic landscape of digital banking promises attractive
                  returns for investors.
                  <br />
                  <br />
                  By participating, you secure a stake in a proven digital
                  asset, poised for growth.
                  <br />
                  <br />
                  We have structured this into 200,400 Units, each priced at
                  ₦20,000, for 25% return on investment (ROI). This investment
                  offers flexibility for accquiring Units according to your
                  preferences.
                  <br />
                  <br />
                  We have redifined banking services that is positioned to
                  maximum success for revenue streams generated by the robust
                  digital banking platform, including innovative POS services,
                  cutting-edge payment gateways, and a comprehensive suit of
                  local and international banking services.
                  <br />
                  <br />
                  Should you choose, you maintain the flexibility to liquidate
                  your investment at any juncture by selling your units,
                  ensuring your financial strategies remain agile and
                  responsive.
                </div>
              </div>
            </div>
            <br />
            <br />
          </div>
        </Modal.Body>
      </Modal>
      {/*ABOUNT THIS INVESTMENT*/}
      {/*INVESTMENT TYPE*/}
      <Modal
        size="lg"
        show={investmentTypeShow}
        onHide={() => setInvestmentTypeShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        keyboard={false}
        animation={true}
      >
        <Modal.Body>
          <div className="Clique24__ModalHeadings">
            <div onClick={handleInvestmentType}>
              <Close className="Clique24__Close" />
            </div>
          </div>
          <div className="Settings__Modal__Container">
            <div className="Inv-Heading">
              {/* <div className="Log__Cont">
                  <img src="/assets/lg.png" alt="" className="Log__Img__Modal" />
                </div> */}
              <div className="Clique24__Headings__Note">
                <div className="Clique24__Settings__Header">
                  Investment Type
                </div>
                <br />
                <div className="Type-Container">
                  <div className="container p-0">
                    <div className="row g-4">
                      <div className="col-lg-4">
                        <div className="Type-Details-Wrapper">
                          <div className="Type-Details-Wrapper-Headings">
                            Expected Returns
                          </div>
                          <div className="Type-Details-Wrapper-Sub">
                            25% in 12 months
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="Type-Details-Wrapper">
                          <div className="Type-Details-Wrapper-Headings">
                            Current Value/Unit
                          </div>
                          <div className="Type-Details-Wrapper-Sub">
                            ₦20,000 per Unit
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="Type-Details-Wrapper">
                          <div className="Type-Details-Wrapper-Headings">
                            Investment Type
                          </div>
                          <div className="Type-Details-Wrapper-Sub">
                            Fixed Income
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="Type-Details-Wrapper">
                          <div className="Type-Details-Wrapper-Headings">
                            Maturity Time
                          </div>
                          <div className="Type-Details-Wrapper-Sub">
                            12 months
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="Type-Details-Wrapper">
                          <div className="Type-Details-Wrapper-Headings">
                            Payout Type
                          </div>
                          <div className="Type-Details-Wrapper-Sub">
                            Capital + profit is paid at maturity date
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />
          </div>
        </Modal.Body>
      </Modal>
      {/*INVESTMENT TYPE*/}
      {/*INVESTMENT POLICY*/}
      <Modal
        size="lg"
        show={investmentPolicyShow}
        onHide={() => setInvestmentPolicyShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        keyboard={false}
        animation={true}
      >
        <Modal.Body>
          <div className="Clique24__ModalHeadings">
            <div onClick={handleInvestmentPolicy}>
              <Close className="Clique24__Close" />
            </div>
          </div>
          <div className="Settings__Modal__Container">
            <div className="Inv-Heading">
              {/* <div className="Log__Cont">
                  <img src="/assets/lg.png" alt="" className="Log__Img__Modal" />
                </div> */}
              <div className="Clique24__Headings__Note">
                <div className="Clique24__Settings__Header">
                  Investment Policy
                </div>

                <br />
                <div>
                  <div className="InvestmentNote">
                    At CleeQ24, we value our investors and believe in rewarding
                    them for their support and loyalty. Our referral policy
                    allows investors to earn additional rewards by referring
                    friends, family, or acquaintances to our investment
                    platform. Here's how it works:
                    <br />
                    <br />
                    <br />
                    <b>Referral Bonus:</b> For every new investor referred by an
                    existing investor, the referrer will receive a referral
                    bonus. The bonus amount will be credited to the accounts
                    upon successful completion of the investment.
                    <br />
                    <br /> <b>Bonus Structure: </b>The referral bonus amount
                    will be based on the total investment made by the referee.
                    The more significant the investment, the higher the referral
                    bonus.
                    <br />
                    <br /> <b>Tracking: </b>Referrals will be tracked through
                    unique referral links or referral codes assigned to each
                    investor. When a new investor signs up using the referral
                    link or code provided by an existing investor, they will be
                    linked automatically to the referrer.
                    <br />
                    <br /> <b>Eligibility: </b>To be eligible for the referral
                    bonus, both the referrer and the investor must meet the
                    investment criteria specified in our investment terms and
                    conditions. Additionally, the investor must complete the
                    investment process successfully within the specified time
                    frame.
                    <br />
                  </div>
                </div>
                <div className="InvestmentNote">
                  <br /> <b>Transparency: </b>All referral bonuses earned will
                  be clearly displayed in the investor's account dashboard.
                  Investors can track their referral earnings and monitor the
                  progress of their referrals in real-time.
                  <br />
                  <br />
                  <br /> <b>Unlimited Referrals:</b> There is no limit to the
                  number of referrals an investor can make. Investors are
                  encouraged to share their referral links or codes with as many
                  people as possible to maximize their earning potential.
                  <br />
                  <br /> <b>Promotional Campaigns: </b>Periodically, we may run
                  special promotional campaign, offering additional incentives
                  or rewards for referrals. Investors will be notified of these
                  campaigns via email or through our official communication
                  channels.
                  <br />
                  <br />
                  <b>
                    By participating in our referral program, investors not only
                    stand to earn additional rewards but also contribute to the
                    growth and success of our investment platform. Join us in
                    spreading the word about CleeQ24 and help us build a
                    stronger community of investors.
                  </b>
                </div>
              </div>
            </div>
            <br />
            <br />
          </div>
        </Modal.Body>
      </Modal>
      {/*INVESTMENT POLICY*/}
      {/*INVESTMENT TERMS*/}
      <Modal
        size="lg"
        show={investmentTermsShow}
        onHide={() => setInvestmentTermsShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        keyboard={false}
        animation={true}
      >
        <Modal.Body>
          <div className="Clique24__ModalHeadings">
            <div onClick={handleInvestmentTerms}>
              <Close className="Clique24__Close" />
            </div>
          </div>
          <div className="Settings__Modal__Container">
            <div className="Inv-Heading">
              {/* <div className="Log__Cont">
                  <img src="/assets/lg.png" alt="" className="Log__Img__Modal" />
                </div> */}
              <div className="Clique24__Headings__Note">
                <div className="Clique24__Settings__Header">
                  Terms and Conditions
                </div>
                <div>
                  <div className="InvestmentNote">
                    <br />
                    <br />
                    <b>Investment Type:</b> Fixed Investment
                    <br />
                    <br />
                    <b>Investment Period:</b> The investment period shall be 12
                    months from the date of initial investment.
                    <br />
                    <br />
                    <b>Unit Capital:</b> Each unit capital for investment is set
                    at 20,000 Nigerian Naira (NGN).
                    <br />
                    <br />
                    <b>Investment deposit:</b> All investment deposit must be
                    made to the official bank account of CleeQ24, as specified
                    by the company. The account details will be provided upon
                    login to the platform. Any payment made that does not
                    correspond to the number of units selected will be subject
                    to a penalty as specified in the investment agreement.
                    <br />
                    <br />
                    <b>Return on Investment (ROI):</b> Investors can expect to
                    receive a fixed return on their investment at the end of the
                    12-month maturity period. The exact ROI will be displayed on
                    your dashboard.
                    <br />
                    <br />
                    <b> Maturity Date: </b>The investment matures after a period
                    of 12 months from the date of investment. At maturity,
                    investors are entitled to the return on their investment
                    based on the agreed terms.
                    <br />
                    <br />
                    <b>ROI Payment:</b> Payment for investments is due at the
                    maturity date.
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />
          </div>
        </Modal.Body>
      </Modal>
      {/*INVESTMENT TERMS*/}
    </div>
  );
}

export default Home;
