import React from "react";
import "../InvestorComponent/Pagination.css";
import "../../Components/UserDashboard.css";
import "../../Components/Responsive.css";
import "../../Components/Clique24Logo/Clique24Logo.css";
import PayoutCount from "./PayoutCount";
import PayoutTable from "./PayoutTable";

function Payout() { 
  return (
    <>
      <PayoutCount />
      <PayoutTable />
    </>
  );
}

export default Payout;
