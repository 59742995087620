import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listPayouts } from '../../services/PayoutService'

const initialState = {
    loading: false,
    payouts: [],
    paginator: {},
    isSuccessful: false
}

export const fetchPayouts = createAsyncThunk('fetchPayouts', (initData) => {
    return listPayouts(initData)
    .then((response) => response.data.data)
})


const payoutsSlice = createSlice({
    name: 'payouts',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchPayouts.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchPayouts.fulfilled, (state, action ) => {
        state.loading = false
        state.payouts = action.payload.data
        state.paginator = action.payload.paginator
        state.isSuccessful = true
       })
       builder.addCase(fetchPayouts.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default payoutsSlice.reducer 