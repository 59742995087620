import React, { useState } from "react";
import "../../Components/Responsive.css";
import "../../Components/Res.css";
import "../../Components/UserDashboard.css";
import "../../Components/SweetAlert.css";
import "../../Components/Modal.css";

import "../InvestorComponent/Pagination.css";
import { MoreVert } from "@material-ui/icons";
import Paginations from "../InvestorComponent/Paginations";
import ViewPayoutTransactions from "./ViewPayoutTransactions";

function AllTransactions() {
  const [transactionViewShow, setTransactionViewShow] = useState(false);
  const handleTransactionView = () => setTransactionViewShow(false);
  return (
    <>
      <Paginations />
      <div className="Investment-Transaction-Info50">
        <div className="Investors-TransactionRef">
          <div className="Wallet-Ref-Heading">Ref ID</div>
          <div className="Wallet-Ref-Result">7873736565</div>
        </div>

        <div className="Invested-Amts">
          <div className="Wallet-Ref-Heading">Amount</div>
          <div className="Inv-RResult-Amount">
            &#8358;&nbsp;
            <span>0:00</span>
          </div>
        </div>

        {/* <div className="Inv-ROI">
          <div className="Inv-Heading">ROI&nbsp;&#8358;</div>
          <div className="Inv-RResult-Amount">
            &#8358;&nbsp; <ROI />
          </div>
        </div> */}

        <div className="Inv-Maturity-Date">
          <div className="Wallet-Ref-Heading">Date</div>
          <div className="Wallet-Ref-Result">12-23-3033</div>
        </div>

        <div className="Inv-Maturity-Status">
          <div className="Wallet-Ref-Heading">Status</div>
          <div className="Wallet-Ref-Result">Processed</div>
        </div>

        <div className="Members-Action">
          <div
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <MoreVert />
          </div>
          <ul
            className="dropdown-menu dropdown-menu-end"
            aria-labelledby="dropdownMenuButton1"
          >
            <li>
              <a
                className="dropdown-item View__Details"
                onClick={() => setTransactionViewShow(true)}
              >
                View Details
              </a>
            </li>
          </ul>
        </div>
      </div>

      <Paginations />

      <ViewPayoutTransactions
        show={transactionViewShow}
        onClose={handleTransactionView}
        onOpen={() => setTransactionViewShow(true)}
      />
    </>
  );
}

export default AllTransactions;
