import React from "react";
import "../InvestorComponent/Pagination.css";
import AccountDetails from "./AccountDetails";
import "../../Components/UserDashboard.css";
import "../../Components/Responsive.css";
import "../../Components/Clique24Logo/Clique24Logo.css";

function Accounts() {
  return (
    <>
      <AccountDetails />
    </>
  );
}

export default Accounts;
