import React from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { Modal } from "react-bootstrap";
import { Close } from "@material-ui/icons";
import "../../Components/Responsive.css";
import "../../Components/Res.css";
import "../../Components/UserDashboard.css";
import "../../Components/SweetAlert.css";
import "../../Components/Modal.css";

function ViewPayoutTransactions({ show, onClose, onOpen }) {
  return (
    <div>
      {" "}
      <div>
        <Modal
          size="lg"
          show={show}
          onHide={onClose}
          backdrop="static"
          aria-labelledby="example-modal-sizes-title-lg"
          keyboard={false}
          animation={true}
        >
          <Modal.Body>
            <div className="Clique24__ModalHeadings">
              <Close className="Clique24__Close" onClick={onClose} />
            </div>

            <div className="Clique24__ModalHeadings"></div>
            <div className="Settings__Modal__Container">
              <div className="Clique24__Headings">
                <div className="Log__Cont">
                  <img src="/assets/3.png" alt="" className="Log__Img__Modal" />
                </div>
                <div className="Clique24__Headings__Note">
                  <div className="Clique24__Settings__Header">
                    Payout transaction details
                  </div>
                </div>
              </div>
              <br />
              <br />
              <div className="container">
                <div className="Inv-Display-Container">
                  <div className="Inv-Display-Container-Payment">
                    PAYOUT REFERENCE NUMBER
                  </div>
                  <div className="Inv-Display-Container-Details">
                    Investment Refrence Number : &nbsp;
                    <span className="Inv-Display-Container-Result">
                      reference display
                    </span>
                  </div>
                  <div className="Inv-Display-Container-Details">
                    Payout Refrence Number : &nbsp;
                    <span className="Inv-Display-Container-Result">
                      reference display
                    </span>
                  </div>
                </div>
              </div>

              <br />
              <div className="container">
                <div className="Inv-Display-Container">
                  <div className="Inv-Display-Container-Payment">
                    {" "}
                    ACCOUNT DETAILS
                  </div>

                  <div className="Inv-Display-Container-Details">
                    Account Name :&nbsp;
                    <span className="Inv-Display-Container-Result">
                      Name of account display
                    </span>
                  </div>
                  <div className="Inv-Display-Container-Details">
                    Account Number :&nbsp;
                    <span className="Inv-Display-Container-Result">
                      Account Number display
                    </span>
                  </div>
                  <div className="Inv-Display-Container-Details">
                    Bank Name :&nbsp;
                    <span className="Inv-Display-Container-Result">
                      Name of bank
                    </span>
                  </div>
                </div>
              </div>
              <br />
              <div className="container">
                <div className="Inv-Display-Container">
                  <div className="Inv-Display-Container-Payment">
                    PAYMENT DETAILS
                  </div>
                  <div className="Inv-Display-Container-Details">
                    Paid Amount :&nbsp;
                    <span className="Inv-Display-Container-Result">
                      &#8358; 0:00
                    </span>
                  </div>

                  <div className="Inv-Display-Container-Details">
                    Payment Date :&nbsp;
                    <span className="Inv-Display-Container-Result">
                      20-20-2024
                    </span>
                  </div>

                  <div className="Inv-Display-Container-Details">
                    Payment Status : &nbsp;
                    <span className="Inv-Display-Container-Result">Paid</span>
                  </div>
                </div>
              </div>
              <br />

              <br />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default ViewPayoutTransactions;
