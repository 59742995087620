import axios from '../axiosinstance';

      
export function listInvests(initData){
        return axios.get('/invests', { params: initData })
}

export function addInvest(data){
        return axios.post('/invests', data)
}

export function getInvest(initData){
        return axios.get('/invests/'+initData?.id)
}

export function updateInvest(initData){
        return axios.patch('/invests/'+initData.id, initData)
}

export function investCount(search){
        return axios.get('/invests/stat/count', { params: search })
}

export function investSum(search){
        return axios.get('/invests/stat/sum', { params: search })
}

export function investSumROI(search){
        return axios.get('/invests/stat/sum/roi', { params: search })
}

export function investSumUnits(search){
        return axios.get('/invests/stat/sum/units', { params: search })
}

