import React from "react";
import "../InvestorComponent/Pagination.css";
import TransactionCount from "./TransactionCount";
import InvestorTransaction from "./InvestorTransaction";
import { Menu } from "@material-ui/icons";
import "../../Components/UserDashboard.css";
import "../../Components/Responsive.css";
import "../../Components/Clique24Logo/Clique24Logo.css";

function Transactions() {
  return (
    <>
      <TransactionCount />
      <InvestorTransaction />
    </>
  );
}

export default Transactions;
