import axios from 'axios'
import { toast } from 'react-toastify';
import { clearAuth, getToken, getUser } from './services/AuthService';

const baseURL = 'https://cleeq24-api-8isby.ondigitalocean.app/api/v1/investor/'
//const baseURL = 'http://localhost:5000/api/v1/investor/';
//const LOCAL_STORAGE_API_KEY = 'token'
const user = getUser();

const instance = axios.create({
        baseURL: baseURL,
        headers: {
                Authorization: `Bearer ${getToken()}`,
                investorid: user?._id
        },
})

instance.interceptors.request.use(request => {
        const token = localStorage.getItem("token");
        const user = JSON.parse(localStorage.getItem("user"));
        if (token) {
                request.headers.Authorization = `Bearer ${token}`;
        }
        if (user) {
                request.headers.investorid = `${user?._id}`;
        }
        console.log("REQUEST", request)
        return request;
}, error => {
        console.log("REQUEST ERROR", error)
})
      
instance.interceptors.response.use(response => {
        console.log("RESPONSE", response)
        return response;

}, 
error => {
        console.log("RESPONSE ERROR", error.response)
        if (error.response.status === 401) {
                clearAuth()
                toast.error(error.response.message ? error.response.message : "You are logged out");
                window.location.href = '/login';
        }
        if (error.response.status === 408) {
                //window.location.href = '/CliqueManagement';
        }
        if(!error.response?.data?.success){
                toast.error(error.response?.data?.message);
        }
})

// instance.defaults.headers.common

export default instance;