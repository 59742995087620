import axios from '../axiosinstance';

export function signUp(phone, password){
        const postData = {
                phone,
                password,
        }

        return axios.post('/signup', postData)
}

export function signUp2(data){
        return axios.post('/signup2fa', data)
}

export function login(data){
        return axios.post('/login', data)
}

export function saveTokenInLocalStorage(token){
         localStorage.setItem('token', token);
}

export function saveUserInLocalStorage(user){
         localStorage.setItem('user', JSON.stringify(user));
}

export function saveSysInLocalStorage(sys){
        localStorage.setItem('sys', JSON.stringify(sys));
}

export function getToken(){
        return localStorage.getItem('token')
}

export function getUser(){
        const user = localStorage.getItem('user');
        return JSON.parse(user)
}

export function getSys(){
        const sys = localStorage.getItem('sys');
        return JSON.parse(sys)
}

export function clearAuth (){
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('sys');
}