import React from "react";
import { Modal } from "react-bootstrap";
import { Close } from "@material-ui/icons";
import "../../Components/Responsive.css";
import "../../Components/Res.css";
import "../../Components/UserDashboard.css";
import "../../Components/SweetAlert.css";
import "../../Components/Modal.css";
import moment from "moment";
import CurrencyFormat from "react-currency-format";

function ViewPayout({ show, onClose, onOpen, selected }) {
  const showDate = (passDate) => {
    return moment(passDate).format('DD-MM-YYYY')
  }
  return (
    <div>
      {" "}
      <div>
        <Modal
          size="lg"
          show={show}
          onHide={onClose}
          backdrop="static"
          aria-labelledby="example-modal-sizes-title-lg"
          keyboard={false}
          animation={true}
        >
          <Modal.Body>
            <div className="Clique24__ModalHeadings">
              <Close className="Clique24__Close" onClick={onClose} />
            </div>

            <div className="Clique24__ModalHeadings"></div>
            <div className="Settings__Modal__Container">
              <div className="Clique24__Headings">
                <div className="Log__Cont">
                  <img src="/assets/3.png" alt="" className="Log__Img__Modal" />
                </div>
                <div className="Clique24__Headings__Note">
                  <div className="Clique24__Settings__Header">
                    All payout transaction details
                  </div>
                </div>
              </div>
              <br />
              <br />
              <div className="container">
                <div className="Inv-Display-Container">
                  <div className="Inv-Display-Container-Payment">
                    PAYOUT REFERENCE NUMBER
                  </div>
                  <div className="Inv-Display-Container-Details">
                    Investmemt Refrence Number : &nbsp;
                    <span className="Inv-Display-Container-Result">
                      {selected?.invest?.code}
                    </span>
                  </div>
                  <div className="Inv-Display-Container-Details">
                    Payout Refrence Number : &nbsp;
                    <span className="Inv-Display-Container-Result">
                      {selected?.code}
                    </span>
                  </div>
                </div>
              </div>

              <br />
              <div className="container">
                <div className="Inv-Display-Container">
                  <div className="Inv-Display-Container-Payment">
                    {" "}
                    ACCOUNT DETAILS
                  </div>

                  <div className="Inv-Display-Container-Details">
                    Account Name :&nbsp;
                    <span className="Inv-Display-Container-Result">
                      {selected?.account_name}
                    </span>
                  </div>
                  <div className="Inv-Display-Container-Details">
                    Account Number :&nbsp;
                    <span className="Inv-Display-Container-Result">
                      {selected?.account_number}
                    </span>
                  </div>
                  <div className="Inv-Display-Container-Details">
                    Bank Name :&nbsp;
                    <span className="Inv-Display-Container-Result">
                      {selected?.bank_name}
                    </span>
                  </div>
                </div>
              </div>
              <br />
              {
                selected?.status === "paid" ? (
                  <div className="container">
                    <div className="Inv-Display-Container">
                      <div className="Inv-Display-Container-Payment">
                        PAYMENT DETAILS
                      </div>
                      <div className="Inv-Display-Container-Details">
                        Paid Amount :&nbsp;
                        <CurrencyFormat
                          value={selected?.amount || 0}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"₦ "}
                          renderText={(value) => (
                            <span className="Inv-Display-Container-Result">{value}</span>
                          )}
                        />
            
                      </div>

                      <div className="Inv-Display-Container-Details">
                        Payment Date :&nbsp;
                        <span className="Inv-Display-Container-Result">
                          {showDate(selected?.payment_date)}
                        </span>
                      </div>

                      <div className="Inv-Display-Container-Details">
                        Payment Status : &nbsp;
                        <span className="Inv-Display-Container-Result">{selected?.status}</span>
                      </div>
                    </div>
                  </div>
                ) : null
              }
              <br />

              <br />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default ViewPayout;
