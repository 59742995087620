import "../../Components/Responsive.css";
import "../../Components/Res.css";
import "../../Components/UserDashboard.css";
import "../../Components/SweetAlert.css";
import "../../Components/Modal.css";
import {
  FcLineChart,
  FcMultipleInputs,
  FcNegativeDynamic,
  FcPaid,
  FcPositiveDynamic,
} from "react-icons/fc";
import React, { useEffect, useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { Modal } from "react-bootstrap";
import { Close } from "@material-ui/icons";
import EnterPin from "../InvestorComponent/EnterPin";
import RemainingEquity from "../InvestorComponent/RemainingEquity";
import Swal from "sweetalert2";
import { MdOutlineFileCopy } from "react-icons/md";
import WithdrawFunds from "../InvestorComponent/WithdrawFunds";
import WithdrawBonus from "./WithdrawBonus";
import { fetchReferralCount } from "../../features/referrals/counts/referralCountSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchReferralSum } from "../../features/referrals/sums/referralSumSlice";
import CurrencyFormat from "react-currency-format";
import { fetchReferralPayoutSum } from "../../features/referrals/sums/referralPayoutSumSlice";
import { fetchReferralPayoutCount } from "../../features/referrals/counts/referralPayoutCountSlice";
import { getUser } from "../../services/AuthService";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Tooltip } from 'react-tooltip'

function MyReferralCount() {
  const dispatch = useDispatch()
  const user = getUser();
  const referralCount = useSelector((state) => state.referralCount)
  const referralPayoutCount = useSelector((state) => state.referralPayoutCount)
  const referralSum = useSelector((state) => state.referralSum)
  const referralPayoutSum = useSelector((state) => state.referralPayoutSum)
  const [isOpen, setIsOpen] = useState(false)
  const [isOpen2, setIsOpen2] = useState(false)

  const [investinusShow, setInvestinusShow] = useState(false);
  const handleInvestinus = () => setInvestinusShow(false);

  const [bonuswithdrawfundsShow, setBonuswithdrawfundsShow] = useState(false);
  const handleBonuswithdrawfunds = () => setBonuswithdrawfundsShow(false);

  useEffect(() => {
    dispatch(fetchReferralCount())
    dispatch(fetchReferralSum({status: 'ready'}))
    dispatch(fetchReferralPayoutSum({status: 'paid'}))
    dispatch(fetchReferralPayoutCount({status: 'paid'}))
  }, [])

  useEffect(() => {
    if(isOpen){
      setTimeout(() => {
        setIsOpen(false)
      }, 2000);
    }
    if(isOpen2){
      setTimeout(() => {
        setIsOpen2(false)
      }, 2000);
    }
  }, [isOpen, isOpen2])

  const handleInvest = () => {
    setInvestinusShow(false);

    Swal.fire({
      customClass: {
        confirmButton: "confirm-button-class",
        title: "title-class",
        icon: "icon-class",
        text: "text-class",
      },

      title: "Thanks for making the right choice",
      text: "Your investment will be processed shortly",
      icon: "warning",
      confirmButtonText: "Yes!",
      //   showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          customClass: {
            confirmButton: "confirm-button-class",
            title: "title-class",
            icon: "icon-class",
            text: "text-class",
          },

          title: "Your Investment is Successful!",
          text: "Thank you for Investing with us",
          icon: "success",
          confirmButtonText: "Feels Good!",
          //       timer: 2000,
        });
      }
    });
  };
  return (
    <div>
      <div className="container overflow-hidden p-0">
        <div className="Investors-Dashboard-Body">
          {/* <div className="CleeQBody2">eee</div> */}
          <div className="Investors-Dashboard-Heading-Container mt-5">
            <div className="Investors-Dashboard-Heading20">My Referrals</div>
            {
              user?.is_invest ? (
                <div className="Investors-Referals-Container">
                  <div className="Investors-Referals-Container-Intro">
                    Click on the icon to copy the Referral code or link and share
                  </div>
                  <div className="Investors-Referals" data-tooltip-id="my-tooltips">
                    <div className="Investors-Referals-Code">{user?.cus_code}</div>
                    <CopyToClipboard text={`${user?.cus_code}`}
                      onCopy={() => setIsOpen2(true)}>
                      <MdOutlineFileCopy className="Investors-Referals-Ico" />
                    </CopyToClipboard>
                  </div>
                  <Tooltip
                    id="my-tooltips"
                    content="Copied!"
                    isOpen={isOpen2}
                  />
                  <div className="Investors-Referals" data-tooltip-id="my-tooltip">
                    <div className="Investors-Referals-Code">Link</div>
                    <CopyToClipboard text={`https://investment.cleeq24.com/SignUp?code=${user?.cus_code}`}
                      onCopy={() => setIsOpen(true)}>
                      <MdOutlineFileCopy className="Investors-Referals-Ico" />
                    </CopyToClipboard>
                  </div>
                  <Tooltip
                    id="my-tooltip"
                    content="Copied!"
                    isOpen={isOpen}
                  />
                </div>
              ) : null
            }
          </div>
          <br />
          <div className="container overflow-hidden p-0 Investors-Dashboard-Overview">
            <div className="row g-4">
              <div className="col-xl">
                <div className="Investors-Invested-Amount Investment-Mobile">
                  <div className="Investors-Invested-Heading">
                    <div className="Investors-Amount-Heading">Referrals</div>

                    <div className="IconCont1">
                      <FcMultipleInputs className="AccIcon" />
                    </div>
                  </div>
                  <div className="Investors-Amount20">
                    <span>{referralCount?.loading ? "Loading..." : referralCount?.count }</span>
                    {/* <span>&#8358;&nbsp;0</span> */}
                  </div>
                </div>
              </div>
              <div className="col-xl">
                <div className="Investors-Invested-Amount Investment-Mobile">
                  <div className="Investors-Invested-Heading">
                    <div className="Investors-Amount-Heading">Total Bonus</div>

                    <div className="IconCont1">
                      <FcPositiveDynamic className="AccIcon" />
                    </div>
                  </div>
                  <div className="Investors-Amount20">
                    {/* <span>0</span> */}
                    {
                      referralSum?.loading ? (
                        <span>Loading...</span>
                      ) : (
                        <CurrencyFormat
                          value={referralSum?.total || 0}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"₦ "}
                          renderText={(value) => (
                            <span>{value}</span>
                          )}
                        />
                      )
                    }
                    {/* <span>&#8358;&nbsp;0</span> */}
                    {
                      referralSum?.total > 0 ? (
                        <button
                          className="WithdrawFunds"
                          onClick={() => setBonuswithdrawfundsShow(true)}
                        >
                          Withdraw
                        </button>
                      ) : null
                    }
                  </div>
                </div>
              </div>
              <div className="col-xl">
                <div className="Investors-Invested-Amount Investment-Mobile">
                  <div className="Investors-Invested-Heading">
                    <div className="Investors-Amount-Heading">
                      Referral Payouts
                    </div>

                    <div className="IconCont1">
                      <FcPaid className="AccIcon" />
                    </div>
                  </div>
                  <div className="Investors-Amount20">
                    <span>{referralPayoutCount?.loading ? "Loading..." : referralPayoutCount?.count }</span>
                    {
                      referralSum?.loading ? (
                        <span>Loading...</span>
                      ) : (
                        <CurrencyFormat
                          value={referralPayoutSum?.total || 0}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"₦ "}
                          renderText={(value) => (
                            <span>{value}</span>
                          )}
                        />
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <WithdrawBonus
          amount={referralSum?.total}
          show={bonuswithdrawfundsShow}
          onClose={handleBonuswithdrawfunds}
          onOpen={() => setBonuswithdrawfundsShow(true)}
        />
      </div>

      <Modal
        size="lg"
        show={investinusShow}
        onHide={() => setInvestinusShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        keyboard={false}
        animation={true}
      >
        <Modal.Body>
          <div className="Clique24__ModalHeadings">
            <div onClick={handleInvestinus}>
              <Close className="Clique24__Close" />
            </div>
          </div>
          <div className="Settings__Modal__Container">
            <div className="Inv-Heading">
              <div className="Log__Cont">
                <img src="/assets/3.png" alt="" className="Log__Img__Modal" />
              </div>
              <div className="Clique24__Headings__Note">
                <div className="Clique24__Settings__Header">Invest with us</div>
                <div className="DisplayUsernames">
                  Enter the equity percentage accordignly
                </div>
              </div>
            </div>
            <br /> <br /> <br />
            <div>
              <div className="Invest-Dashboard">
                <div className="Invest-DashboardContainer">
                  <div className="INVRemaining">
                    {/* Net Profit&nbsp;&#8358; */}
                    Total Equity Remaining
                  </div>
                  <div className="IconCont1">
                    <FcLineChart className="AccIcon" />
                  </div>
                </div>
                <div className="InvAmtHeadingWrapper">
                  <div className="InvAmtHeading">
                    <RemainingEquity />
                  </div>
                  {/* <Settings /> */}
                </div>
              </div>
            </div>
            <br /> <br />
            <div className="InCont">
              <div className="InContWrapper">
                <div className="mb-3">
                  <FloatingLabel
                    controlId="floatingUsername"
                    label="First Name"
                    className="Input__User34"
                  >
                    <Form.Control type="text" placeholder="Last Name" />
                  </FloatingLabel>
                </div>
              </div>
              <div className="InContWrapper">
                <div className="mb-3">
                  <FloatingLabel
                    controlId="floatingUsername"
                    label="LastName"
                    className="Input__User34"
                  >
                    <Form.Control type="text" placeholder="Last Name" />
                  </FloatingLabel>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <FloatingLabel
                controlId="floatingUsername"
                label="Equity %"
                className="Input__User34"
              >
                <Form.Control type="number" placeholder="First Name" />
              </FloatingLabel>
            </div>
            <div className="InCont">
              <div className="InContWrapper">
                <div className="mb-3">
                  <FloatingLabel
                    controlId="floatingUsername"
                    label="Phone number"
                    className="Input__User34"
                  >
                    <Form.Control type="number" placeholder="Last Name" />
                  </FloatingLabel>
                </div>
              </div>
              <div className="InContWrapper">
                <div className="mb-3">
                  <FloatingLabel
                    controlId="floatingUsername"
                    label="Password"
                    className="Input__User34"
                  >
                    <Form.Control type="password" placeholder="Last Name" />
                  </FloatingLabel>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <EnterPin />
              <div
                className="valid-feedback AlertNot alert alert-danger "
                role="alert"
              >
                Incorrect PIN!
              </div>
            </div>
            <br />
            <div className="Payment__Heading__Cont">
              <div className="Profile__Cont__Wrapper mb-3">
                <button className="Profile__Cont__Saves" onClick={handleInvest}>
                  Invest
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default MyReferralCount;
