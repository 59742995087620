import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { referralCount } from '../../../services/ReferralService'

const initialState = {
    loading: false,
    count: 0,
}

export const fetchReferralCount = createAsyncThunk('fetchReferralCount', (initData) => {
    return referralCount(initData)
    .then((response) =>  response.data.data)
})


const referralCountSlice = createSlice({
    name: 'referralCount',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchReferralCount.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchReferralCount.fulfilled, (state, action ) => {
        state.loading = false
        state.count = action.payload
       })
    }
})

export default referralCountSlice.reducer 