import React, { useEffect } from "react";
import "../../Components/Responsive.css";
import "../../Components/Res.css";
import "../../Components/UserDashboard.css";
import "../../Components/SweetAlert.css";
import "../../Components/Modal.css";
import PayoutInfo from "./PayoutInfo";
import { useDispatch, useSelector } from "react-redux";
import { fetchPayouts } from "../../features/payouts/payoutsSlice";

function PayoutTable() {
  const dispatch = useDispatch()
  const {payouts} = useSelector((state) => state.payouts)

  useEffect(() => {
    dispatch(fetchPayouts({}))
  }, [])
  return (
    <>
      <div className="container overflow-hidden p-0">
        <div className="Investors-Dashboard-Body">
          <div className="Investors-Dashboard-Heading-Container">
            <div className="Investors-Dashboard-Heading">Payout Details</div>
          </div>
          {
            payouts?.length < 1  ? (
              <div className="container overflow-hidden Investors-Transactions">
                <div className="">There is no payout yet.</div>
              </div>
            ) : null
          }
          {
            payouts?.length > 0  ? (
              <div className="container overflow-hidden Investors-Transactions300">
                <PayoutInfo />
              </div>
            ) : null
          }
          <center className="Spreader"></center>
        </div>
      </div>
    </>
  );
}

export default PayoutTable;
