import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../Home/Home.css";
import FooterHome from "../../Components/Footer/FooterHome";
import { useAuth } from "../../auth";
import {
  saveTokenInLocalStorage,
  saveUserInLocalStorage,
  signUp2,
} from "../../services/AuthService";
import { Bars } from "react-loader-spinner";

function VerifyOTP() {
  const auth = useAuth();
  const location = useLocation();
  let navigate = useNavigate();

  const [otp, setOtp] = useState("");
  const [errOtp, setErrOtp] = useState("");
  const [loading, setLoading] = useState("");
  const [countDown, setCountDown] = useState(0);
  const [runTimer, setRunTimer] = useState(true);

  useEffect(() => {
    let timerId;

    if (runTimer) {
      setCountDown(60 * 3);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1500);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  
  }, [runTimer])

  useEffect(() => {
    if (countDown < 0 && runTimer) {
      console.log("expired");
      setRunTimer(false);
      setCountDown(0);
    }
  }, [countDown, runTimer])

  const seconds = String(countDown % 60).padStart(2, 0);
  const minutes = String(Math.floor(countDown / 60)).padStart(2, 0);

  const handleSubmit = () => {
    if (!otp) {
      setErrOtp("OTP is required");
      return;
    }
    setLoading(true);
    const sendData = {
      phone: location.state?.user.phone,
      first_name: location.state?.user.first_name,
      last_name: location.state?.user.last_name,
      password: location.state?.user?.password,
      referral_code: location.state?.user.referral_code,
      know_about_us: location.state?.user.know_about_us,
      token_id: location.state?.token,
      code: otp,
    };
    //dispatch(signupAction(sendData, navigate))
    signUp2(sendData)
      .then(async (response) => {
        //console.log(response)
        if (response.data.success) {
          await saveUserInLocalStorage(response.data.user);
          await saveTokenInLocalStorage(response.data.token);
          await auth.signup(response.data.user);
          setLoading(false);
          navigate("/successful", { state: { user: location.state?.user } });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <div className="LoginBody">
      <header></header>
      <div className="DashBoardContainer">
        <div className="column22 ">
          <div className="logoSignup__Cont">
            <div className="logo__Cont">
              <img src="/assets/3.png" alt="" className="ImgLogo" />
            </div>
            <div className="logo__SignUp__Note">
              <div className="SignUp__Header">Verify OTP.</div>

              <div className="SignUp__Header__Note">
                A One-Time-Password has been sent to this(Phone Number).
              </div>
            </div>
          </div>

          <br />

          <div className="Input__C">
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="floatingInput"
                placeholder="Phone Number"
                values={otp}
                onChange={(event) => {
                  if (event.target.value.length) {
                    setErrOtp("");
                  } else {
                    setErrOtp("OTP is required");
                  }
                  setOtp(event.target.value);
                }}
              />
              <label className="frol" for="floatingInput">
                Enter OTP
              </label>
              {errOtp && <div className="input_error">{errOtp}</div>}
            </div>
          </div>
          <br />
          <div className="Otp__Btn">
            <Link to="/" className="Clique__Link">
              <button disabled={loading} className="Log__Btn Log__Back">
                Back
              </button>
            </Link>
            {loading ? (
              <Bars
                heigth="50"
                width="50"
                color="gray"
                ariaLabel="loading-indicator"
              />
            ) : (
              <div className="Clique__Link">
                <button className="Log__Btn" onClick={handleSubmit}>
                  Verify OTP
                </button>
              </div>
            )}
          </div>
          <br />

          <div className="Clique__Qes">
              {runTimer ? (
                <div>
                  <span>Time Counter : {minutes}:{seconds} </span> Otp not received?
                </div>
              ) : (
                <div className="Clique__Link">
                  <span>Resend OTP</span>
                </div>
              )}
          </div>
          <br />
          <div className="Clique__Qes">
            Go back to{" "}
            <Link to="/" className="Clique__Link">
              <span>Home</span>
            </Link>
          </div>
        </div>

        <footer>
          <FooterHome />
        </footer>
      </div>
    </div>
  );
}

export default VerifyOTP;
