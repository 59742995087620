import { Navigate } from "react-router-dom";
import { useAuth } from "./auth";

export const RedirectAuth = ({children}) => {
        const auth = useAuth()
        
        if(auth.user){
          return <Navigate to='/dashboard' />
        }
  return children
}
