import React from "react";
import "../../Components/Responsive.css";
import "../../Components/Res.css";
import "../../Components/UserDashboard.css";
import "../../Components/SweetAlert.css";
import "../../Components/Modal.css";

function InvestedUnits({loading, units}) {
  return <div>{loading ? "Loading" : units || 0}</div>;
}

export default InvestedUnits;
