import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { investCount } from '../../../services/InvestService'

const initialState = {
    loading: false,
    count: 0,
}

export const fetchInvestsMaturedCount = createAsyncThunk('fetchInvestsMaturedCount', (initData) => {
    return investCount(initData)
    .then((response) =>  response.data.data)
})


const investsMaturedCountSlice = createSlice({
    name: 'investsMaturedCount',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchInvestsMaturedCount.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchInvestsMaturedCount.fulfilled, (state, action ) => {
        state.loading = false
        state.count = action.payload
       })
    }
})

export default investsMaturedCountSlice.reducer 