import React, { useState } from "react";
import "../../Components/Responsive.css";
import "../../Components/Res.css";
import "../../Components/UserDashboard.css";
import "../../Components/SweetAlert.css";
import "../../Components/Modal.css";
import { Close } from "@material-ui/icons";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import { getUser, saveUserInLocalStorage } from "../../services/AuthService";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import { updateInvestor } from "../../services/InvestorService";

function ManageInvestorBank() {
  let user = getUser();
  const [bankName, setBankName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountName, setAccountName] = useState("");
  const [loading, setLoading] = useState(false);

  const [managebankShow, setManagebankShow] = useState(false);
  const handleManagebank = () => setManagebankShow(false);

  const handleUpdate = () => {
    setLoading(true);
    const data = {
      bank_name: bankName,
      account_number: accountNumber,
      account_name: accountName
    }
    updateInvestor(user?._id, data)
      .then(async(response) => {
        setLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          await saveUserInLocalStorage(response.data.data)
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Update"
          );
          //window.location.reload();
          setManagebankShow(false);
          Swal.fire({
            customClass: {
              confirmButton: "confirm-button-class",
              title: "title-class",
              icon: "icon-class",
            },
            title: "Successful!",
            icon: "success",
            timer: 2000,
          });
        
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }
  return (
    <div className="p-3 InvDashContainerStat001">
      <div className="container-fluid p-0 overflow-hidden">
        <div className="col-12">
          <div className=" InvestorsContHeadingWrapper">
            <div className=" InvestorsContHeading ">
              <div className=" InvestorsContHeading ">Bank details</div>
              <div className=" BInstructions">
                Kindly indicate your bank account for withdrawals We only accept
                CleeQ24 bank details for withdrawals
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </div>

      <div className="container-fluid p-0 overflow-hidden">
        <div>
          <div className="container-fluid  p-0">
            <br />
            <div className="col-lg-12">
              <div className="Invest-Dashboard202">
                <div className="EquityNumberSubCont">
                  <div className="EquityNumber">Bank Details</div>

                  <button
                    className="WithdrawFunds"
                    onClick={() => setManagebankShow(true)}
                  >
                    Add Bank
                  </button>
                </div>
                <br />
                <div className="PersonalHeadings">Bank Name : {user?.bank_name ? user?.bank_name : "Update Bank Name"}</div>
                <div className="PersonalHeadings">Account Number : {user?.account_number ? user?.account_number : "Update Account Number"}</div>
                <div className="PersonalHeadings">Account Name : {user?.account_name ? user?.account_name : "Update Account Name"}</div>
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>

      {/*EDIT NAME STARTS HERE*/}
      <Modal
        size="lg"
        show={managebankShow}
        onHide={() => setManagebankShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        keyboard={false}
        animation={true}
      >
        <Modal.Body>
          <div className="Clique24__ModalHeadings">
            <div onClick={handleManagebank}>
              <Close className="Clique24__Close" />
            </div>
          </div>
          <div className="Settings__Modal__Container">
            <div className="Inv-Heading">
              <div className="Log__Cont">
                <img src="/assets/3.png" alt="" className="Log__Img__Modal" />
              </div>
              <div className="Clique24__Headings__Note">
                <div className="Clique24__Settings__Header">
                  Bank Details
                </div>
                <div className="DisplayUsernames">
                  Enter your bank details for funds withdrawals.
                </div>
              </div>
            </div>
            <br />
            <br />
            <div className="EditDetails">
              <div className="input-group">
                <FloatingLabel
                  controlId="floatingUsername"
                  label="Bank Name"
                  className="Input__User34"
                  value={bankName}
                  onChange={(event) => {
                    setBankName(event.target.value)
                  }}
                >
                  <Form.Control type="text" placeholder="PIN" />
                </FloatingLabel>
              </div>{" "}
              <br />
              <div className="input-group">
                <FloatingLabel
                  controlId="floatingUsername"
                  label="Account Number"
                  className="Input__User34"
                  value={accountNumber}
                  onChange={(event) => {
                    setAccountNumber(event.target.value)
                  }}
                >
                  <Form.Control type="text" placeholder="PIN" />
                </FloatingLabel>
              </div>
              <br />
              <div className="input-group">
                <FloatingLabel
                  controlId="floatingUsername"
                  label="Account Name"
                  className="Input__User34"
                  value={accountName}
                  onChange={(event) => {
                    setAccountName(event.target.value)
                  }}
                >
                  <Form.Control type="text" placeholder="PIN" />
                </FloatingLabel>
              </div>
            </div>
            <br />
            {/* <div className="EditDetails">
              <EnterPin />
            </div> */}

            <div className="mb-3">
              {/* <EnterPin /> */}
              <div
                className="valid-feedback AlertNot alert alert-danger "
                role="alert"
              >
                Incorrect PIN!
              </div>
            </div>
            <br />
            <div className="Payment__Heading__Cont">
              <div className="Profile__Cont__Wrapper mb-3">
              {
                loading ? (
                  <Bars
                    heigth="20"
                    width="20"
                    color="gray"
                    ariaLabel="loading-indicator"
                  />
                ) : (
                  <button className="Profile__Cont__Saves" onClick={handleUpdate}>
                    Save
                  </button>
                ) 
              }
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/*EDIT NAME ENDS HERE*/}
    </div>
  );
}

export default ManageInvestorBank;
