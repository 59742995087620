import { configureStore } from '@reduxjs/toolkit'
import investsSlice from '../features/invests/investsSlice';
import investsCountSlice from '../features/invests/counts/investsCountSlice';
import referralsSlice from '../features/referrals/referralsSlice';
import referralCountSlice from '../features/referrals/counts/referralCountSlice';
import referralSumSlice from '../features/referrals/sums/referralSumSlice';
import referralPayoutSumSlice from '../features/referrals/sums/referralPayoutSumSlice';
import referralPayoutCountSlice from '../features/referrals/counts/referralPayoutCountSlice';
import investSumSlice from '../features/invests/sums/investSumSlice';
import investROISumSlice from '../features/invests/sums/investROISumSlice';
import investUnitsSumSlice from '../features/invests/sums/investUnitsSumSlice';
import investWaitingROISumSlice from '../features/invests/sums/investWaitingROISumSlice';
import investMaturedROISumSlice from '../features/invests/sums/investMaturedROISumSlice';
import investPaidROISumSlice from '../features/invests/sums/investPaidROISumSlice';
import payoutsSlice from '../features/payouts/payoutsSlice';
import payoutCountSlice from '../features/payouts/counts/payoutCountSlice';
import payoutSumSlice from '../features/payouts/sums/payoutSumSlice';
import investsWaitingCountSlice from '../features/invests/counts/investsWaitingCountSlice';
import investsMaturedCountSlice from '../features/invests/counts/investsMaturedCountSlice';
import investsPaidCountSlice from '../features/invests/counts/investsPaidCountSlice';
import payoutPaidCountSlice from '../features/payouts/counts/payoutPaidCountSlice';
import payoutPaidSumSlice from '../features/payouts/sums/payoutPaidSumSlice';
import investPendingROISumSlice from '../features/invests/sums/investPendingROISumSlice';
import investsPendingCountSlice from '../features/invests/counts/investsPendingCountSlice';
import investorsCountSlice from '../features/investors/counts/investorsCountSlice';

const store = configureStore({
  reducer: {
      invests: investsSlice, 
      investsCount: investsCountSlice,
      referrals: referralsSlice,
      referralCount: referralCountSlice,    
      referralSum: referralSumSlice,
      referralPayoutSum: referralPayoutSumSlice,
      referralPayoutCount: referralPayoutCountSlice,
      investSum: investSumSlice,
      investROISum: investROISumSlice,
      investUnitsSum: investUnitsSumSlice,
      investWaitingROISum: investWaitingROISumSlice,
      investMaturedROISum: investMaturedROISumSlice,
      investPaidROISum: investPaidROISumSlice,
      payouts: payoutsSlice,
      payoutCount: payoutCountSlice,
      payoutSum: payoutSumSlice,
      investsWaitingCount: investsWaitingCountSlice,
      investsMaturedCount: investsMaturedCountSlice,
      investsPaidCount: investsPaidCountSlice,
      payoutPaidCount: payoutPaidCountSlice,
      payoutPaidSum: payoutPaidSumSlice,
      investPendingROISum: investPendingROISumSlice,
      investsPendingCount: investsPendingCountSlice,
      investorsCount: investorsCountSlice
  }
})
    
export default store;