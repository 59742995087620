import React, { useState } from "react";
import "../Home/Home.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../auth";
import { signUp } from "../../services/AuthService";
import { Bars } from "react-loader-spinner";

function ConfirmPhoneNumber() {
  const auth = useAuth();
  const location = useLocation();
  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const handleSignup = (user) => {
    setLoading(true);
    signUp(user?.phone, user?.password)
      .then((response) => {
        //console.log(response)
        if (response.data.success) {
          setLoading(false);
          navigate("/verify-otp", {
            state: { user: user, token: response.data.data },
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
    //dispatch(signupAction(user.phone, user.password))
    //auth.signup(user)
    //setShowModal(true)
  };

  return (
    <div className="LoginBody">
      <header></header>
      <div className="DashBoardContainer">
        <div className="column22 ">
          <div className="logoSignup__Cont">
            <div className="logo__Cont">
              <img src="/assets/3.png" alt="" className="ImgLogo" />
            </div>
            <div className="logo__SignUp__Note">
              <div className="SignUp__Header">Confirm Phone number.</div>

              <div className="SignUp__Header__Note">
                Confirm that this Phone number is available to receive OTP.
              </div>
            </div>
          </div>

          <br />

          <div className="Input__C">
            <div className="form-floating mb-3">
              <input
                disabled
                type="text"
                className="form-control"
                id="floatingInput"
                placeholder="Phone Number"
                value={location.state?.user?.phone}
              />
              <label className="frol" for="floatingInput">
                Phone Number
              </label>
            </div>
          </div>

          <div className="Clique__Qes">
            Is your Phone Number correct? Do you want to change it?
          </div>

          <br />

          <div className="Otp__Btn">
            <Link to="/Signup" className="Clique__Link">
              <button disabled={loading} className="Log__Btn ChangeNumber__Btn">
                Change{" "}
              </button>{" "}
            </Link>
            {loading ? (
              <Bars
                heigth="50"
                width="50"
                color="gray"
                ariaLabel="loading-indicator"
              />
            ) : (
              <div className="Clique__Link">
                <button
                  className="Log__Btn"
                  onClick={() => handleSignup(location.state?.user)}
                >
                  Send OTP{" "}
                </button>
              </div>
            )}
          </div>

          <br />
          <div className="Clique__Qes">
            Go back to{" "}
            <Link to="/" className="Clique__Link">
              <span>Home</span>
            </Link>
          </div>
        </div>

        <footer>{/* <FooterHome /> */}</footer>
      </div>
    </div>
  );
}

export default ConfirmPhoneNumber;
