import React, { useEffect, useState } from "react";
import "../../Components/Responsive.css";
import "../../Components/Res.css";
import "../../Components/UserDashboard.css";
import "../../Components/SweetAlert.css";
import "../../Components/Modal.css";
import "../InvestorComponent/Pagination.css";
import { MoreVert } from "@material-ui/icons";
import Paginations from "../InvestorComponent/Paginations";
import ViewPayout from "./ViewPayout";
import { useDispatch, useSelector } from "react-redux";
import { fetchPayouts } from "../../features/payouts/payoutsSlice";
import CurrencyFormat from "react-currency-format";
import moment from "moment";
import { Bars } from "react-loader-spinner";

function AllPayouts() {
  const dispatch = useDispatch()
  const {payouts, loading, paginator} = useSelector((state) => state.payouts)
  const [transactionViewShow, setTransactionViewShow] = useState(false);
  const handleTransactionView = () => setTransactionViewShow(false);
  const [selected, setSelected] = useState({});

  const [search2, setSearch2] = useState('');
  const [page, setPage] = useState(paginator?.currentPage || 1);
  const [pageLimit, setPageLimit] = useState(10);
  const [pageCount, setPageCount] = useState(paginator?.pageCount || 0);

  useEffect(() => {
    handleFetchPayout()
  }, [])

  const handleFetchPayout = (search = "", limit = 10, page = 1) => {
    dispatch(fetchPayouts({name: search, page_size: limit, page:page }))
  }

  const handleMyUpdate = (search = search2, limit = pageLimit, page = 1) => {
    dispatch(fetchPayouts({name: search, page_size: limit, page:page }));
  }

  const showDate = (passDate) => {
    return moment(passDate).format('DD-MM-YYYY')
  }
  return (
    <>
      <Paginations 
        handleFetchTransSearch={handleMyUpdate} 
        page={page} 
        setPage={setPage} 
        pageCount={paginator?.pageCount || 0} 
        pageLimit={pageLimit}
        setPageLimit={setPageLimit}
      />
      {loading ? (
          <div className="d-flex justify-content-center">
            <Bars
              heigth="30"
              width="30"
              color="gray"
              ariaLabel="loading-indicator"
            />
          </div>
      ) : null}
      {
        payouts?.map((item, index) => (
          <div key={index} className="Investment-Transaction-Info50">
            <div className="Investors-TransactionRef">
              <div className="Wallet-Ref-Heading">Reference ID</div>
              <div className="Wallet-Ref-Result">{item?.code}</div>
            </div>

            <div className="Inv-Maturity-Date">
              <div className="Wallet-Ref-Heading">&#8358; Amount</div>
              <CurrencyFormat
                value={item?.amount || 0}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₦ "}
                renderText={(value) => (
                  <div className="Wallet-Ref-Result">{value}</div>
                )}
              />
            </div>
        
            <div className="Inv-Date">
              <div className="Wallet-Ref-Heading">Payment Date</div>
              <div className="Wallet-Ref-Result">{showDate(item?.created_at)}</div>
            </div>

            <div className="Inv-Maturity-Status">
              <div className="Wallet-Ref-Heading">Status</div>
              <div className="Wallet-Ref-Result">{item?.status}</div>
            </div>

            <div className="Members-Action">
              <div
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <MoreVert /> 
              </div>
              <ul
                className="dropdown-menu dropdown-menu-end"
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  <a
                    className="dropdown-item View__Details"
                    onClick={() => {setSelected(item); setTransactionViewShow(true)}}
                  >
                    View Details
                  </a>
                </li>
              </ul>
            </div>
          </div>
        ))
      }

      <Paginations 
        handleFetchTransSearch={handleMyUpdate} 
        page={page} 
        setPage={setPage} 
        pageCount={paginator?.pageCount || 0} 
        pageLimit={pageLimit}
        setPageLimit={setPageLimit}
      />
      <ViewPayout
        selected={selected}
        show={transactionViewShow}
        onClose={handleTransactionView}
        onOpen={() => setTransactionViewShow(true)}
      />
    </>
  );
}

export default AllPayouts;
