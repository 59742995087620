import React, { useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { Modal } from "react-bootstrap";
import { Close } from "@material-ui/icons";
import Swal from "sweetalert2";
import EnterPin from "./EnterPin";
import "../../Components/Responsive.css";
import "../../Components/Res.css";
import "../../Components/UserDashboard.css";
import "../../Components/SweetAlert.css";
import "../../Components/Modal.css";
import CurrencyFormat from "react-currency-format";
import { addPayout } from "../../services/PayoutService";
import { fetchInvests } from "../../features/invests/investsSlice";
import { fetchInvestsCount } from "../../features/invests/counts/investsCountSlice";
import { fetchInvestSum } from "../../features/invests/sums/investSumSlice";
import { fetchInvestROISum } from "../../features/invests/sums/investROISumSlice";
import { fetchInvestWaitingROISum } from "../../features/invests/sums/investWaitingROISumSlice";
import { fetchInvestMaturedROISum } from "../../features/invests/sums/investMaturedROISumSlice";
import { fetchInvestPaidROISum } from "../../features/invests/sums/investPaidROISumSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

function WithdrawFunds({ show, onClose, onOpen, selected }) {
  const dispatch = useDispatch()
  const [withdrawfundsShow, setWithdrawfundsShow] = useState(false);
  const handleWithdrawfunds = () => setWithdrawfundsShow(false);

  const [sendmoneydetailsShow, setSendmoneydetailsShow] = useState(false);
  const handleSendmoneydetails = () => setSendmoneydetailsShow(false);

  const [notificationShow, setNotificationShow] = useState(false);
  const handleNotificationDetails = () => setNotificationShow(false);

  const [notificationPinShow, setNotificationPinShow] = useState(false);
  const handleNotificationPinDetails = () => setNotificationPinShow(false);
  const [loading, setLoading] = useState(false);

  const handleSent = () => {
    onClose();
    setSendmoneydetailsShow(true);
  };

  const handleBack = () => {
    onOpen();
    setSendmoneydetailsShow(false);
  };

  const handleSentmoney = () => {
    onClose();
    setSendmoneydetailsShow(false);

    Swal.fire({
      customClass: {
        confirmButton: "confirm-button-class",
        title: "title-class",
        icon: "icon-class",
        text: "text-class",
      },

      title: "Are you sure about this transaction?",
      text: "This transaction will take few hours to reflect in your bank account!",
      icon: "warning",
      confirmButtonText: "Yes!",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        handleAdd();
      }
    });
  };

  const handleAdd = () => {
    setLoading(true);

    const data = {
      amount: selected?.roi,
      invest: selected?._id,
      account_name: selected?.investor?.account_name,
      account_number: selected?.investor?.account_number,
      bank_name: selected?.investor?.bank_name,
      type: "invest",
      status: 'pending'
    };

    console.log(data);
    addPayout(data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Invested"
          );
          onClose()
          setSendmoneydetailsShow(false);
          dispatch(fetchInvests({}))
          dispatch(fetchInvestsCount())
          dispatch(fetchInvestSum())
          dispatch(fetchInvestROISum())
          dispatch(fetchInvestROISum())
          dispatch(fetchInvestWaitingROISum({status: "waiting"}))
          dispatch(fetchInvestMaturedROISum({status: "matured"}))
          dispatch(fetchInvestPaidROISum({status: "paid"}))
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
              text: "text-class",
            },

            title: "Successful!",
            text: "Thank you for Investing with us",
            icon: "success",
            confirmButtonText: "Feels Good!",
            timer: 2000,
          });
        
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }

  return (
    <div>
      <div>
        {/*Clique  SEND MONEY   Settings Modal starts here*/}
        <Modal
          size="lg"
          show={show}
          onHide={onClose}
          aria-labelledby="example-modal-sizes-title-lg"
          keyboard={false}
          animation={true}
        >
          <Modal.Body>
            <div className="Clique24__ModalHeadings">
              <div onClick={onClose}>
                <Close className="Clique24__Close" />
              </div>
            </div>
            <div className="Settings__Modal__Container">
              <div className="Clique24__Headings">
                <div className="Log__Cont">
                  <img src="/assets/3.png" alt="" className="Log__Img__Modal" />
                </div>
                <div className="Clique24__Headings__Note">
                  <div className="Clique24__Settings__Header">
                    Investment Withdrawal
                  </div>
                  <div className="DisplayUsernames">
                    Withdraw funds to your registered account
                  </div>
                </div>
              </div>
              <br />

              <div className="Payment__Heading__Cont">
                <br />
                <div className="DisplayNames">
                  Beneficiary's Name : <span>{selected?.investor?.account_name}</span>
                </div>
                <div className="DisplayNames">
                  Bank Name : <span>{selected?.investor?.bank_name}</span>
                </div>
                <div className="DisplayNames">
                  Account Number: <span>{selected?.investor?.account_number}</span>
                </div>
                <br /><br />
                <div className="DisplayNames26">
                  Due Amount:{" "}
                  <CurrencyFormat
                    value={selected?.roi || 0}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"₦ "}
                    renderText={(value) => (
                      <span>{value}</span>
                    )}
                  />
                
                </div>
                <br /><br /><br />
                <div className="Profile__Cont__Wrapper mb-3">
                  {
                    selected?.investor?.bank_name && selected?.investor?.account_number ? (
                      <div className="Profile__Cont__Saves" onClick={handleSent}>
                        Withdraw Funds
                      </div>
                    ) : (
                      <div className="Profile__Cont__Saves3" onClick={() => setNotificationShow(true)}>
                        Withdraw Funds
                      </div>
                    )
                  }
                  
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/*Clique SEND MONEY  Settings Modal Ends here*/}
      </div>

      {/*Clique  SEND MONEY DETAILS DISPLAY Modal starts here*/}
      <Modal
        size="lg"
        show={sendmoneydetailsShow}
        onHide={() => setSendmoneydetailsShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        keyboard={false}
        animation={true}
      >
        <Modal.Body>
          <div className="Clique24__ModalHeadings">
            <div onClick={handleSendmoneydetails}>
              <Close className="Clique24__Close" />
            </div>
          </div>
          <div className="Settings__Modal__Container">
            <div className="Inv-Heading">
              <div className="Log__Cont">
                <img src="/assets/3.png" alt="" className="Log__Img__Modal" />
              </div>
              <div className="Clique24__Headings__Note">
                <div className="Clique24__Settings__Header">
                  ROI Withdrawal Confirmation
                </div>
                <div className="DisplayUsernames">
                  Confirm your ROI withdrawal details
                </div>
              </div>
            </div>
            <br />
            <br />
            <div className="INV-AccountDetails">
              <div className="BenHeadings">Investor's Information </div>
              <div className="BenHeadingsTittle">
                <div className="DisplayNames">Account Name</div>
                <div className="DisplayUsernames">{selected?.investor?.account_name}</div>
              </div>
              <div className="BenHeadingsTittle">
                <div className="DisplayNames">Account Number</div>
                <div className="DisplayUsernames">{selected?.investor?.bank_name}</div>
              </div>
              <div className="BenHeadingsTittle">
                <div className="DisplayNames">Bank Name</div>
                <div className="DisplayUsernames">{selected?.investor?.account_number}</div>
              </div>
            </div>
            <div className="INV-AccountDetails">
            <CurrencyFormat
              value={selected?.roi || 0}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"₦ "}
              renderText={(value) => (
                <div className="BenHeadings">{value}</div>
              )}
            />
              {/* <div>
                <div className="DisplayNames">Amount</div>
                <div className="DisplayUsernames">&#8358; Amount display</div>
              </div> */}
            </div>

            <div className="mb-3">
              <EnterPin />
              <div
                className="valid-feedback AlertNot alert alert-danger "
                role="alert"
              >
                Incorrect PIN!
              </div>
            </div>
            <br />
            <div className="Payment__Heading__Cont">
              <div className="Profile__Cont__Wrapper mb-3">
                <div className="Profile__Cont__Saves" onClick={handleBack}>
                  Back
                </div>
                  {
                    selected?.investor?.pin ? (
                      <button
                        className="Profile__Cont__Saves"
                        onClick={handleSentmoney}
                      >
                        Withdraw Funds
                      </button>
                    ) : (
                      <button className="Profile__Cont__Saves3" onClick={() => setNotificationPinShow(true)}>
                        Withdraw Funds
                      </button>
                    )
                  }

              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/*Clique SEND MONEY DETAILS DISPLAY   Modal Ends here*/}

      {/* Notification when no Bank account */}
      <Modal
        size="lg"
        show={notificationShow}
        onHide={() => setNotificationShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        keyboard={false}
        animation={true}
      >
        <Modal.Body>
          <div className="Clique24__ModalHeadings">
            <div onClick={handleNotificationDetails}>
              <Close className="Clique24__Close" />
            </div>
          </div>
          <div className="Settings__Modal__Container">
            <div className="Inv-Heading">
              <div className="Log__Cont">
                <img src="/assets/3.png" alt="" className="Log__Img__Modal" />
              </div>
              <div className="Clique24__Headings__Note">
                <div className="Clique24__Settings__Header">
                  Withdrawal Notification
                </div>
                <div className="DisplayUsernames">
                  Confirm your Bank details are updated
                </div>
              </div>
            </div>
            <br />
            <br />
            <div className="INV-AccountDetails">
              <div className="DisplayUsernames">
                Please go to Accounts - Manage Bank - Add Bank, and add your account details.
              </div>
              <br />
              <div className="DisplayUsernames">
                Your account details is required for this withdral.
              </div>
              <br />
              <div className="DisplayUsernames">
                Thank you.
              </div>
            </div>
            
            <br />
            <div className="Payment__Heading__Cont">
              <div className="Profile__Cont__Wrapper mb-3">
                <div className="Profile__Cont__Saves" onClick={() => setNotificationShow(false)}>
                  Back
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Notification when no PIN */}
      <Modal
        size="lg"
        show={notificationPinShow}
        onHide={() => setNotificationPinShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        keyboard={false}
        animation={true}
      >
        <Modal.Body>
          <div className="Clique24__ModalHeadings">
            <div onClick={handleNotificationPinDetails}>
              <Close className="Clique24__Close" />
            </div>
          </div>
          <div className="Settings__Modal__Container">
            <div className="Inv-Heading">
              <div className="Log__Cont">
                <img src="/assets/3.png" alt="" className="Log__Img__Modal" />
              </div>
              <div className="Clique24__Headings__Note">
                <div className="Clique24__Settings__Header">
                  Withdrawal Notification
                </div>
                <div className="DisplayUsernames">
                  Confirm your PIN is updated
                </div>
              </div>
            </div>
            <br />
            <br />
            <div className="INV-AccountDetails">
              <div className="DisplayUsernames">
                Please go to Accounts - Change Withdrawal PIN, and input your pin.
              </div>
              <br />
              <div className="DisplayUsernames">
                Your Pin is required for this withdral.
              </div>
              <br />
              <div className="DisplayUsernames">
                Thank you.
              </div>
            </div>
            
            <br />
            <div className="Payment__Heading__Cont">
              <div className="Profile__Cont__Wrapper mb-3">
                <div className="Profile__Cont__Saves" onClick={() => setNotificationPinShow(false)}>
                  Back
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default WithdrawFunds;
