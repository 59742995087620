import React, { useEffect } from "react";
import "../../Components/Responsive.css";
import "../../Components/Res.css";
import "../../Components/UserDashboard.css";
import "../../Components/SweetAlert.css";
import "../../Components/Modal.css";

import InvestmentTransactionInfo from "./InvestmentTransactionInfo";
import { useDispatch, useSelector } from "react-redux";
import { fetchInvests } from "../../features/invests/investsSlice";
import { getUser } from "../../services/AuthService";

function InvestmentTransactions() {
  const dispatch = useDispatch()
  const user = getUser();
  const {invests, paginator, loading} = useSelector((state) => state.invests)

  useEffect(() => {
    if(user?._id){
      setTimeout(() => {
        dispatch(fetchInvests({}))
      }, 6000);
    }
  }, [])
  
  return (
    <>
      <div className="container overflow-hidden p-0">
        <div className="Investors-Dashboard-Body">
          <div className="Investors-Dashboard-Heading-Container">
            <div className="Investors-Dashboard-Heading">
              Investment Details
            </div>
          </div>
          {
            invests?.length < 1  ? (
              <div className="container overflow-hidden Investors-Transactions">
                <div className="">
                Once you've invested, your investment details will promptly display here.
                </div>
              </div>
            ) : null
          }
          
          {
            invests?.length > 0  ? (
              <div className="container overflow-hidden Investors-Transactions22">
                <InvestmentTransactionInfo />
              </div>
            ) : null
          }
          

          <center className="Spreader"></center>
        </div>
      </div>
    </>
  );
}

export default InvestmentTransactions;
