import React, {useState} from "react";
import "../Home/Home.css";
import { Link, useNavigate } from "react-router-dom";
import FooterHome from "../../Components/Footer/FooterHome";
import NavBarList from "../../Components/NavBarList";
import { login, saveTokenInLocalStorage, saveUserInLocalStorage, signUp2 } from "../../services/AuthService";
import { toast } from "react-toastify";
import { useAuth } from "../../auth";
import { Bars } from "react-loader-spinner";
import NavBar from "../../Components/NavBar";
import Footer from "../../Components/Footer";

function Login() {
  const auth = useAuth();
  let navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const [errPhone, setErrPhone] = useState("");
  const [password, setPassword] = useState("")
  const [errPassword, setErrPassword] = useState("");
  const [loading, setLoading] = useState("")

  const [comfirmPassword, setComfirmPassword] = useState("")
  const [errConfirmPassword, setErrConfirmPassword] = useState("");
  const [isAccept, setIsAccept] = useState("")
  const [refCode, setRefCode] = useState("")
  const [knowAboutUs, setKnowAboutUs] = useState("")


  const moveDashboard = () => {
    navigate("/dashboard")
  }

  const handleSubmit = () => {
    if (!phone) {
      setErrPhone("Phone number is required");
      return;
    }
    if (!password) {
      setErrPassword("Password is required");
      return;
    }
    setLoading(true)
    const sendData = {
      phone: phone,
      password: password,

    }
     console.log(sendData);
    login(sendData)
    .then(async (response) => {
      console.log(response)
      if(response.data.success){
        toast.success(
          response.data.message
            ? response.data.message
            : "Successfully Signed-In"
        );
        await saveUserInLocalStorage(response.data.user)
        await saveTokenInLocalStorage(response.data.token)
        setLoading(false)
        auth?.signup(response.data.user)
        navigate("/dashboard")
      }
      
     
    }).catch((err) => {
      setLoading(false)
      console.log(err)
    })

  }
  return (
    <div className="LoginBody">
      <header>
        <div>
          <NavBar />
        </div>
      </header>
      <div className="DashBoardContainer">
        <div className="column22 ">
          <div className="logoSignup__Cont">
          
              <img src="/assets/3.png" alt="" className="ImgLogo" />
         
            <div className="logo__SignUp__Note">
              <div className="SignUp__Header">Login.</div>

              <div className="SignUp__Header__Note">Enter Your Phone Number</div>
            </div>
          </div>
          <div className="Input__C">
            <div className="form-floating mb-3">
              <input
                type="number"
                className="form-control"
                id="floatingInput"
                placeholder="Phone Number"
                value={phone}
                onChange={(event) => {
                  if(event.target.value.length){
                    setErrPhone("")
                  }else{
                    setErrPhone("Phone number is required")
                  }
                  setPhone(event.target.value)
                }}
              />
              <label className="frol" for="floatingInput">
                Phone Number
              </label>
              {(errPhone) && <div className='input_error'>{errPhone}</div>}
            </div>
            <div className="form-floating  ghshs">
              <input
                type="password"
                className="form-control"
                id="floatingPassword"
                placeholder="Password"
                value={password}
                onChange={(event) => {
                  if(event.target.value.length){
                    setErrPassword("")
                  }else{
                    setErrPassword("Password is required")
                  }
                  setPassword(event.target.value)
                }}
              />
              <label className="frol" for="floatingPassword">
                Password
              </label>
              {(errPassword) && <div className='input_error'>{errPassword}</div>}
            </div>
          </div>
          <br />

          <br />
          <div className="Clique__Qes">
            {/* <Link to="/ForgotPassword" className="Clique__Link">
              Forgot Password?{" "}
            </Link> */}
          </div>
          <br />
          <div>
            {
              loading ? (
                <Bars
                  heigth="50"
                  width="50"
                  color="gray"
                  ariaLabel="loading-indicator"
                />
              ) : (
                <button className="Log__Btn" onClick={handleSubmit}>Login</button>
              ) 
            }
          </div>
          <br />
          <br />
          <div className="Clique__Qes">
            Go back to{" "}
            <Link to="/" className="Clique__Link">
              <span>Home</span>
            </Link>
          </div>
        </div>
        {/* <Footer/> */}
      </div>
    </div>
  );
}

export default Login;
