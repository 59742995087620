import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { payoutCount } from '../../../services/PayoutService'

const initialState = {
    loading: false,
    count: 0,
}

export const fetchPayoutCount = createAsyncThunk('fetchPayoutCount', (initData) => {
    return payoutCount(initData)
    .then((response) =>  response.data.data)
})


const payoutCountSlice = createSlice({
    name: 'payoutCount',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchPayoutCount.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchPayoutCount.fulfilled, (state, action ) => {
        state.loading = false
        state.count = action.payload
       })
    }
})

export default payoutCountSlice.reducer 