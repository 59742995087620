import "../../Components/Responsive.css";
import "../../Components/Res.css";
import "../../Components/UserDashboard.css";
import "../../Components/SweetAlert.css";
import "../../Components/Modal.css";
import {
  FcLineChart,
  FcMultipleInputs,
  FcNegativeDynamic,
} from "react-icons/fc";
import React, { useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { Modal } from "react-bootstrap";
import { Close } from "@material-ui/icons";
import EnterPin from "../InvestorComponent/EnterPin";
import RemainingEquity from "../InvestorComponent/RemainingEquity";
import Swal from "sweetalert2";

function TransactionCount() {
  const [investinusShow, setInvestinusShow] = useState(false);
  const handleInvestinus = () => setInvestinusShow(false);

  const handleInvest = () => {
    setInvestinusShow(false);

    Swal.fire({
      customClass: {
        confirmButton: "confirm-button-class",
        title: "title-class",
        icon: "icon-class",
        text: "text-class",
      },

      title: "Thanks for making the right choice",
      text: "Your investment will be processed shortly",
      icon: "warning",
      confirmButtonText: "Yes!",
      //   showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          customClass: {
            confirmButton: "confirm-button-class",
            title: "title-class",
            icon: "icon-class",
            text: "text-class",
          },

          title: "Your Investment is Successful!",
          text: "Thank you for Investing with us",
          icon: "success",
          confirmButtonText: "Feels Good!",
          //       timer: 2000,
        });
      }
    });
  };
  return (
    <div>
      <div className="container overflow-hidden p-0">
        <div className="Investors-Dashboard-Body">
          {/* <div className="CleeQBody2">eee</div> */}
          <div className="Investors-Dashboard-Heading-Container">
            <div className="Investors-Dashboard-Heading">Withdrawals</div>
          </div>
          <br />
          <div className="container overflow-hidden p-0 Investors-Dashboard-Overview">
            <div className="row g-4">
              <div className="col-xl-6">
                <div className="Investors-Invested-Amount Investment-Mobile">
                  <div className="Investors-Invested-Heading">
                    <div className="Investors-Amount-Heading">
                      No of Withdrawals
                      {/* <EqutiyOffer /> */}
                    </div>
                    <div className="IconCont1">
                      <FcNegativeDynamic className="AccIcon" />
                    </div>
                  </div>
                  <div className="Investors-Amount">0</div>
                </div>
              </div>

              <div className="col-xl-6 ">
                <div className="Investors-Invested-Amount Investment-Mobile">
                  <div className="Investors-Invested-Heading">
                    <div className="Investors-Amount-Heading">
                      Pending Withdrawals
                    </div>

                    <div className="IconCont1">
                      <FcMultipleInputs className="AccIcon" />
                    </div>
                  </div>
                  <div className="Investors-Amount">
                    <span>0</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TransactionCount;
