import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { investSumROI } from '../../../services/InvestService'

const initialState = {
    loading: false,
    total: 0,
}

export const fetchInvestWaitingROISum = createAsyncThunk('fetchInvestWaitingROISum', (initData) => {
    return investSumROI(initData)
    .then((response) =>  response.data.data)
})


const investWaitingROISumSlice = createSlice({
    name: 'investWaitingROISum',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchInvestWaitingROISum.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchInvestWaitingROISum.fulfilled, (state, action ) => {
        state.loading = false
        state.total = action.payload[0]?.amount
       })
    }
})

export default investWaitingROISumSlice.reducer 