import React, { useEffect, useState } from "react";
import "../../Components/Responsive.css";
import "../../Components/Res.css";
import "../../Components/UserDashboard.css";
import "../../Components/SweetAlert.css";
import "../../Components/Modal.css";
import "../InvestorComponent/Pagination.css";
import { CiBadgeDollar, CiBookmarkMinus, CiBookmarkPlus } from "react-icons/ci";
import { FiSearch } from "react-icons/fi";
import MyAllReferral from "./MyAllReferral";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { fetchReferrals } from "../../features/referrals/referralsSlice";

function MyReferralInfo() {
  const dispatch = useDispatch()
  const {referrals, paginator, loading} = useSelector((state) => state.referrals)
  const [active, setActive] = useState("AllTransactions");

  const [withdrawfundsShow, setWithdrawfundsShow] = useState(false);
  const handleWithdrawfunds = () => setWithdrawfundsShow(false);

  const [search2, setSearch2] = useState('');
  const [page, setPage] = useState(paginator?.currentPage || 1);
  const [pageLimit, setPageLimit] = useState(10);
  const [pageCount, setPageCount] = useState(paginator?.pageCount || 0);
  const [selected, setSelected] = useState({})

  const showDate = (passDate) => {
    return moment(passDate).format('DD-MM-YYYY')
  }

  useEffect(() => {
    handleFetchMyReferrals()
  }, [])

  const handleFetchMyReferrals = (search = "", limit = 10, page = 1) => {
    dispatch(fetchReferrals({name: search, page_size: limit, page:page }))
  }

  const handleMyUpdate = (search = search2, limit = pageLimit, page = 1) => {
    dispatch(fetchReferrals({name: search, page_size: limit, page:page }));
  }
  return (
    <div>
      <div className="container-fluid overflow-hidden p-0">
        <div className="TransactionCont">
          <div
            className={active === "AllTransactions" ? "IA" : "ICONINACTIVE"}
            onClick={() => setActive("AllTransactions")}
          >
            <CiBadgeDollar className="InvestICO" />
            <div className="User-Headings">My Referral</div>
          </div>
        </div>

        <div>
          <div className="Invwrapper_inner">
          <form className="search-container">
              <input
                type="text"
                placeholder="Search.."
                name="search"
                className="Investors-Search"
                onKeyUp={(e) => {
                  if(e.target.value.length > 1){
                    setSearch2(e.target.value)
                    handleMyUpdate(e.target.value)
                  }else{
                    setSearch2('')
                    handleFetchMyReferrals()
                  }
                }}
              />
              <button type="submit" className="Investors-Search-BTN">
                <FiSearch className="Investors-Search-Icon" />
              </button>
            </form>
            {active === "AllTransactions" && <MyAllReferral />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyReferralInfo;
