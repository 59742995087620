import React, { useEffect, useState } from "react";
import "../../Components/Responsive.css";
import "../../Components/Res.css";
import "../../Components/UserDashboard.css";
import "../../Components/SweetAlert.css";
import "../../Components/Modal.css";
import "../InvestorComponent/Pagination.css";
import { FiDatabase, FiNavigation } from "react-icons/fi";
import InvestmentOverview from "./InvestmentOverview";
import ROI from "./ROI";
import InvestmentList from "./InvestmentList";
import { FcAlarmClock, FcBullish, FcLowPriority, FcOk } from "react-icons/fc";
import { useDispatch, useSelector } from "react-redux";
import CurrencyFormat from "react-currency-format";
import { fetchInvestROISum } from "../../features/invests/sums/investROISumSlice";
import { fetchInvestWaitingROISum } from "../../features/invests/sums/investWaitingROISumSlice";
import { fetchInvestMaturedROISum } from "../../features/invests/sums/investMaturedROISumSlice";
import { fetchInvestPaidROISum } from "../../features/invests/sums/investPaidROISumSlice";
import { fetchInvestsWaitingCount } from "../../features/invests/counts/investsWaitingCountSlice";
import { fetchInvestsMaturedCount } from "../../features/invests/counts/investsMaturedCountSlice";
import { fetchInvestsPaidCount } from "../../features/invests/counts/investsPaidCountSlice";
import { fetchInvests } from "../../features/invests/investsSlice";
import { fetchInvestsPendingCount } from "../../features/invests/counts/investsPendingCountSlice";
import { fetchInvestPendingROISum } from "../../features/invests/sums/investPendingROISumSlice";
import { getUser } from "../../services/AuthService";

function InvestmentTransactionInfo() {
  const user = getUser();
  const dispatch = useDispatch();
  const investROISum = useSelector((state) => state.investROISum)
  const investWaitingROISum = useSelector((state) => state.investWaitingROISum)
  const investMaturedROISum = useSelector((state) => state.investMaturedROISum)
  const investPaidROISum = useSelector((state) => state.investPaidROISum)
  const investPendingROISum = useSelector((state) => state.investPendingROISum)
  const investsCount = useSelector((state) => state.investsCount)
  const investsWaitingCount = useSelector((state) => state.investsWaitingCount)
  const investsMaturedCount = useSelector((state) => state.investsMaturedCount)
  const investsPaidCount = useSelector((state) => state.investsPaidCount)
  const investsPendingCount = useSelector((state) => state.investsPendingCount)

  
  const [withdrawfundsShow, setWithdrawfundsShow] = useState(false);
  const handleWithdrawfunds = () => setWithdrawfundsShow(false);
  
  useEffect(() => {
    if(user?._id){
      setTimeout(() => {
        dispatch(fetchInvestROISum())
        dispatch(fetchInvestWaitingROISum({status: "waiting"}))
        dispatch(fetchInvestMaturedROISum({status: "matured"}))
        dispatch(fetchInvestPaidROISum({status: "paid"}))
        dispatch(fetchInvestPendingROISum({status: "pending"}))
        dispatch(fetchInvestsWaitingCount({status: "waiting"}))
        dispatch(fetchInvestsMaturedCount({status: "matured"}))
        dispatch(fetchInvestsPaidCount({status: "paid"}))
        dispatch(fetchInvestsPendingCount({status: "pending"}))
      }, 7000);
    }
  }, [])

  const statusChange = (type) => {
    if(type === 'all'){
      dispatch(fetchInvests({}))
    }
    if(type === 'reviewing'){
      dispatch(fetchInvests({status: 'reviewing'}))
    }
    if(type === 'waiting'){
      dispatch(fetchInvests({status: 'waiting'}))
    }
    if(type === 'matured'){
      dispatch(fetchInvests({status: 'matured'}))
    }
    if(type === 'pending'){
      dispatch(fetchInvests({status: 'pending'}))
    }
    if(type === 'paid'){
      dispatch(fetchInvests({status: 'paid'}))
    }
  }
  return (
    <div>
      <InvestmentOverview />
      <br />
      <div className="container-fluid overflow-hidden p-0">
        <div className="row g-4">
          <div onClick={() => statusChange("all")} className="col-lg-3">
            <div className="Invest-Dashboard20">
              <div className="InvestDetails">
                <div className="InvestDetailsHeading">All (&#8358; ROI)</div>
                <div className="IconCont1">
                  <FcBullish className="AccIcon" />
                </div>
              </div>
              <div className="EquityNumber">
                {
                  investROISum?.loading ? (
                    <div>Loading</div>
                  ) : (
                    <CurrencyFormat
                      value={investROISum?.total || 0}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"₦ "}
                      renderText={(value) => (
                        <div>{value}</div>
                      )}
                    />
                  )
                }
                <div>{investsCount?.loading ? "Loading..." : investsCount?.count}</div>
              </div>
            </div>
          </div>

          <div onClick={() => statusChange("waiting")} className="col-lg-3">
            <div className="Invest-Dashboard20">
              <div className="InvestDetails">
                <div className="InvestDetailsHeading">Waiting(&#8358;ROI)</div>
                <div className="IconCont1">
                  <FcAlarmClock className="AccIcon" />
                </div>
              </div>

              <div className="EquityNumber">
                {
                  investWaitingROISum?.loading ? (
                    <div>Loading</div>
                  ) : (
                    <CurrencyFormat
                      value={investWaitingROISum?.total || 0}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"₦ "}
                      renderText={(value) => (
                        <div>{value}</div>
                      )}
                    />
                  )
                }

                <div>{investsWaitingCount?.loading ? "Loading..." : investsWaitingCount?.count}</div>
              </div>
              {/* 
                <button
                  className="WithdrawFunds"
                  onClick={() => setWithdrawfundsShow(true)}
                >
                  Withdraw
                </button> */}
            </div>
          </div>
          <div onClick={() => statusChange("matured")} className="col-lg-3">
            <div className="Invest-Dashboard20">
              <div className="InvestDetails">
                <div className="InvestDetailsHeading">Matured(&#8358;ROI)</div>
                <div className="IconCont1">
                  <FcLowPriority className="AccIcon" />
                </div>
              </div>

              <div className="EquityNumber">
              {
                  investMaturedROISum?.loading ? (
                    <div>Loading</div>
                  ) : (
                    <CurrencyFormat
                      value={investMaturedROISum?.total || 0}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"₦ "}
                      renderText={(value) => (
                        <div>{value}</div>
                      )}
                    />
                  )
                }
                <div>{investsMaturedCount?.loading ? "Loading..." : investsMaturedCount?.count}</div>

                {/* 
                <button
                  className="WithdrawFunds"
                  onClick={() => setWithdrawfundsShow(true)}
                >
                  Withdraw
                </button> */}
              </div>
            </div>
          </div>
          <div onClick={() => statusChange("pending")} className="col-lg-3">
            <div className="Invest-Dashboard20">
              <div className="InvestDetails">
                <div className="InvestDetailsHeading">Pending</div>
                <div className="IconCont1">
                  <FcOk className="AccIcon" />
                </div>
              </div>
              <div className="EquityNumber">
                {
                  investPendingROISum?.loading ? (
                    <div>Loading</div>
                  ) : (
                    <CurrencyFormat
                      value={investPendingROISum?.total || 0}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"₦ "}
                      renderText={(value) => (
                        <div>{value}</div>
                      )}
                    />
                  )
                }
                <div>{investsPendingCount?.loading ? "Loading..." : investsPendingCount?.count}</div>
              </div>
            </div>
          </div>
          <div onClick={() => statusChange("paid")} className="col-lg-3">
            <div className="Invest-Dashboard20">
              <div className="InvestDetails">
                <div className="InvestDetailsHeading">Paid</div>
                <div className="IconCont1">
                  <FcOk className="AccIcon" />
                </div>
              </div>
              <div className="EquityNumber">
                {
                  investPaidROISum?.loading ? (
                    <div>Loading</div>
                  ) : (
                    <CurrencyFormat
                      value={investPaidROISum?.total || 0}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"₦ "}
                      renderText={(value) => (
                        <div>{value}</div>
                      )}
                    />
                  )
                }
                <div>{investsPaidCount?.loading ? "Loading..." : investsPaidCount?.count}</div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <InvestmentList />
      </div>

      {/* <WithdrawFunds
        show={withdrawfundsShow}
        onClose={handleWithdrawfunds}
        onOpen={() => setWithdrawfundsShow(true)}
      /> */}
    </div>
  );
}

export default InvestmentTransactionInfo;
