
import React from "react";
import "../InvestorComponent/Pagination.css";
import "../../Components/UserDashboard.css";
import "../../Components/Responsive.css";
import "../../Components/Clique24Logo/Clique24Logo.css";
import MyReferralCount from "./MyReferralCount";
import MyReferralTable from "./MyReferralTable";

function MyReferrals() {
  return (
    <>
      <MyReferralCount />
      <MyReferralTable />
    </>
  );
}

export default MyReferrals;
